import { Avatar } from 'antd';
import { ChangePersonPhoto } from 'components';
import usePhoto from 'hooks/usePhoto';
import { memo, MutableRefObject, useState } from 'react';
import style from './index.module.scss';

type props = {
  api: any;
  person: any;
  constants: MutableRefObject<any>;
};

const ProfilePhoto = ({ api, person, constants }: props) => {
  const [visiblePhoto, setVisiblePhoto] = useState(false);
  const [tempProfilePhoto, setTempProfilePhoto] = useState<any>();
  const { img, fallback } = usePhoto({
    api,
    person,
  });

  const setInnerPhoto = (v: any) => {
    setTempProfilePhoto(v);
    constants.current['profilePhoto'] = v || null;
  };

  return (
    <div className={style.avatar}>
      <Avatar
        src={tempProfilePhoto?.fileBase64 || img || fallback}
        size={150}
        onClick={() =>
          tempProfilePhoto ? setInnerPhoto(undefined) : setVisiblePhoto(true)
        }
      />
      <ChangePersonPhoto
        visible={visiblePhoto}
        onCancel={() => setVisiblePhoto(false)}
        callBackImgData={(v) => {
          setVisiblePhoto(false);
          setInnerPhoto(v);
        }}
      />
    </div>
  );
};

export default memo(ProfilePhoto);
