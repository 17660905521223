import { Col, Form, Input, Row, Select } from 'antd';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPhoneCode } from 'systemConfiguration/redux-sagas/actions';
import { validateNumber } from 'utilities';

import style from './index.module.scss';

type PhoneFieldType = {
  phoneCodeName?: string;
  phoneName?: string;
  labelSpan?: number;
  labelInput?: number;
  phoneCodeSpan?: number;
  phoneSpan?: number;
  rowWidth?: string;
};

const PhoneField = ({
  phoneCodeName,
  phoneName,
  rowWidth,
  labelSpan,
  phoneCodeSpan,
  phoneSpan,
}: PhoneFieldType) => {
  const [selectedPhoneCode, setSelectedPhoneCode] = useState(false);
  const [selectedPhone, setSelectedPhone] = useState(false);
  const { phoneCodes } = useSelector((state: any) => state.systemConfiguration);
  const dispatch = useDispatch();
  useEffect(() => {
    !phoneCodes?.length && dispatch(getAllPhoneCode());
    // eslint-disable-next-line
  }, []);

  return (
    <Row gutter={15} style={{ width: rowWidth }} className={style.phone_field}>
      <Col span={labelSpan || 5}>
        <p className={style.label}>Teléfono:</p>
      </Col>
      <Col span={phoneCodeSpan || 4}>
        <Form.Item name={phoneCodeName || 'phoneCode'} label={'+54'}>
          <Select
            className={style.phone_code}
            showSearch
            onClick={() => setSelectedPhoneCode(true)}
            onBlur={() => setSelectedPhoneCode(false)}
            dropdownStyle={{ width: 300 }}
            suffixIcon={null}
            bordered={selectedPhoneCode}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            allowClear
            placeholder="xxxx"
          >
            {phoneCodes?.map((phoneCode: any) => (
              <Select.Option key={phoneCode.code} value={phoneCode.code + ''}>
                {`${phoneCode.code}`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={phoneSpan || 3}>
        <Form.Item name={phoneName || 'phone'} normalize={validateNumber}>
          <Input
            onClick={() => setSelectedPhone(true)}
            onBlur={() => setSelectedPhone(false)}
            className={style.phone}
            type="tel"
            placeholder="yyyyyyy"
            bordered={selectedPhone}
          />
        </Form.Item>
        <small className={style.advice}>Ingrese número sin el 15</small>
      </Col>
    </Row>
  );
};

export default memo(PhoneField);
