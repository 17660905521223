import { Button, Popover, Segmented, Select } from 'antd';
import useTour from 'hooks/useTour';
import { memo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  CACHE_RECORD_KEY,
  getNumberOfFiltersApplied,
  getParamsFromValues,
  getParamsToObject,
  setGetWitchCache,
} from 'utilities';
import style from './index.module.scss';

const { Option } = Select;

const Content = memo(() => {
  const {
    professionalsEmails,
    loadingEmails,
    specialities,
    loading: loadingSpecialities,
  } = useSelector((state: any) => state.systemConfiguration);

  const { state }: any = useLocation();

  const [filters, setFilters] = useState<any>({});
  const [params, setSearchParams] = useSearchParams();

  const applyFilters = () => {
    setSearchParams(
      getParamsFromValues({
        ...filters,
        ...['end', 'begin'].reduce(
          (a, c) => ({
            ...a,
            ...(params.get(c) && { [c]: params.get(c) }),
          }),
          {},
        ),
      }),
      { state },
    );
  };
  const clearFilters = () => {
    setGetWitchCache(CACHE_RECORD_KEY);
    setSearchParams({}, { state });
  };

  useEffect(() => {
    setFilters(getParamsToObject(params));
  }, [params]);

  const segmentValue = () => {
    if (
      (filters.private && filters.public) ||
      (!filters.private && !filters.public)
    )
      return 'to';
    return (filters.private && 'pri') || 'pub';
  };

  return (
    <div className={style.filter_content}>
      <div>
        <Segmented
          value={segmentValue()}
          onChange={(v) =>
            setFilters({
              ...filters,
              private: v === 'pri' || v === 'to',
              public: v === 'pub' || v === 'to',
            })
          }
          options={[
            { label: 'Privado', value: 'pri' },
            { label: 'Públicos', value: 'pub' },
            { label: 'Todos', value: 'to' },
          ]}
          onResize={undefined}
          onResizeCapture={undefined}
        />
      </div>
      <div>
        <Segmented
          value={filters.order || 'desc'}
          onChange={(v) =>
            setFilters({
              ...filters,
              order: v,
              sort: 'registeredAt',
            })
          }
          options={[
            { label: 'Más antiguos primero', value: 'asc' },
            { label: 'Más recientes primero', value: 'desc' },
          ]}
          onResize={undefined}
          onResizeCapture={undefined}
        />
      </div>
      <div>
        <Select
          allowClear
          showSearch
          filterOption={(input: string, option: any) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.createdBy}
          onChange={(v) => {
            if (!v) delete filters.createdBy;
            setFilters({
              ...filters,
              ...(v && { createdBy: v }),
            });
          }}
          loading={loadingSpecialities}
          placeholder="Seleccionar tipo de estudio"
        >
          {specialities.map(({ id, name }: any) => (
            <Option value={id}>{name}</Option>
          ))}
        </Select>
      </div>
      <div>
        <Select
          allowClear
          showSearch
          filterOption={(input: string, option: any) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.madeBy}
          onChange={(v) => {
            if (!v) delete filters.madeBy;
            setFilters({
              ...filters,
              ...(v && { madeBy: v }),
            });
          }}
          loading={loadingEmails}
          placeholder="Seleccionar profesional creador"
        >
          {professionalsEmails.map(({ uid, email }: any) => (
            <Option value={uid}>{email}</Option>
          ))}
        </Select>
      </div>
      <div className={style.filter_actions}>
        {getNumberOfFiltersApplied(params) > 0 && (
          <Button className={`mt_secondary_button`} onClick={clearFilters}>
            Limpiar Filtros
          </Button>
        )}
        <Button className={`mt_primary_button`} onClick={applyFilters}>
          Aplicar Filtros
        </Button>
      </div>
    </div>
  );
});

const AddRecordsFilter = () => {
  const { ref } = useTour();
  const [params] = useSearchParams();
  const counter = getNumberOfFiltersApplied(params);

  return (
    <>
      <Popover placement="bottom" content={<Content />} trigger="click">
        <Button
          ref={(r) => ref('person_filters', r)}
          className={style.filter_button}
        >
          {counter > 0 ? `Filtros (${counter})` : '+ Añadir Filtros'}
        </Button>
      </Popover>
    </>
  );
};

export default memo(AddRecordsFilter);
