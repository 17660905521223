import { memo, useState } from 'react';
import style from './index.module.scss';
import moment from 'moment';
import useDate from 'hooks/useDate';
import { EnrollmentIcon } from 'icons';
import SpecialityTag from 'components/specialityTag';

type props = { professional: any };

const ProfessionalInformation = ({ professional }: props) => {
  const { getFormat } = useDate();
  const [extraInformation, setExtraInformation] = useState<any>(
    professional.specialities?.[0].id,
  );

  const setSpeciality = (id: string) => setExtraInformation(id);
  const detail = professional.extraSpecialitiesInfo?.[extraInformation];

  return (
    <div className={style.professional_information}>
      <div className={style.specialities}>
        {professional.specialities?.map((sp: any) => (
          <SpecialityTag
            key={sp.id}
            onClick={() => setSpeciality(sp.id)}
            speciality={sp}
            isSelected={sp.id === extraInformation}
          />
        ))}
      </div>
      {detail && (
        <div className={style.speciality_detail}>
          <div>
            <EnrollmentIcon /> {detail.enrollment}
          </div>
          <div>
            {detail.enrollmentAt &&
              moment(detail.enrollmentAt).format(getFormat('DD/MM/YYYY'))}
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(ProfessionalInformation);
