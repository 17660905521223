import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./translations/en.json";
import es from "./translations/es.json";

const resources = {
  en: { translations: en },
  es: { translations: es },
};

i18n.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  resources,
  lng: "en",
});

export default i18n;
