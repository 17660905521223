import { SearchPeople } from 'components';
import { memo } from 'react';

import style from './index.module.scss';

const Search = () => {
  return (
    <div className={style.search}>
      <SearchPeople />
      <div className={style.img}></div>
    </div>
  );
};

export default memo(Search);
