import api from '../api';

const baseUrl =
  process.env.REACT_APP_API_URL || process.env.REACT_APP_PERSON_SERVICE_URL;
// const baseContext = `${baseUrl}/api/v1/people`;
const baseContext = `${baseUrl}${
  process.env.REACT_APP_API_URL ? '/api/v1/people' : ''
}`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  //Get all peoples
  getAll: () => {
    return api.get(`${baseContext}`);
  },

  // Search by where statement
  search: (params) => {
    return api.get(`${baseContext}`, { params });
  },

  // Get people by id
  getById: (id, params) => {
    return api.get(`${baseContext}/${id}`, { params });
  },

  // Create new person
  create: (data) => {
    return api.post(`${baseContext}`, data);
  },

  // Update person
  update: (id, data) => {
    return api.put(`${baseContext}/${id}`, data);
  },

  // Download profile photo
  downloadProfilePhoto: (personId, fileId, params) => {
    return api.get(`${baseContext}/${personId}/file/${fileId}`, {
      responseType: 'blob',
      params,
    });
  },
};
