import { memo } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Login, NotFound, Disabled } from 'pages';
import Security from 'security';
import { useSelector } from 'react-redux';
import { routes } from './data';
import useTour from 'hooks/useTour';

const Routers = () => {
  const { TourComponent } = useTour();
  const {
    medicalProfile: { claims },
  } = useSelector((state: any) => state.medicalProfile);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Security direction="forward" />}>
            {routes.map((r) => {
              const R = r.component;
              return (
                !claims.disabled && (
                  <Route key={r.path} path={r.path} element={<R />} />
                )
              );
            })}
            {/* Disabled page */}
            {claims.disabled && (
              <Route path="/disabled" element={<Disabled />} />
            )}
          </Route>

          <Route path="/login" element={<Security direction="backward" />}>
            <Route path="/login" element={<Login />} />
          </Route>

          <Route
            key="not-found"
            path="/*"
            element={<Security direction="none" />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Router>
      <TourComponent />
    </>
  );
};

export default memo(Routers);
