import * as types from "./types";

const result = (type, payload) => {
  return {
    type: type,
    payload: payload,
  };
};

export const getMe = payload => result(types.GET_ME, payload);
export const getMeSuccess = payload => result(types.GET_ME_SUCCESS, payload);
export const getMeError = payload => result(types.GET_ME_ERROR, payload);

export const setLogged = payload => result(types.SET_LOGGED, payload);

export const setTermsAsRead = payload => result(types.SET_TERMS_AS_READ, payload);
export const setTermsAsReadSuccess = payload => result(types.SET_TERMS_AS_READ_SUCCESS, payload);
export const setTermsAsReadError = payload => result(types.SET_TERMS_AS_READ_ERROR, payload);

export const updateMedicalCache = payload => result(types.UPDATE_MEDICAL_CACHE, payload);

export const signOut = payload => result(types.SIGN_OUT, payload);

export const medicalClear = payload => result(types.MEDICAL_CLEAR, payload);
