import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as types from "./types";
import * as actions from "./actions";
import { MedicalApi } from "api";

function* watchGetMe() {
  yield takeEvery(types.GET_ME, function* ({ payload }) {
    try {
      const { data } = yield call(MedicalApi.getMe, payload);
      yield put(actions.getMeSuccess(data));
    } catch (error) {
      yield put(actions.getMeError());
    }
  });
}

function* watchSetTermsAsRead() {
  yield takeEvery(types.SET_TERMS_AS_READ, function* ({ payload }) {
    try {
      const { data } = yield call(MedicalApi.setTermsAsRead, payload);
      yield put(actions.setTermsAsReadSuccess(data));
    } catch (error) {
      yield put(actions.setTermsAsReadError());
    }
  });
}

function* MedicalProfileSagas() {
  yield all([fork(watchGetMe), fork(watchSetTermsAsRead)]);
}

export default MedicalProfileSagas;
