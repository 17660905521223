import { memo } from 'react';
import Lottie from 'react-lottie';
import doctorProfile from './doctor-profile.json';

const LottieComponent = () => {
  return (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: doctorProfile,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
      height={400}
      width={400}
    />
  );
};

export default memo(LottieComponent);
