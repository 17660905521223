import { Input, Modal, Collapse, Empty, Button, Checkbox } from 'antd';
import { IcdComponent } from 'components';
import { memo, useEffect, useState } from 'react';
import {
  CommentOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import style from './index.module.scss';
import { useRecord } from 'provider/recordProvider';
import useTour from 'hooks/useTour';
import { useRecordUpsert } from 'hooks/useRecord';
const { Panel } = Collapse;

const IcdModal = () => {
  const { ref } = useTour();
  const { isNewRecord } = useRecord();
  const { editing, data, onChange, isVisible } = useRecordUpsert();
  const [show, setShow] = useState(false);
  const [innerSelected, innerSetSelected] = useState<any>([]);
  const [resumeVisible, setResumeVisible] = useState<boolean>(false);
  const [innerOpened, setInnerOpened] = useState<string[] | any>([]);
  const [innerToRemove, setInnerToRemove] = useState<any>([]);

  useEffect(() => {
    const currently =
      data.current?.icd?.filter((v: any) => v.action !== 'delete') || undefined;
    innerSetSelected(currently);
    setResumeVisible(!!currently?.length);
  }, [data, data.current?.icd?.length, show]);

  const onApply = () => {
    setResumeVisible(true);
  };

  const resumeCancel = () => {
    setResumeVisible(false);
  };

  const resumeOk = () => {
    setResumeVisible(false);
    onChange('icd', [
      ...innerSelected.reduce((acc: any, v: any) => {
        if (!acc.find((e: any) => e.code === v.code)) return [...acc, v];
      }, []),
      ...(innerToRemove.map((e: any) => ({ ...e, action: 'delete' })) || []),
    ]);
    onClose();
  };

  const onClose = () => {
    setShow(false);
  };

  const removeSelected = (v: any, e: any) => {
    e.stopPropagation();
    if (v.id)
      setInnerToRemove((state: any) => [
        ...state,
        innerSelected.find((item: any) => item.id === v.id),
      ]);
    innerSetSelected((sate: any[]) =>
      sate.filter((item: any) => item.code !== v.code),
    );
  };

  const lengthInnerList = innerSelected?.filter(
    ({ action }: any) => action !== 'delete',
  ).length;

  const showCondtion =
    editing ||
    isNewRecord ||
    (isVisible && (!!lengthInnerList || !!data.current.icd?.length));

  return (
    <>
      {showCondtion && (
        <Button
          ref={(r) => ref('record_icd', r)}
          className={`mt_fifth_button outline ${style.button_diagnostics}`}
          onClick={() => setShow(true)}
        >{`${
          !!lengthInnerList ? `(${lengthInnerList})` : 'Agregar'
        } Diagnósticos`}</Button>
      )}
      {show && !resumeVisible && (
        <Modal
          onCancel={onClose}
          open={show}
          title={
            <div>
              Clasificación internacional de enfermedades{' '}
              <b>(haga click en los diagnosticos que desea agregar)</b>
            </div>
          }
          style={{ margin: 'auto', top: 0, padding: 0, height: '90%' }}
          bodyStyle={{ overflow: 'hidden' }}
          width={'100%'}
          onOk={onApply}
          okButtonProps={{ disabled: !!!innerSelected?.length }}
          okText="Agregar"
        >
          <IcdComponent
            isSelect
            selected={innerSelected?.filter((v: any) => v.action !== 'delete')}
            onSelected={innerSetSelected}
          />
        </Modal>
      )}
      {resumeVisible && show && (
        <Modal
          onCancel={onClose}
          open={resumeVisible && show}
          title="Diagnosticos seleccionados"
          style={{ margin: 'auto', top: 0, padding: 0, height: '50%' }}
          bodyStyle={{ overflow: 'auto' }}
          width={'50%'}
          footer={[
            !!innerSelected?.length && (editing || isNewRecord) && (
              <Button key="add-more" onClick={resumeCancel}>
                <PlusOutlined /> Agregar
              </Button>
            ),
            <Button key="back" onClick={onClose}>
              Cancelar
            </Button>,
            ((editing || isNewRecord) && (
              <Button key="submit" type="primary" onClick={resumeOk}>
                Aplicar
              </Button>
            )) ||
              null,
          ]}
          okButtonProps={{ disabled: !!!innerSelected?.length }}
        >
          <Collapse
            onChange={setInnerOpened}
            style={(!innerSelected?.length && { height: '100%' }) || {}}
          >
            {innerSelected?.map((v: any, i: number) => (
              <Panel
                header={`${v.code} - ${v.title}`}
                key={`${i}-${v.code}`}
                extra={
                  <div className={style.panel_actions}>
                    <div onClick={(e) => e.stopPropagation()}>
                      <Checkbox
                        disabled={!editing && !isNewRecord}
                        defaultChecked={v.antecedent}
                        onChange={(value) => {
                          if (v.id) v.action = 'update';
                          v.antecedent = value.target.checked;
                        }}
                      >
                        Antecedente
                      </Checkbox>
                    </div>
                    {(editing || isNewRecord) && (
                      <div onClick={(e) => removeSelected(v, e)}>
                        <DeleteOutlined />
                      </div>
                    )}
                  </div>
                }
              >
                {innerOpened.includes(`${i}-${v.code}`) && (
                  <>
                    <label>
                      {`${((isNewRecord || editing) && 'Agregar') || ''} ${
                        ((isNewRecord || editing) && 'c') || 'C'
                      }omentario`}{' '}
                      <CommentOutlined />
                    </label>
                    <Input.TextArea
                      className={style.comment}
                      disabled={!editing && !isNewRecord}
                      style={{ marginBottom: '10px' }}
                      showCount
                      defaultValue={v.description}
                      maxLength={100}
                      onChange={(value: any) => {
                        if (v.id) v.action = 'update';
                        v.description = value.target.value;
                      }}
                    />
                  </>
                )}
              </Panel>
            ))}
            {!innerSelected?.length && (
              <div className={style.empty_diagnostics}>
                <Empty description="No hay diagnosticos seleccionados" />
                <p className={style.back_diagnostics} onClick={resumeCancel}>
                  Seleccionar Diagnósticos
                </p>
              </div>
            )}
          </Collapse>
        </Modal>
      )}
    </>
  );
};

export default memo(IcdModal);
