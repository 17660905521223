import useOnScreen from 'hooks/useOnScreen';
import { memo, useLayoutEffect } from 'react';
import style from './index.module.scss';
import { Empty } from 'antd';
import { Loading } from 'components';

type Props = {
  data: any[];
  loading?: boolean;
  Item: any;
  findAgain?: number; // number to find again from bottom
  onSearch?: Function;
  type?: 'list' | 'grid';
  card_width?: string;
  gap?: string;
  emptyLabel?: string;
  setRef?: (ref: any) => void;
  onRender: (v: any) => any;
};

const List = ({
  data,
  Item,
  findAgain,
  onSearch,
  loading,
  type = 'list',
  card_width = '200px',
  gap = 'var(--gap)',
  emptyLabel,
  setRef,
  onRender,
}: Props) => {
  const { setObserver } = useOnScreen(onSearch);

  useLayoutEffect(() => {
    setObserver('find-after-here');
  }, [data?.length, setObserver]);

  useLayoutEffect(() => {
    const div = document.querySelector('#list-container') as any;
    div && div.style.setProperty('--card-width', card_width);
    div && div.style.setProperty('--gap-list', gap);
  }, [card_width, gap]);

  const renderItem = (e: any, i: number) =>
    (i + 1 === data.length - (findAgain || 0) && (
      <Item key={`list-${i}`} {...onRender(e)} id={'find-after-here'} />
    )) || <Item key={`list-${i}`} {...onRender(e)} />;

  return (
    <div
      key="cointainer-list-key"
      id="list-container"
      style={{ height: !!!data?.length ? '100%' : '' }}
      className={`${(type === 'grid' && style.grid) || style.list}`}
    >
      <>
        {data?.map((e: any, i: number) => renderItem(e, i))}
        {!data.length && !loading && (
          <div ref={setRef} className={style.empty}>
            <Empty key="empty-list" description={emptyLabel} />
          </div>
        )}
        {loading && (
          <div ref={setRef} key="loading-list" className={style.list_loading}>
            {loading && <Loading />}
          </div>
        )}
      </>
    </div>
  );
};

export default memo(List);
