import { memo, useEffect, useState } from 'react';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import style from './index.module.scss';
import { AddNewProfessionalAddress } from 'components';
import { MedicalCenterIcon } from 'icons';
import { useProfessional } from 'hooks/useProfessional';
import classNames from 'classnames';

const ProfessionalAddresses = () => {
  const { form, professional, constants, onUpdate, updating } =
    useProfessional();
  const [addOrUpdate, setaddOrUpdate] = useState<null | string>(null);
  const [center, setCenter] = useState<any>();

  const onNewAddress = () => {
    constants.current['action'] = 'new';
    clearAddressInput();
    if (addOrUpdate === 'new') {
      setaddOrUpdate(null);
      return;
    } else {
      setaddOrUpdate('new');
    }
  };

  useEffect(() => {
    if (updating) {
      setaddOrUpdate(null);
      clearConstants();
    }
    // eslint-disable-next-line
  }, [updating]);

  const clearAddressInput = () => {
    form.setFieldsValue({
      stateId: null,
      departmentId: null,
      localityId: null,
      street: null,
      streetNumber: null,
      observation: null,
      zip: null,
      latitude: null,
      longitude: null,
      addressId: null,
      addressEmail: null,
      addressPhoneCode: null,
      addressPhone: null,
    });
  };

  const openAddress = (address: any) => {
    if (constants.current['addressId'] === address.id) {
      clearAddressInput();
      clearConstants();
      setaddOrUpdate(null);
      return;
    }
    form.setFieldsValue({
      stateId: address.state?.id,
      departmentId: address.department?.id,
      localityId: address.locality?.id,
      street: address.street,
      streetNumber: address.streetNumber,
      observation: address.observation,
      zip: address.zip,
      latitude: address.latitude,
      longitude: address.longitude,
      addressEmail: address.email,
      addressPhoneCode: address.phoneCode,
      addressPhone: address.phone,
    });
    constants.current['action'] = 'update';
    constants.current['addressId'] = address.id;
    setCenter({ lat: address.latitude, lng: address.longitude });
    setaddOrUpdate(address.id);
  };

  const onDelete = async () => {
    constants.current['action'] = 'delete';
    constants.current['addressId'] = addOrUpdate;
    await onUpdate(form.getFieldsValue());
    clearConstants();
    clearAddressInput();
    setaddOrUpdate(null);
  };

  const clearConstants = () => {
    constants.current['action'] = null;
    constants.current['addressId'] = null;
  };

  return (
    <div className={style.addresses}>
      <div className={style.addresses_list}>
        {professional.addresses?.map((address: any) => (
          <div
            className={classNames(
              {
                [style.disabled]: addOrUpdate === 'new',
                [style.selected]: addOrUpdate === address.id,
              },
              style.address,
            )}
            onClick={() => addOrUpdate !== 'new' && openAddress(address)}
            key={address.id}
          >
            <MedicalCenterIcon /> {address.observation}
          </div>
        ))}
        <div onClick={onNewAddress} className={style.add_address}>
          {(addOrUpdate !== 'new' && (
            <>
              <PlusOutlined /> Agregar dirección
            </>
          )) || (
            <>
              <CloseOutlined /> Cancelar
            </>
          )}
        </div>
      </div>
      {addOrUpdate && (
        <AddNewProfessionalAddress center={center} isEditing={!!addOrUpdate} />
      )}
      {addOrUpdate && addOrUpdate !== 'new' && (
        <div className={style.delete_address}>
          <div onClick={onDelete}>Eliminar dirección</div>
        </div>
      )}
    </div>
  );
};

export default memo(ProfessionalAddresses);
