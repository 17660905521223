import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import style from './index.module.scss';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className={style.not_found_page}>
      <div className={style.content}>
        <h1>La pagina que estas buscando no existe</h1>
        <Button
          className="mt_primary_button"
          onClick={() => navigate('/', { replace: true })}
        >
          Inicio
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
