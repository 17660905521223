import { Col, Row, Form, DatePicker } from 'antd';
import useDate from 'hooks/useDate';
import { CakeIcon } from 'icons';
import { memo } from 'react';
import style from './index.module.scss';

type props = {
  label: string;
  placeholder: string;
  rules?: any[];
  [x: string]: any;
};

const BirthDateField = ({ label, rules, placeholder, ...props }: props) => {
  const { getFormat, disabledFutureDate } = useDate();
  return (
    <Row className={style.birthdate_field}>
      <Col span={5}>
        <p className={style.label}>{label}</p>
      </Col>
      <Col span={10}>
        <Form.Item name="birthdate" rules={rules}>
          <DatePicker
            suffixIcon={<CakeIcon />}
            placeholder={placeholder}
            disabledDate={disabledFutureDate}
            format={getFormat('DD/MM/YYYY')}
            style={{ width: 'fit-content' }}
            {...props}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default memo(BirthDateField);
