import { Header } from 'components';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

// Apply to all System
const commonLayout = (isLogged: boolean, disabled: boolean = false) => {
  const layoutStyle: any = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    background: 'var(--color-secondary)',
  };

  return (
    (isLogged && !disabled && (
      <div style={layoutStyle}>
        <Header />
        <Outlet />
      </div>
    )) || <Outlet />
  );
};

const Security = ({
  direction,
}: {
  direction: 'forward' | 'backward' | 'none';
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const {
    isLogged,
    medicalProfile: { claims },
  } = useSelector((sate: any) => sate.medicalProfile);

  const isInit = pathname.includes('/init');
  const isDisabled = pathname.includes('/disabled');

  useEffect(() => {
    if (!pathname.includes('/person')) window.document.title = 'Medical Trace';
  }, [pathname]);

  useEffect(() => {
    if (isLogged && claims.temporalPass) navigate('/init', { replace: true });
    if (!isLogged && isDisabled) navigate('/login', { replace: true });
  }, [isLogged, pathname]);

  useEffect(() => {
    if (isLogged && !!claims.disabled) {
      navigate('/disabled', { replace: true });
    }
  }, [claims.disabled, pathname, isLogged]);

  const forward = isLogged ? (
    commonLayout(isLogged, !!claims.disabled)
  ) : (
    <Navigate to="/login" />
  );

  const backward = isLogged ? (
    <Navigate to="/" />
  ) : (
    commonLayout(isLogged && !isInit, !!claims.disabled)
  );

  const noneDirection = commonLayout(isLogged && !isInit, !!claims.disabled);

  return direction === 'forward' ? (
    forward
  ) : direction === 'backward' ? (
    backward
  ) : direction === 'none' ? (
    noneDirection
  ) : (
    <Navigate to="/login" />
  );
};

export default Security;
