import { Button } from 'antd';
import { PeopleApi } from 'api';
import useFetch from 'hooks/useFetch';
import { memo, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import style from './index.module.scss';
import { LoadingOutlined } from '@ant-design/icons';
import ChoosePersonModal from 'components/choosePersonModal';

const SearchPeople = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [openChooseModal, setOpenChooseModal] = useState(false);
  const cuil_id = searchParams.get('cuil_id');

  if (!cuil_id) navigate('/', { replace: true });

  const {
    loading: { search: loading },
    data: { search: peopleList },
    search,
  } = useFetch(PeopleApi);

  const userNotExist = () => !!!peopleList?.items.length && peopleList;

  const innerCreate = () => {
    navigate('/person', { state: { tempData: { cuil_id } } });
  };

  const cancel = () => {
    navigate('/');
  };

  useEffect(() => {
    const personFinded = peopleList?.items?.find(
      (p: any) => p.cuil === cuil_id,
    );
    if (personFinded)
      navigate(`/person/${personFinded.doc.id}`, {
        state: { personInformation: personFinded },
      });

    setOpenChooseModal(!personFinded && !!peopleList?.items?.length);
  }, [cuil_id, navigate, peopleList?.items]);

  useEffect(() => {
    cuil_id && search && search({ cuil_id });
  }, [cuil_id, search]);

  return (
    <div className={style.search_people}>
      {loading && (
        <div className={style.searching}>
          <p className={style.text}>Buscando usuarios</p>
          <LoadingOutlined spin className={style.icon} />
        </div>
      )}
      {!loading && userNotExist() && (
        <div className={style.not_exist}>
          <h3>No se han encontrado resultados</h3>
          <p>
            No se ha encontrado un usuario cuya identificación sea{' '}
            <b>{cuil_id}</b>.
          </p>
          <p className={style.hints}>
            Sin embargo, puede reiniciar la búsqueda o dar de alta al usuario
          </p>
          <div className={style.buttons}>
            <Button
              className="mt_third_button"
              type="primary"
              shape="round"
              onClick={cancel}
            >
              Cancelar búsqueda
            </Button>
            <Button
              className="mt_fourth_button"
              type="primary"
              shape="round"
              onClick={innerCreate}
            >
              Agregar persona
            </Button>
          </div>
        </div>
      )}
      <ChoosePersonModal open={openChooseModal} people={peopleList?.items} />
    </div>
  );
};

export default memo(SearchPeople);
