import { Divider, Modal } from 'antd';
import { memo, useEffect, useState } from 'react';
import style from './index.module.scss';
import {
  SendOutlined,
  PhoneOutlined,
  WhatsAppOutlined,
  EnvironmentOutlined,
  ContactsOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { sendEmail } from 'utilities';
import useChooseCallOrWhatsapp from 'hooks/useChooseCallOrWhatsapp';
import { GoogleMap } from 'components/map';
import { InfoIcon, SocialWorkIcon } from 'icons';
import { usePerson } from 'hooks/usePerson';

const zoom = 15;

const PersonExtraInfomation = () => {
  const { person } = usePerson();
  const [innerVisible, setInnerVisible] = useState<boolean>();
  const [center, setCenter] = useState<any>();
  const [click, setClick] = useState<any>();
  const { openToChoose } = useChooseCallOrWhatsapp({
    personName: person?.name,
    phoneNumber: `${person.phoneCode}${person?.phone}`,
  });

  useEffect(() => {
    if (person.latitude && person.longitude) {
      setCenter({ lat: person.latitude, lng: person.longitude });
      setClick({ lat: person.latitude, lng: person.longitude });
    }
  }, [person.latitude, person.longitude]);

  return (
    <>
      <div title="Informacion" onClick={() => setInnerVisible(true)}>
        <InfoIcon />
      </div>
      {innerVisible && (
        <Modal
          style={{ top: 0, margin: 'auto' }}
          width={800}
          title={`Información adicional de ${person.name} ${person.lastName}`}
          cancelText={
            <>
              <CloseOutlined /> Cerrar
            </>
          }
          open={innerVisible}
          onCancel={() => setInnerVisible(false)}
          closable
          okButtonProps={{ style: { display: 'none' } }}
        >
          <div className={style.person_extra_information}>
            <Divider orientation="left">
              Obra social <SocialWorkIcon />
            </Divider>
            <div className={style.container}>
              <div>
                <h4>Nombre:</h4>
                {person.socialType?.nombre}
              </div>
              <div>
                <h4>N° de afiliado:</h4>
                {person.socialId}
              </div>
            </div>
            <Divider orientation="left">
              Dirección <EnvironmentOutlined />
            </Divider>
            <div className={`${style.container} ${style.address}`}>
              <div>
                {(person.state?.nombre ||
                  person.department?.nombre ||
                  person.locality?.nombre) && (
                  <div>
                    <h4>Dirección:</h4> {person.state?.nombre}{' '}
                    {person.department?.nombre} {person.locality?.nombre}
                  </div>
                )}
                {(person.street || person.streetNumber) && (
                  <div>
                    <h4>Calle:</h4> {person.street} <h4>N°:</h4>{' '}
                    {person.streetNumber}
                  </div>
                )}
                {person.observation && (
                  <div>
                    <h4>Observacion:</h4> {person.observation}
                  </div>
                )}
                {person.zip && (
                  <div>
                    <h4>Código de Area:</h4> {person.zip}
                  </div>
                )}
              </div>
              {person.latitude && person.longitude && (
                <div style={{ height: 130 }}>
                  <GoogleMap
                    notEditable
                    click={click}
                    newCenter={center}
                    newZoom={zoom}
                  />
                </div>
              )}
            </div>
            <Divider orientation="left">
              Contacto <ContactsOutlined />
            </Divider>
            <div className={style.container}>
              {person.email && (
                <div
                  title={'Enviar correo'}
                  className={style.action}
                  onClick={() => !!person.email && sendEmail(person.email)}
                >
                  <h4>Email:</h4>
                  {person.email} {!!person.email && <SendOutlined />}
                </div>
              )}
              {person.phone && (
                <div
                  title={'Comunicarse'}
                  className={style.action}
                  onClick={() => !!person.phone && openToChoose()}
                >
                  <h4>Teléfono:</h4> {person.phoneCode}-{person.phone}
                  {!!person.phone && (
                    <>
                      <PhoneOutlined />
                      <WhatsAppOutlined />
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default memo(PersonExtraInfomation);
