import { Modal } from 'antd';
import { ChooseCallOrWhatsapp } from 'components';
import { useSelector } from 'react-redux';
import { callPhone, sendWhatsapp } from 'utilities/tools';
import { CloseOutlined } from '@ant-design/icons';
import { fastModalClass } from 'utilities';

const actions = {
  call: callPhone,
  whatsapp: sendWhatsapp,
};

type props = {
  phoneNumber: string;
  personName: string;
};

const useChooseCallOrWhatsapp = ({ phoneNumber, personName }: props) => {
  const { medicalProfile } = useSelector((state: any) => state.medicalProfile);

  const action = (v: 'call' | 'whatsapp') => {
    actions[v](undefined, phoneNumber, personName, medicalProfile.name);
  };

  const openToChoose = (e?: any) => {
    e && e.stopPropagation();
    Modal.confirm({
      className: fastModalClass,
      maskClosable: true,
      width: 500,
      style: { top: '0', margin: 'auto' },
      title: (
        <div style={{ textAlign: 'center' }}>{`Contactar a ${personName}`}</div>
      ),
      cancelButtonProps: { style: { display: 'none' } },
      cancelText: (
        <>
          <CloseOutlined /> Cancelar
        </>
      ),
      content: (
        <div>
          <div style={{ textAlign: 'center', marginBottom: '10px' }}>
            +54 {phoneNumber}
          </div>
          <ChooseCallOrWhatsapp choose={action} />
        </div>
      ),
      okButtonProps: { style: { display: 'none' } },
      icon: null,
    });
  };

  return { openToChoose };
};

export default useChooseCallOrWhatsapp;
