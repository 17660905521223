import { Button, Modal } from 'antd';
import PersonChip from 'components/personChip';
import useNav from 'hooks/useNav';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import style from './index.module.scss';

type props = {
  open: boolean;
  people: any[];
};

const ChoosePersonModal = ({ open, people }: props) => {
  const navigate = useNavigate();
  const { backNavigate } = useNav();

  const onCancel = () => {
    backNavigate('/');
  };

  const innerCreate = () => {
    navigate('/person', { state: { tempData: { cuil_id: people[0].id } } });
  };

  return (
    (open && !!people?.length && (
      <Modal
        onCancel={onCancel}
        title={`Personas que coinciden con DNI: ${people[0].id}`}
        style={{
          margin: 'auto',
          top: '0px',
          height: 'fit-content',
        }}
        bodyStyle={{
          margin: '10px 0',
          maxHeight: '500px',
          padding: '10px 5px',
        }}
        width={'600px'}
        open={open}
        footer={[
          <div className={style.buttons} key="buttons">
            <Button
              className="mt_third_button"
              type="primary"
              shape="round"
              onClick={onCancel}
            >
              Cancelar búsqueda
            </Button>
            <div className={style.hints}>
              <Button
                className="mt_fourth_button"
                type="primary"
                shape="round"
                onClick={innerCreate}
              >
                Agregar persona
              </Button>
            </div>
          </div>,
        ]}
        closable
      >
        <div className={style.list}>
          {people?.map((person) => (
            <PersonChip pointer big key={person.doc.id} person={person} />
          ))}
        </div>
      </Modal>
    )) ||
    null
  );
};

export default memo(ChoosePersonModal);
