import { Form, Input, Row } from 'antd';
import { memo } from 'react';
import { removeFirstAndSecondLastEmptySpace, required } from 'utilities';
import style from './index.module.scss';

const NameField = () => (
  <Row>
    <Form.Item
      normalize={removeFirstAndSecondLastEmptySpace}
      style={{ margin: 0, width: '99%' }}
      name="name-lastName"
      rules={[required]}
    >
      <Input
        className={style.name}
        height={50}
        maxLength={30}
        bordered={false}
        placeholder={'Nombre apellido'}
      />
    </Form.Item>
  </Row>
);

export default memo(NameField);
