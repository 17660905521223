import createReducer from "store/createReducer";
import * as types from "./types";

const initialState = {
  specialities: [],
  professionalsEmails: [],
  oss: [],
  getZip: [],
  phoneCodes: [],
  termsAndPrivacity: {},
  loading: false,
};

/**
 * Este reducer es para guardar las configuraciones del sistema.
 */
const SystemConfigurationReducer = createReducer(initialState, {
  [types.GET_SPECIALITIES](state, action) {
    return {
      ...state,
      loading: true,
    };
  },
  [types.GET_SPECIALITIES_SUCCESS](state, action) {
    return {
      ...state,
      specialities: action.payload.items,
      loading: false,
    };
  },
  [types.GET_SPECIALITIES_ERROR](state, action) {
    return {
      ...state,
      loading: false,
    };
  },
  //////////////////////////////////////////////////////
  [types.GET_TERMS_AND_PRIVACITY](state, action) {
    return {
      ...state,
      loading: true,
    };
  },
  [types.GET_TERMS_AND_PRIVACITY_SUCCESS](state, action) {
    return {
      ...state,
      termsAndPrivacity: action.payload,
      loading: false,
    };
  },
  [types.GET_TERMS_AND_PRIVACITY_ERROR](state, action) {
    return {
      ...state,
      loading: false,
    };
  },
  //////////////////////////////////////////////////////
  [types.GET_ALL_PROFESSIONALS_EMAILS](state, action) {
    return {
      ...state,
      loading: true,
    };
  },
  [types.GET_ALL_PROFESSIONALS_EMAILS_SUCCESS](state, action) {
    return {
      ...state,
      professionalsEmails: action.payload.items,
      loading: false,
    };
  },
  [types.GET_ALL_PROFESSIONALS_EMAILS_ERROR](state, action) {
    return {
      ...state,
      loading: false,
    };
  },
  //////////////////////////////////////////////////////
  [types.GET_ALL_OSS](state, action) {
    return {
      ...state,
      loading: true,
    };
  },
  [types.GET_ALL_OSS_SUCCESS](state, action) {
    return {
      ...state,
      oss: action.payload.items,
      loading: false,
    };
  },
  [types.GET_ALL_OSS_ERROR](state, action) {
    return {
      ...state,
      loading: false,
    };
  },
  //////////////////////////////////////////////////////
  [types.GET_ALL_PHONE_CODE](state, action) {
    return {
      ...state,
      loading: true,
    };
  },
  [types.GET_ALL_ZIP_SUCCESS](state, action) {
    return {
      ...state,
      zip: action.payload.items,
      loading: false,
    };
  },
  [types.GET_ALL_ZIP_ERROR](state, action) {
    return {
      ...state,
      loading: false,
    };
  },
  //////////////////////////////////////////////////////
  [types.GET_ALL_PHONE_CODE](state, action) {
    return {
      ...state,
      loading: true,
    };
  },
  [types.GET_ALL_PHONE_CODE_SUCCESS](state, action) {
    return {
      ...state,
      phoneCodes: action.payload.items,
      loading: false,
    };
  },
  [types.GET_ALL_PHONE_CODE_ERROR](state, action) {
    return {
      ...state,
      loading: false,
    };
  },
});

export default SystemConfigurationReducer;
