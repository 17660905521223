import { useEffect, useRef } from 'react';
import { stepFather } from 'utilities/tour.steps';
import useTour from './useTour';

type props = {
  screen: stepFather;
};
const useTourConfig = ({ screen }: props) => {
  const { setScreen, init: innerInit, ref } = useTour();
  const isInit = useRef(false);

  useEffect(() => {
    setScreen && setScreen(screen);
  }, [screen, setScreen]);

  return {
    init: () =>
      innerInit &&
      !isInit.current &&
      (() => {
        isInit.current = true;
        innerInit();
      })(),
    ref,
  };
};

export default useTourConfig;
