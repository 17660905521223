import style from './index.module.scss';
import { memo } from 'react';
import { IcdIcon } from 'icons';
import { useNavigate } from 'react-router-dom';
import useTour from 'hooks/useTour';

const IcdFabButton = () => {
  const { ref } = useTour();
  const navigate = useNavigate();

  return (
    <div
      ref={(r) => ref('icd_button', r)}
      title="Listado de Enfermades CIE-11"
      onClick={() => navigate('/search-icd')}
      className={style.fab_button}
    >
      <IcdIcon />
    </div>
  );
};

export default memo(IcdFabButton);
