import { useRecord } from 'provider/recordProvider';
import { memo } from 'react';

type props = {
  recordFocus: any;
  recordsList: any;
  loading: any;
};

const MainContentRecords = ({ recordFocus, recordsList, loading }: props) => {
  const { record, isNewRecord, isPreviewRecord, fetching } = useRecord();

  const focus = () =>
    !record && !isNewRecord && fetching ? loading : recordFocus;

  return <> {isNewRecord || isPreviewRecord ? focus() : recordsList}</>;
};

export default memo(MainContentRecords);
