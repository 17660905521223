import { Button, Col, FormInstance, Row } from 'antd';
import { Loading, ProfilePhoto } from 'components';
import { memo, MutableRefObject } from 'react';
import style from './index.module.scss';
import classnames from 'classnames';
import { ArrowLeftOutlined } from '@ant-design/icons';

type props = {
  loading?: boolean;
  okButtonLabel?: string;
  api: any;
  person: any;
  children: any;
  editing?: boolean;
  okLoading?: boolean;
  form: FormInstance<any>;
  constants: MutableRefObject<any>;
  onCancel: () => void;
};

const ProfileLayout = ({
  loading,
  person,
  api,
  children,
  form,
  editing,
  okLoading,
  okButtonLabel,
  constants,
  onCancel,
}: props) =>
  (!loading && (
    <div className={style.profile_layout}>
      <div className={style.box}>
        <Row className={style.main_content}>
          <Col className={style.photo} span={4}>
            <ProfilePhoto constants={constants} api={api} person={person} />
          </Col>
          <Col className={style.form} span={20}>
            {children}
          </Col>
        </Row>
        <Row
          className={classnames(style.buttons, { [style.editing]: editing })}
          justify="space-between"
          gutter={15}
        >
          <Col>
            <Button onClick={onCancel} className="mt_secondary_button">
              {(editing && (
                <>
                  <ArrowLeftOutlined /> Volver
                </>
              )) ||
                'Cancelar'}
            </Button>
          </Col>
          <Col>
            <Button
              loading={okLoading}
              onClick={() => form.submit()}
              className="mt_primary_button"
            >
              {(editing && (okButtonLabel || 'Editar')) ||
                okButtonLabel ||
                'Guardar'}
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  )) || (
    <div className={style.loading_screen}>
      <Loading />
    </div>
  );

export default memo(ProfileLayout);
