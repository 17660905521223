import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import FB from '../utilities/firebase';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMe,
  setLogged,
  signOut as logOut,
  updateMedicalCache,
} from 'pages/medicalProfile/redux-sagas/actions';
import ApiProvider from './apiProvider';
import { MedicalApi } from 'api';
import { useNotify } from 'hooks/useNotify';
import { FirebaseError } from 'firebase/app';
import { Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import api from 'api/api';

export const AuthContext: any = createContext(null);

export type AuthContextType = {
  getToken: Function;
  signIn: Function;
  signOut: () => void;
  getClaims: any;
  isLoading: boolean;
  validateEmail: Function;
  getUID: Function;
  isEmailVerified: Function;
  changePassword: Function;
  forgetPassword: Function;
};

const AuthProvider = ({ children }: any) => {
  const { openErrorNotify, openSuccessNotify } = useNotify();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { error, isLogged } = useSelector((sate: any) => sate.medicalProfile);

  useEffect(() => {
    if (error || !isLogged) {
      signOut();
    }
    if (isLogged) {
      FB.getAuth().onAuthStateChanged(async (user: any) => {
        !user && !isLogged && signOut();
        user && getClaims().then();
      });
    }
  }, [error, isLogged]);

  const signOut = useCallback(async () => {
    await FB.signOut(FB.getAuth())
      .then((e) => {
        dispatch(logOut());
      })
      .catch(({ code, message }: any) => {
        console.log(code, message);
      });
  }, [dispatch]);

  const setDefaultTokenToHeaders = (token: string) => {
    api.defaults.headers = {
      ...api.defaults.headers,
      Authorization: `Bearer ${token}`,
    } as any;
  };

  const signIn = useCallback(
    async (email: string, password: string) => {
      setIsLoading(true);
      return FB.signInWithEmailAndPassword(FB.getAuth(), email, password)
        .then(async ({ user }) => {
          setIsLoading(false);
          if (!isLogged && user) {
            setDefaultTokenToHeaders(await user.getIdToken());
            dispatch(getMe());
            const medicalClaims: any = (await user.getIdTokenResult(true))
              .claims.medical;
            dispatch(
              updateMedicalCache({
                ...medicalClaims,
                emailVerified: user.emailVerified,
              }),
            );

            if (medicalClaims.readTerms) {
              dispatch(setLogged(true));
            }

            // if medical is disabled logout
            if (medicalClaims.disabled) {
              Modal.error({
                closable: true,
                okButtonProps: {
                  style: { boxShadow: 'none' },
                  type: 'default',
                },
                okText: (
                  <>
                    <CloseOutlined /> Cerrar
                  </>
                ),
                title: <b>Cuenta suspendida</b>,
                style: { top: '0', margin: 'auto' },
                content: (
                  <div>
                    <p>La cuenta ha sido suspendida por el siguiente motivo</p>
                    <p style={{ textAlign: 'center', marginRight: '39px' }}>
                      <b>{medicalClaims.disabled.reason}</b>
                    </p>
                    <p>
                      Por favor contacte al administrador para mayor
                      información.
                    </p>
                  </div>
                ),
              });
              signOut();
            }
          }
        })
        .catch(({ code, message }) => {
          setIsLoading(false);
          if (code === 'auth/wrong-password' || code === 'auth/user-not-found')
            openErrorNotify('Correo electrónico o contraseña incorrecta');
          if (code === 'auth/too-many-requests')
            openErrorNotify(
              'Demasiados intentos fallidos, intente de nuevo más tarde',
            );
        });
    },
    [dispatch, isLogged, openErrorNotify, signOut],
  );

  const validateEmail = useCallback(async () => {
    try {
      await FB.sendEmailVerification(FB.getAuth().currentUser!);
      openSuccessNotify('Correo de verificación enviado');
    } catch (error) {
      openErrorNotify(
        'Error al enviar correo de verificación, intente nuevamente más tarde',
      );
      console.log(error);
    }
  }, [openErrorNotify, openSuccessNotify]);

  const getUID = () => {
    return FB.getAuth().currentUser?.uid;
  };

  const isEmailVerified = useCallback(
    async (refresh: boolean): Promise<boolean | undefined> => {
      refresh && (await FB.getAuth().currentUser?.reload());
      const isVerified = FB.getAuth().currentUser?.emailVerified;
      dispatch(updateMedicalCache({ emailVerified: isVerified }));
      return isVerified;
    },
    [dispatch],
  );

  const getClaims = useCallback(async (): Promise<any> => {
    const claims: any = (await FB.getAuth().currentUser?.getIdTokenResult(true))
      ?.claims?.medical;
    claims && dispatch(updateMedicalCache(claims));
    return claims;
  }, [dispatch]);

  const changePassword = useCallback(
    async (oldPassword: string, password: string) => {
      try {
        if (!FB.getAuth().currentUser) return false;
        const credential = FB.EmailAuthProvider.credential(
          FB.getAuth().currentUser?.email!,
          oldPassword,
        );
        await FB.reauthenticateWithCredential(
          FB.getAuth().currentUser!,
          credential,
        );
        await FB.updatePassword(FB.getAuth().currentUser!, password);
        const { temporalPass } = await getClaims();
        if (temporalPass) {
          if (await MedicalApi.changeTempPass()) {
            const medicalClaims = (
              await FB.getAuth().currentUser!.getIdTokenResult(true)
            ).claims.medical;
            dispatch(updateMedicalCache(medicalClaims));
          }
        }
        openSuccessNotify('Contraseña actualizada');
        return true;
      } catch (error: FirebaseError | any) {
        openErrorNotify(
          error.code === 'auth/wrong-password'
            ? 'Contraseña antigua incorrecta'
            : error.message,
        );
        throw error;
      }
    },
    [dispatch, getClaims, openErrorNotify, openSuccessNotify],
  );

  const getToken = useCallback(async (force: boolean) => {
    return new Promise(async (resolve) => {
      const unsuscribe = FB.getAuth().onAuthStateChanged(async (user: any) => {
        if (user) {
          resolve(await user.getIdToken(force));
          unsuscribe();
        }
      });
    });
  }, []);

  const forgetPassword = useCallback(
    async (email: string) => {
      try {
        await FB.sendPasswordResetEmail(FB.getAuth(), email, {
          url: window.location.href,
        });
        openSuccessNotify(`Correo enviado a ${email}`);
      } catch (error: any) {
        switch (error.code) {
          case 'auth/user-not-found':
            openErrorNotify('Correo no valido');
            break;
          default:
            openErrorNotify('Algo salio mal, intente mas tarde');
            break;
        }
        throw error;
      }
    },
    [openErrorNotify, openSuccessNotify],
  );

  const value: AuthContextType = useMemo(
    () => ({
      getClaims,
      isLoading,
      getToken,
      signIn,
      signOut,
      validateEmail,
      getUID,
      isEmailVerified,
      changePassword,
      forgetPassword,
    }),
    [
      changePassword,
      forgetPassword,
      getClaims,
      isEmailVerified,
      isLoading,
      signIn,
      signOut,
      validateEmail,
    ],
  );

  return (
    <AuthContext.Provider value={value}>
      <ApiProvider>{children}</ApiProvider>
    </AuthContext.Provider>
  );
};

export { AuthProvider };
