/* eslint-disable react-hooks/rules-of-hooks */
import { Form, FormInstance } from 'antd';
import { PeopleApi } from 'api';
import useFetch from 'hooks/useFetch';
import {
  createContext,
  useState,
  useEffect,
  useRef,
  MutableRefObject,
  useMemo,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CACHE_PERSON_KEY, getWithCache } from 'utilities';

export type PersonContextType = {
  constants: MutableRefObject<any>;
  person?: any;
  fetching?: boolean;
  editing?: boolean;
  isNewPerson?: boolean;
  form: FormInstance<any>;
  updating: boolean;
  creating: boolean;
  onUpdate: (id: string, v: any) => Promise<any>;
  onCreate: (v: any) => Promise<any>;
};

export const PersonContext = createContext<PersonContextType>(
  {} as PersonContextType,
);

const PersonProvider = ({ children }: any) => {
  const { personId } = useParams();
  const { pathname, search } = useLocation();
  const { state }: any = useLocation();
  const navigate = useNavigate();
  const constants = useRef({});
  const [form] = Form.useForm();

  const {
    getById,
    loading: { getById: fetching, create: creating, update: updating },
    create,
    update,
  } = useFetch(PeopleApi);

  const person =
    personId === state?.personInformation?.doc?.id
      ? state?.personInformation
      : null;

  const [innerPerson, setInnerPerson] = useState(person);

  useEffect(() => {
    (async () => {
      if (personId && !person?.doc?.id && getById)
        try {
          const result = await getById(personId, {
            ...getWithCache(CACHE_PERSON_KEY),
          });
          navigate(pathname + search, { state: { personInformation: result } });
          setInnerPerson(result);
        } catch (error) {
          navigate('/not-found');
        }
    })();
  }, [getById, personId, person?.doc?.id, navigate]);

  useEffect(() => {
    person?.doc?.id &&
      sessionStorage.setItem(
        'person',
        JSON.stringify({ name: person.name, lastName: person.lastName }),
      );
  }, [person?.doc?.id, person?.lastName, person?.name]);

  const isNewPerson = !!(pathname.match('/person') && !personId);

  const value = useMemo(
    () => ({
      constants,
      form,
      person: person || innerPerson,
      isNewPerson,
      fetching: ((!person && !innerPerson) || fetching) && !isNewPerson,
      editing: person && personId,
      creating,
      updating,
      onCreate: create,
      onUpdate: update,
    }),
    [
      create,
      creating,
      fetching,
      form,
      innerPerson,
      isNewPerson,
      person,
      personId,
      update,
      updating,
    ],
  );

  return (
    <PersonContext.Provider value={value}>{children}</PersonContext.Provider>
  );
};

export { PersonProvider };
