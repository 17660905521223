import { useLocation, useNavigate } from 'react-router-dom';

const useNav = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const backNavigate = (url: string) => {
    if (window.history.state && window.history.state.idx > 1) {
      navigate(-1);
    } else {
      navigate(url, { state, replace: true, preventScrollReset: true });
    }
  };

  return {
    backNavigate,
  };
};

export default useNav;
