import moment from 'moment';
import {
  FILTERS_PARAMS,
  FILTER_KEYS,
  KEYS_TO_PARAMS,
  PARAMS_TO_KEYS,
} from './constants';

const sendEmail = (email: string, subject: string = '', body: string = '') => {
  window.open(`mailto:${email}?subject=${subject}&body=${body}`);
};

const callPhone = (countryCode: string = '+549', phone: string) => {
  window.open(`tel:${countryCode}${phone.replace(/[() ]/g, '')}`);
};

const sendWhatsapp = (
  countryCode: string = '+549',
  phone: string,
  personName: string,
  professionalName: string,
  message?: string,
) => {
  const makeWhatsappMessage = () => {
    return (
      message ||
      `Hola *${personName}*, me llamo *${professionalName}* y me pongo en contacto contigo a traves de Medical Trace \n\n`
    );
  };
  window.open(
    `https://api.whatsapp.com/send?phone=${countryCode}${phone.replace(
      /[() ]/g,
      '',
    )}&text=${encodeURIComponent(makeWhatsappMessage())}`,
  );
};

const getCUIT = (document_number: any, gender: any) => {
  /**
   * Cuil format is: AB - document_number - C
   * Author: Nahuel Sanchez, Woile
   *
   * @param {str} document_number -> string solo digitos
   * @param {str} gender -> debe contener HOMBRE, MUJER o SOCIEDAD
   *
   * @return {str}
   **/

  const HOMBRE = ['HOMBRE', 'M', 'MALE'],
    MUJER = ['MUJER', 'F', 'FEMALE'],
    SOCIEDAD = ['SOCIEDAD', 'S', 'SOCIETY'];
  let AB, C;

  /**
   * Verifico que el document_number tenga exactamente ocho numeros y que
   * la cadena no contenga letras.
   */
  if (document_number.length != 8 || isNaN(document_number)) {
    if (document_number.length == 7 && !isNaN(document_number)) {
      document_number = '0'.concat(document_number);
    } else {
      // Muestro un error en caso de no serlo.
      console.error('El numero de document_number ingresado no es correcto.');
      return;
    }
  }

  /**
   * De esta manera permitimos que el gender venga en minusculas,
   * mayusculas y titulo.
   */
  gender = gender.toUpperCase();

  // Defino el valor del prefijo.
  if (HOMBRE.indexOf(gender) >= 0) {
    AB = '20';
  } else if (MUJER.indexOf(gender) >= 0) {
    AB = '27';
  } else {
    AB = '30';
  }

  /*
   * Los numeros (excepto los dos primeros) que le tengo que
   * multiplicar a la cadena formada por el prefijo y por el
   * numero de document_number los tengo almacenados en un arreglo.
   */
  const multiplicadores = [3, 2, 7, 6, 5, 4, 3, 2];

  // Realizo las dos primeras multiplicaciones por separado.
  let calculo: any = parseInt(AB.charAt(0)) * 5 + parseInt(AB.charAt(1)) * 4;

  /*
   * Recorro el arreglo y el numero de document_number para
   * realizar las multiplicaciones.
   */
  for (let i = 0; i < 8; i++) {
    calculo += parseInt(document_number.charAt(i)) * multiplicadores[i];
  }

  // Calculo el resto.
  let resto = parseInt(calculo) % 11;

  /*
   * Llevo a cabo la evaluacion de las tres condiciones para
   * determinar el valor de C y conocer el valor definitivo de
   * AB.
   */
  if (SOCIEDAD.indexOf(gender) < 0 && resto == 1) {
    if (HOMBRE.indexOf(gender) >= 0) {
      C = '9';
    } else {
      C = '4';
    }
    AB = '23';
  } else if (resto === 0) {
    C = '0';
  } else {
    C = 11 - resto;
  }
  // Generate cuit
  const cuil_cuit = `${AB}-${document_number}-${C}`;
  return cuil_cuit;
};

const getParamsToObject = (params: any) => {
  const aux: any = getValuesFromParams(params);

  if (PARAMS_TO_KEYS.b in aux) {
    aux['registeredAt'] = [
      moment(aux.begin, 'DD/MM/YYYY').toDate() || null,
      moment(aux.end, 'DD/MM/YYYY').toDate() || null,
    ];
    delete aux.begin;
    delete aux.end;
  }

  if (PARAMS_TO_KEYS.p in aux) aux.private = aux.private === 'true';
  if (PARAMS_TO_KEYS.pu in aux) aux.public = aux.public === 'true';

  return aux;
};

const getNumberOfFiltersApplied = (params: any) => {
  const filters = getParamsToObject(params);

  let counter = 0;

  if (
    (filters.public && !filters.private) ||
    (!filters.public && filters.private)
  )
    counter++;
  if (filters.order === 'asc') counter++;
  if (filters.createdBy) counter++;
  if (filters.madeBy) counter++;

  return counter;
};

export const getParamsFromValues = (values: any) =>
  FILTER_KEYS.reduce(
    (acc, key: any) => ({
      ...acc,
      ...(values[key] && { [(KEYS_TO_PARAMS as any)[key]]: btoa(values[key]) }),
    }),
    {},
  );

export const getValuesFromParams = (params: any) =>
  FILTERS_PARAMS.reduce(
    (acc, key: any) => ({
      ...acc,
      ...(params.get(key) && {
        [(PARAMS_TO_KEYS as any)[key]]: atob(params.get(key)),
      }),
    }),
    {},
  );

const numberWithCommas = (x: any) =>
  x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

//delete html tags
const removeHtmlTags = (str: string) => {
  return str?.replace(/<(?:.|\n)*?>/gm, '');
};

const setGetWitchCache = (key: string) =>
  sessionStorage.setItem(key, JSON.stringify({ noCache: 'true' }));

const getWithCache = (key: string) => {
  const value = JSON.parse(
    sessionStorage.getItem(key) || JSON.stringify({ noCache: 'false' }),
  );
  sessionStorage.removeItem(key);
  return value;
};

const getNameAndLastName = (value: string[]) => {
  let name = '';
  let lastName = '';

  if (value.length <= 2) {
    name = value[0];
    lastName = value.splice(1).join(' ');
    return [name, lastName];
  }

  if (value.length >= 3) {
    name = value.splice(-3, 2).join(' ');
    lastName = value.join(' ');
    return [name, lastName];
  }

  return [name, lastName];
};

const removeFirstAndSecondLastEmptySpace = (value: string) => {
  if (!value || !value.length) return value;
  return value.replace(/^\s+| \s+$/g, '');
};

export {
  removeFirstAndSecondLastEmptySpace,
  sendEmail,
  callPhone,
  sendWhatsapp,
  getCUIT,
  getParamsToObject,
  numberWithCommas,
  removeHtmlTags,
  getNumberOfFiltersApplied,
  setGetWitchCache,
  getWithCache,
  getNameAndLastName,
};
