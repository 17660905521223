import api from '../api';

const baseUrl =
  process.env.REACT_APP_API_URL || process.env.REACT_APP_MEDICAL_SERVICE_URL;
// const baseContext = `${baseUrl}/api/v1/medical`;
const baseContext = `${baseUrl}${process.env.REACT_APP_API_URL ? "/api/v1/medical":""}`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  //Get all medical
  getAllMedical: function () {
    return api.get(`${baseContext}`);
  },

  //Get all medical Emails
  getAllMedicalsEmails: function () {
    return api.get(`${baseContext}/emails`);
  },

  //Update professional
  updateProfessional: function (data) {
    return api.put(`${baseContext}`, data);
  },

  // Get the current professional
  getMe: function (data) {
    const { token, ...params } = data || {};
    return api.get(`${baseContext}/me`, {
      headers: token ? { Authorization: `Bearer ${token}` } : {},
      params: { ...params },
    });
  },

  // Download profile photo
  downloadProfilePhoto: function (medicalId, fileId) {
    return api.get(`${baseContext}/${medicalId}/file/${fileId}`, {
      responseType: 'blob',
    });
  },

  // Get all notificactions
  getAllNotifications: function (params) {
    return api.get(`${baseContext}/notifications`, { params: params });
  },

  // Set terms as accepted
  setTermsAsRead: function (token) {
    return api.put(`${baseContext}/terms/read`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  // Change temporal inital password
  changeTempPass: function () {
    return api.put(`${baseContext}/password/changed`);
  },

  //Save template
  saveTemplate: function (data) {
    return api.post(`${baseContext}/template`, data);
  },

  //Get all templates
  getAllTemplates: function (args) {
    return api.get(`${baseContext}/templates`, { params: { ...args } });
  },

  //Delete template
  deleteTemplate: function (templateId) {
    return api.delete(`${baseContext}/template/${templateId}`);
  },
};
