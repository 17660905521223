import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import SystemConfigurationReducer from "systemConfiguration/redux-sagas/reducer";
import MedicalProfileReducer from "pages/medicalProfile/redux-sagas/reducer";
import SystemProcessesReducer from "systemProcesses/redux-sagas/reducer";

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    // register reducers here...
    systemConfiguration: SystemConfigurationReducer,
    medicalProfile: MedicalProfileReducer,
    processes: SystemProcessesReducer,
  });

export default createRootReducer;
