export const GET_SPECIALITIES = "GET_SPECIALITIES";
export const GET_SPECIALITIES_ERROR = "GET_SPECIALITIES_ERROR";
export const GET_SPECIALITIES_SUCCESS = "GET_SPECIALITIES_SUCCESS";

export const GET_TERMS_AND_PRIVACITY = "GET_TERMS_AND_PRIVACITY";
export const GET_TERMS_AND_PRIVACITY_ERROR = "GET_TERMS_AND_PRIVACITY_ERROR";
export const GET_TERMS_AND_PRIVACITY_SUCCESS = "GET_TERMS_AND_PRIVACITY_SUCCESS";

export const GET_ALL_PROFESSIONALS_EMAILS = "GET_ALL_PROFESSIONALS_EMAILS";
export const GET_ALL_PROFESSIONALS_EMAILS_ERROR = "GET_ALL_PROFESSIONALS_EMAILS_ERROR";
export const GET_ALL_PROFESSIONALS_EMAILS_SUCCESS = "GET_ALL_PROFESSIONALS_EMAILS_SUCCESS";

export const GET_ALL_OSS = "GET_ALL_OSS";
export const GET_ALL_OSS_ERROR = "GET_ALL_OSS_ERROR";
export const GET_ALL_OSS_SUCCESS = "GET_ALL_OSS_SUCCESS";

export const GET_ALL_ZIP = "GET_ALL_ZIP";
export const GET_ALL_ZIP_ERROR = "GET_ALL_ZIP_ERROR";
export const GET_ALL_ZIP_SUCCESS = "GET_ALL_ZIP_SUCCESS";

export const GET_ALL_PHONE_CODE = "GET_ALL_PHONE_CODE";
export const GET_ALL_PHONE_CODE_ERROR = "GET_ALL_PHONE_CODE_ERROR";
export const GET_ALL_PHONE_CODE_SUCCESS = "GET_ALL_PHONE_CODE_SUCCESS";