import { MedicalStudyApi } from 'api';
import useFetch from 'hooks/useFetch';
import { useFile } from 'hooks/useFiles';
import { memo, useEffect, useState } from 'react';
import style from './index.module.scss';
import { DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';
import { useRecord } from 'provider/recordProvider';
import useTour from 'hooks/useTour';
import { useRecordUpsert } from 'hooks/useRecord';

const MAX_FILES = 5;

const RecordFiles = () => {
  const { ref } = useTour();
  const { record, isNewRecord } = useRecord();
  const { onChange, editing, data } = useRecordUpsert();
  const { downloadFile } = useFetch(MedicalStudyApi);
  const {
    UploadComponent,
    getBaseFromBlob,
    downloadFile: download,
    setFileList,
    setMax,
    ImgFile,
  } = useFile({
    max: MAX_FILES,
    showRemaining: true,
    label: (
      <>
        <PaperClipOutlined /> Adjuntar
      </>
    ),
  });

  const [deletedFile, setDeletedFile] = useState<any[]>([]);

  const innerDownload = async (file: any) => {
    if (editing) return;
    download(
      (await getBaseFromBlob(
        await downloadFile(record?.doc?.id, file.id),
      )) as string,
      file.fileName,
    );
  };

  const callBack = (files: []) => {
    if (editing) {
      onChange('files', [
        ...files.map((e: any) => ({ action: 'new', ...e })),
        ...data.current?.files?.filter((f: any) => f.action === 'delete'),
      ]);
      return;
    } else
      onChange(
        'files',
        files.map((e: any) => ({ action: 'new', ...e })),
      );
  };

  useEffect(() => {
    onChange('files', record?.files || []);
    setDeletedFile([]);
    if (!editing) return;
    setMax(MAX_FILES - record?.files.length);
    setFileList([]);
  }, [editing]);

  const onDelete = (v: any) => {
    if (deletedFile.find((e) => e.id === v.id)) return;
    setDeletedFile((d: any[]) => [...d, v]);
    setMax(MAX_FILES - record?.files?.length + deletedFile.length + 1);
    onChange('files', [
      ...data.current.files.filter(
        (f: any) => f.action === 'delete' || f.action === 'new',
      ),
      { action: 'delete', ...v },
    ]);
  };

  return (
    <div className={style.record_files}>
      {!!record?.files?.length && (
        <div className={style.record_files_uploaded}>
          {record?.files
            ?.filter((e: any) => !!e.fileName)
            ?.filter((f: any) => !!!deletedFile.find((e) => e.id === f.id))
            .map((file: any, i: number) => (
              <div
                title="Descargar archivo"
                onClick={() => innerDownload(file)}
                key={`file-${file.id}`}
                className={style.file}
              >
                <div className={style.medical_study_file_icon}>
                  {ImgFile[file.fileType] || ImgFile.default}
                </div>
                <div className={style.medical_study_file_name}>
                  <p title={file.fileName}>{file.fileName}</p>
                </div>
                {editing && (
                  <div
                    title="Eliminar archivo"
                    className={style.medical_study_files_delete_button}
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete(file);
                    }}
                  >
                    <DeleteOutlined />
                  </div>
                )}
              </div>
            ))}
        </div>
      )}
      {(editing || isNewRecord) && (
        <div
          ref={(r) => ref('record_file', r)}
          className={style.record_file_upload}
        >
          {UploadComponent(callBack)}
        </div>
      )}
    </div>
  );
};

export default memo(RecordFiles);
