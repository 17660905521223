import { Button, Form, Modal } from 'antd';
import { ChangePassword } from 'components';
import { useNotify } from 'hooks/useNotify';
import { memo, useEffect, useState } from 'react';
import { CloseOutlined, LockOutlined } from '@ant-design/icons';
import style from './index.module.scss';

type props = {
  onClose: Function;
  show: boolean;
};

const ChangePasswordModal = ({ show, onClose }: props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { openSuccessNotify } = useNotify();

  const innerClose = () => {
    setLoading(false);
    onClose(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [show]);

  return (
    <Modal
      style={{
        margin: 'auto',
        top: '0px',
      }}
      onCancel={innerClose}
      footer={[
        <Button key="close-change-password-modal" onClick={innerClose}>
          <CloseOutlined /> Cerrar
        </Button>,
        <Button
          key="apply-change-password-modal"
          loading={loading}
          onClick={() => {
            form.validateFields().then(() => {
              setLoading(true);
              form.submit();
            });
          }}
        >
          <LockOutlined />
          Cambiar contraseña
        </Button>,
      ]}
      title={<p className={style.change_password_title}>Cambiar contraseña</p>}
      open={show}
    >
      <ChangePassword
        onError={() => setLoading(false)}
        form={form}
        hideTitle
        hideButton
        onChange={() => {
          openSuccessNotify('Contraseña modificada con éxito!');
          setLoading(false);
          innerClose();
        }}
      />
    </Modal>
  );
};

export default memo(ChangePasswordModal);
