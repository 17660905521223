import { memo } from 'react';
import { LogoutOutlined, WarningOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import style from './index.module.scss';
import { useAuth } from 'hooks/useAuth';

const Disabled = () => {
  const { signOut } = useAuth();
  const {
    medicalProfile: { claims },
  } = useSelector((state: any) => state.medicalProfile);
  return (
    <div className={style.disabled}>
      <div>
        <WarningOutlined
          style={{ fontSize: '2rem', color: 'red', marginBottom: '10px' }}
        />
        <p key="disabled-1">
          Su cuenta se encuentra suspendida por el siguiente motivo
        </p>
        <p key="disabled-2">
          <b>{claims.disabled.reason}</b>
        </p>

        <p key="disabled-3">
          Por favor contacte al administrador para mayor información.
        </p>
      </div>
      <Button onClick={signOut}>
        <LogoutOutlined /> Cerrar sesión
      </Button>
    </div>
  );
};

export default memo(Disabled);
