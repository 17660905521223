import { memo, useState } from 'react';
import { Modal } from 'antd';
import style from './index.module.scss';
import { sendEmail } from 'utilities';

type props = {
  emails: string[];
  children?: any;
  title: any;
};

const SharedProfessional = ({ emails, children, title }: props) => {
  const [open, setOpen] = useState(false);

  const sendMail = (e: any, email: string) => {
    e.stopPropagation();
    sendEmail(email);
  };

  return (
    <>
      {children(setOpen)}
      {open && (
        <Modal
          footer={false}
          style={{
            margin: 'auto',
            top: '0px',
            height: '40vh',
          }}
          width={'65vh'}
          title={title}
          onCancel={(e) => {
            e.stopPropagation();
            setOpen(false);
          }}
          open={open && !!emails.length}
        >
          <div className={style.email_list}>
            {emails.map((s) => (
              <div
                key={s}
                onClick={(e) => sendMail(e, s)}
                className={style.email_row}
              >
                {s}
              </div>
            ))}
          </div>
        </Modal>
      )}
    </>
  );
};

export default memo(SharedProfessional);
