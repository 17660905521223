import { Button, Col, Modal, Row } from 'antd';
import { MedicalApi } from 'api';
import { List } from 'components';
import useDate from 'hooks/useDate';
import useFetch from 'hooks/useFetch';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  CheckOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import style from './index.module.scss';
import confirm from 'antd/lib/modal/confirm';
import { DownloadOutlined } from '@ant-design/icons';
import { useRecordUpsert } from 'hooks/useRecord';
import { fastModalClass } from 'utilities';

const TemplateList = () => {
  const { setTemplate } = useRecordUpsert();
  const { applyFormat } = useDate();
  const [show, setShow] = useState(false);
  const {
    loading: { getAllTemplates: loading },
    data: { getAllTemplates: templates },
    getAllTemplates,
    deleteTemplate,
  } = useFetch(MedicalApi);

  const onDelete = useCallback(async (id: string) => {
    confirm({
      maskClosable: true,
      className: fastModalClass,
      title: 'Estas seguro de eliminar este template?',
      icon: <ExclamationCircleOutlined />,
      content: 'No podrás recuperarlo después de eliminarlo',
      okText: 'Si, eliminar',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        await deleteTemplate(id);
        getAllTemplates({ noCache: true });
      },
      onCancel() {},
    });
  }, []);

  const Item = useCallback(
    ({ data }: any) => (
      <Row>
        <Col className={style.item_element} span={8}>
          {data.name}
        </Col>
        <Col className={style.item_element} span={8}>
          {applyFormat(data.createdAt, 'DD/MM/YYYY HH:mm [hs]')}
        </Col>
        <Col className={style.item_actions} span={8}>
          <Button onClick={() => onDelete(data.id)}>
            <DeleteOutlined />
          </Button>
          <Button
            onClick={() => {
              setTemplate(data);
              innerCancel();
            }}
          >
            <CheckOutlined />
          </Button>
        </Col>
      </Row>
    ),
    [applyFormat, onDelete, setTemplate],
  );

  const innerCancel = () => {
    setShow(false);
  };

  useEffect(() => {
    getAllTemplates && show && getAllTemplates({ noCache: true });
  }, [getAllTemplates, show]);

  return (
    <>
      <Button title="Aplicar plantilla" onClick={() => setShow(true)}>
        <DownloadOutlined />
      </Button>
      <Modal
        title="Aplicar plantilla"
        width={'50%'}
        style={{
          margin: 'auto',
          top: '0px',
        }}
        bodyStyle={{
          height: '200px',
        }}
        onCancel={innerCancel}
        footer={[
          <Button key="back" onClick={innerCancel}>
            Cerrar
          </Button>,
        ]}
        open={show}
      >
        <List
          loading={loading}
          data={templates?.items || []}
          onRender={(v: any) => ({ data: v })}
          Item={Item}
        />
      </Modal>
    </>
  );
};

export default memo(TemplateList);
