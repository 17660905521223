/* eslint-disable react-hooks/exhaustive-deps */
import MoreOptionRecord from 'components/moreOptionRecord';
import { useRecord } from 'provider/recordProvider';
import { memo, useCallback, useMemo, useState } from 'react';
import ReactQuill from 'react-quill';
import { MAX_LENGTH_DESCRIPTION, removeHtmlTags } from 'utilities';
import style from './index.module.scss';
import 'react-quill/dist/quill.snow.css';
import { Col, Row } from 'antd';
import useTour from 'hooks/useTour';
import { useRecordUpsert } from 'hooks/useRecord';

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],
  [{ header: 1 }, { header: 2 }], // custom button innerValues
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ direction: 'rtl' }], // text direction
  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ align: [] }],
  ['clean'], // remove formatting button
  [{ font: [] }],
];

const RecordEditor = () => {
  const { ref } = useTour();
  const { selectedTemplate, onChange, data, editing } = useRecordUpsert();
  const { isPreviewRecord, isNewRecord } = useRecord();
  const [counter, setCounter] = useState(
    removeHtmlTags(data.current?.description || '').length || 0,
  );
  const [description, setDescription] = useState('');

  const innerSetDescription = useCallback(
    (v: any) => {
      setCounter(removeHtmlTags(v).length);
      if (removeHtmlTags(v).length > MAX_LENGTH_DESCRIPTION) return;
      setDescription(v);
      onChange('description', v);
    },
    [onChange],
  );

  const canShowEditor =
    (data.current?.description &&
      (editing || isPreviewRecord || isNewRecord)) ||
    (!data.current?.description && (isNewRecord || editing));

  const QuillMemorized = useMemo(
    () =>
      (canShowEditor && (
        <ReactQuill
          value={
            selectedTemplate?.template ||
            data.current.description ||
            description
          }
          className={isPreviewRecord && !editing ? style.hidden : ''}
          readOnly={isPreviewRecord && !editing}
          modules={{
            toolbar: toolbarOptions,
          }}
          theme="snow"
          onChange={innerSetDescription}
        />
      )) || (
        <MoreOptionRecord
          record={data.current}
          text="No tienes permisos para ver este registro,"
        />
      ),
    [selectedTemplate, innerSetDescription, isPreviewRecord, editing],
  );

  return (
    <>
      <Row className={style.editor}>
        <Col ref={(r) => ref('record_editor', r)} span={24}>
          <div className={style.text_editor}>{QuillMemorized}</div>
        </Col>
      </Row>
      {(isNewRecord || editing) && (
        <div className={style.counter}>
          <div
            className={`${counter > MAX_LENGTH_DESCRIPTION ? style.max : ''}`}
          >{`${counter}/${MAX_LENGTH_DESCRIPTION}`}</div>
        </div>
      )}
    </>
  );
};

export default memo(RecordEditor);
