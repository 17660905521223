import { MedicalApi } from 'api';
import { useCallback, useEffect } from 'react';
import useFetch from './useFetch';

type props = {
  fromNoCache?: boolean;
};

const useNotification = ({ fromNoCache = false }: props = {}) => {
  const {
    data: { getAllNotifications: notifications },
    getAllNotifications,
  } = useFetch(MedicalApi);

  const getNotification = useCallback(
    (noCache = false) =>
      getAllNotifications && getAllNotifications({ noCache }),
    [getAllNotifications],
  );

  useEffect(() => {
    getNotification && getNotification(fromNoCache);
  }, [fromNoCache, getNotification]);

  return { notifications: notifications?.items || [], getNotification };
};

export default useNotification;
