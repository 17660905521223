import { memo, useEffect, useMemo, useRef, useState } from 'react';
import {
  SendOutlined,
  SmileOutlined,
  SolutionOutlined,
  UserOutlined,
  LockOutlined,
} from '@ant-design/icons';
import { Button, Steps } from 'antd';
import style from './index.module.scss';
import { ChangePassword } from 'components';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuth } from 'hooks/useAuth';

const { Step } = Steps;

type emailContentTypes = {
  setCurrent: any;
  current: any;
  claims: any;
};
const EmailContent = memo(
  ({ setCurrent, current, claims }: emailContentTypes) => {
    const [loadingEmail, setLoading] = useState(false);
    const [count, setCount] = useState(60);
    const interval = useRef<any>();
    const { validateEmail, isEmailVerified } = useAuth();

    const checkValidateEmail = async (refresh: boolean) => {
      interval.current && clearInterval(interval.current);
      interval.current = setInterval(async () => {
        if (await isEmailVerified(refresh)) {
          setLoading(false);
          setCurrent(2);
          clearInterval(interval.current);
        }
      }, 1000);
    };

    const sendValidateEmail = () => {
      validateEmail();
      setLoading(true);
      checkValidateEmail(true);
    };

    useEffect(() => {
      if (!loadingEmail || current !== 1) return;
      (async () => {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setCount((count) => count - 1);
        if (count === 0) {
          setLoading(false);
          setCount(60);
        }
      })();
    }, [count, current, loadingEmail]);

    useEffect(() => {
      if (current === 1 && !claims.emailVerified) {
        checkValidateEmail(true);
      }
      return () => {
        interval.current && clearInterval(interval.current);
      };
    }, []);

    return (
      <>
        <h3>Para continuar debes validar tu correo electrónico</h3>
        <div>
          <p>
            Enviaremos un correo electrónico a tu cuenta con un enlace para que
            puedas validar tu correo electrónico.
          </p>
          <Button loading={loadingEmail} onClick={() => sendValidateEmail()}>
            <SendOutlined />{' '}
            {loadingEmail
              ? `Intentar nuevamente en ${count}s`
              : 'Enviar correo'}
          </Button>
        </div>
      </>
    );
  },
);

const InitProcess = () => {
  const navigate = useNavigate();
  const {
    medicalProfile: { claims },
  } = useSelector((state: any) => state.medicalProfile);

  const [current, setCurrent] = useState(
    claims.emailVerified && !claims.temporalPass
      ? 3
      : (claims.emailVerified && 2) || 1,
  );

  const steps = useMemo(
    () => [
      {
        id: 'first',
        title: <p className={style.step_title}>Inicio de sesión</p>,
        status: 'finish',
        icon: <UserOutlined />,
      },
      {
        id: 'second',
        title: <p className={style.step_title}>Validar Email</p>,
        status: 'process',
        content: (
          <EmailContent
            current={current}
            setCurrent={setCurrent}
            claims={claims}
          />
        ),
        icon: <SolutionOutlined />,
      },
      {
        id: 'third',
        title: <p className={style.step_title}>Cambiar contraseña</p>,
        status: 'wait',
        content: (
          <ChangePassword
            onChange={() => {
              setCurrent(3);
            }}
          />
        ),
        icon: <LockOutlined />,
      },
      {
        id: 'fourth',
        title: <p className={style.step_title}>Finalizar</p>,
        status: 'wait',
        content: (
          <div>
            <h3>¡Bienvenido! a Medical Trace</h3>
            <p>Ya puedes registrar tus informes profesionales</p>
            <Button
              className="mt_secondary_button"
              onClick={() => navigate('/', { replace: true })}
            >
              Ir al Inicio
            </Button>
          </div>
        ),
        icon: <SmileOutlined />,
      },
    ],
    [claims, current, navigate],
  );

  useEffect(() => {
    if (current === 2 && 'temporalPass' in claims && !claims.temporalPass) {
      setCurrent(3);
    }
  }, [current, claims.temporalPass]);

  return (
    <div className={style.init_process}>
      <div className={style.content}>
        <Steps current={current}>
          {steps.map((item) => (
            <Step key={item.id} title={item.title} />
          ))}
        </Steps>
        <div className={style.steps_content}>{steps[current].content}</div>
      </div>
    </div>
  );
};

export default memo(InitProcess);
