import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import * as serviceWorker from './serviceWorker';
import Routers from './routers';
import reportWebVitals from './reportWebVitals';
import LanguageProvider from 'provider/language';
import i18n from 'i18n';
import './index.scss';
import { AuthProvider } from 'provider/authContext';
import 'dayjs/locale/es';
import { ConfigProvider } from 'antd';
import locale from 'antd/locale/es_ES';
import { TourProvider } from 'provider/tourProvider';

createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <LanguageProvider i18n={i18n}>
        <AuthProvider>
          <ConfigProvider locale={locale}>
            <TourProvider>
              <Routers />
            </TourProvider>
          </ConfigProvider>
        </AuthProvider>
      </LanguageProvider>
    </PersistGate>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
