import { Empty, Modal } from 'antd';
import { memo } from 'react';
import style from './index.module.scss';
import { DiagnosticsItem } from 'components';
import { usePerson } from 'hooks/usePerson';

type props = {
  onClose: () => void;
  show: boolean;
};

const AntecedentsModal = ({ show, onClose }: props) => {
  const { person } = usePerson();

  const sort = (array?: any[]) => {
    return (
      array?.sort(({ antecedents: a }: any, { antecedents: b }: any) => {
        if (a.length > b.length) return -1;
        if (a.length < b.length) return 1;
        return 0;
      }) || []
    );
  };

  return (
    <>
      <Modal
        footer={false}
        style={{
          margin: 'auto',
          top: '0px',
          height: '90vh',
        }}
        width={'75%'}
        title={<h2 style={{ margin: 0 }}>Antecedentes</h2>}
        closable
        onCancel={onClose}
        className={style.modal_antecedents}
        open={show}
      >
        {(!!person.antecedents?.items?.length &&
          sort(person.antecedents?.items).map((a) => (
            <DiagnosticsItem type="antecedents" key={a.code} diagnostics={a} />
          ))) || (
          <div className={style.modal_empty}>
            <Empty description="Sin antecedentes registrados" />
          </div>
        )}
      </Modal>
    </>
  );
};

export default memo(AntecedentsModal);
