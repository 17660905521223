import { Col, Row, Select, SelectProps } from 'antd';
import { useSelector } from 'react-redux';
import { useState, memo } from 'react';
import style from './index.module.scss';
import useTour from 'hooks/useTour';
import { useRecordUpsert } from 'hooks/useRecord';

type props = {
  showMode: 'full' | 'partial';
  disabledVisibility?: boolean;
};

const RelatedInputs = memo(
  ({ showMode = 'full', disabledVisibility = false }: props) => {
    const { ref } = useTour();
    const { onChange, data } = useRecordUpsert();
    const [visibility, setVisibilit] = useState(
      data.current.visibility || 'public',
    );

    const { professionalsEmails, loading } = useSelector(
      (state: any) => state.systemConfiguration,
    );
    const { medicalProfile } = useSelector(
      (state: any) => state.medicalProfile,
    );

    const selectProps: SelectProps = {
      mode: 'multiple',
      maxTagCount: 'responsive',
      allowClear: true,
      filterOption: (input: any, option: any) =>
        (option!.children as unknown as string)
          .toLowerCase()
          .includes(input.toLowerCase()),
      filterSort: (optionA, optionB) =>
        (optionA?.email ?? '')
          .toLowerCase()
          .localeCompare((optionB?.email ?? '').toLowerCase()),
    };

    const VisibilitySelect = (
      <Col span={3}>
        <Select
          disabled={disabledVisibility}
          value={visibility}
          {...(disabledVisibility && { className: style.disabledInput })}
          defaultValue={data.current.visibility || 'public'}
          onChange={(v) => {
            onChange('visibility', v);
            setVisibilit(v);
          }}
          placeholder="Visibilidad"
        >
          <Select.Option value="public">Público</Select.Option>
          <Select.Option value="private">Privado</Select.Option>
        </Select>
      </Col>
    );

    return (
      (showMode === 'full' && (
        <Row ref={(r) => ref('record_visibility', r)} gutter={15}>
          {VisibilitySelect}
          {visibility === 'public' && (
            <Col span={21}>
              <Select
                defaultValue={data.current.canSee || []}
                onChange={(v) => onChange('canSee', v)}
                loading={loading}
                placeholder="Seleccione profesionales con acceso al informe"
                {...selectProps}
              >
                {professionalsEmails
                  .filter(({ email }: any) => email !== medicalProfile.email)
                  .map(({ email, uid }: any) => (
                    <Select.Option key={uid}>{email}</Select.Option>
                  ))}
              </Select>
            </Col>
          )}
        </Row>
      )) ||
      VisibilitySelect
    );
  },
);

export default memo(RelatedInputs);
