export type builderSteps =
  | 'main_list'
  | 'search_bar'
  | 'icd_button'
  | 'profile'
  | 'person_information'
  | 'person_action'
  | 'person_counter'
  | 'person_records_list'
  | 'person_date_filter'
  | 'person_filters'
  | 'person_add_record'
  | 'record_file'
  | 'record_editor'
  | 'record_visibility'
  | 'record_icd'
  | 'record_template_add'
  | 'record_template_get'
  | 'record_date'
  | 'record_title';

export type stepFather = 'home' | 'people' | 'record';

const buttons = {
  nextButtonProps: { children: 'Siguiente' },
  prevButtonProps: { children: 'Anterior' },
};

const getImg = (img: string) => `${window.location.origin}/assets/icons/${img}`;

export const tourBuilder = {
  home: {
    search_bar: {
      title: 'Barra de Busqueda',
      description:
        'En esta barra de búsqueda puedes buscar personas según su DNI o CUIL, realizando la búsqueda por este último valor la búsqueda será más precisa.',
      ...buttons,
    },
    main_list: {
      title: 'Lista Principal',
      description:
        'En este apartado, visualizarán los últimos 5 informes que realizaste como profesional de la salud.',
      ...buttons,
      placement: 'top',
    },
    profile: {
      title: 'Perfil',
      cover: getImg('profile-medical-trace.png'),
      description:
        'Accede a tu Perfil, tus Avisos y podrás salir de la sesión actual.',
      ...buttons,
      placement: 'top',
    },
    icd_button: {
      title: 'Clasificación Internacional de Enfermedades, 11.a revisión',
      description:
        'Haciendo click en este apartado podrás consultar el listado de enfermedades registradas a nivel mundial.',
      ...buttons,
      cover: getImg('icd-medical-trace.png'),
      placement: 'right',
    },
  },
  people: {
    person_information: {
      title: 'Información general de la persona',
      description:
        'En este apartado podras visualizar de forma rapida informacion importante de la persona, como edad, sexo, documento, obra social, nombre, apelido y foto',
      ...buttons,
    },
    person_action: {
      title: 'Acciones',
      cover: getImg('person-action-medical-trace.png'),
      description:
        'Podrás contactar a la persona por medio de su teléfono (si se encuentra configurado), ver un detalle de dirección, obra social e información de contacto, así como también la posibilidad de editar los datos.',
      ...buttons,
    },
    person_counter: {
      title: 'Contadores',
      description:
        'Estos contadores son una ayuda visual rápida para saber cuantos informes, archivos adjuntos, diagnósticos y antecedentes PÚBLICOS con los que cuenta la persona. Haciendo click en cada uno de ellos se podrá ver un detalle de los mismos, teniendo en cuenta que solo se podrán ver los detalles compartidos unicamentes.',
      ...buttons,
    },
    person_records_list: {
      title: 'Lista de Informes vincuados a la persona',
      description:
        'En este listado podrás ver todos los informes "públicos" que se le han cargados a la persona, asi como tambien tus informes (públicos y privados), para poder ver los informes públicos en su totalidad, necesitarás que el profesional propietario del mismo te lo comparta.',
      ...buttons,
    },
    person_date_filter: {
      title: 'Filtros por rango de fecha',
      description:
        'Con esta herramienta podrás filtrar los registros por un rango de fecha de inicio y/o fin.',
      ...buttons,
    },
    person_filters: {
      title: 'Filtros',
      cover: getImg('person-filter-medical-trace.png'),
      description:
        'Haciendo click sobre este botón, verás todos los filtros disponibles para interactuar con el listado de informes.',
      ...buttons,
    },
    person_add_record: {
      title: 'Agregar Informe',
      description:
        'Haciendo click sobre este botón, podrás agregar nuevos informes a la persona.',
      ...buttons,
    },
  },
  record: {
    record_title: {
      title: 'Titulo del Informe',
      description: 'Cada informe registrado debe contar con un título.',
      ...buttons,
    },
    record_date: {
      title: 'Fecha del Informe',
      description: 'Por defecto se configurará en la fecha actual.',
      ...buttons,
    },
    record_template_add: {
      title: 'Agregar Plantilla',
      description:
        'Podrás guardar plantillas para posteriormente usarlas en futuros informes.',
      ...buttons,
    },
    record_template_get: {
      title: 'Usar Plantillas',
      description:
        'Podrás seleccionar una plantilla previamente guardada para usarla en este informe.',
      ...buttons,
    },
    record_icd: {
      title: 'Diagnósticos',
      description:
        'Haciendo click en este botón, podrás agregar diagnósticos según el CIE-11 a este informes.',
      ...buttons,
    },
    record_visibility: {
      title: 'Visibilidad',
      description:
        'El informe puede ser "privado" (solo será visible para usted) o "público", el cual habilitará que el informe aparezca en el lista de la persona, pero para que otro profesional pueda ver su contenido deberá compartirlo por medio de su correo electrónico.',
      ...buttons,
    },
    record_editor: {
      title: 'Editor de Texto',
      description: 'Contenido del informe.',
      ...buttons,
    },
    record_file: {
      title: 'Archivos Adjuntos',
      description:
        'Por medio de esta área podrá arrastrar archivos (o haciendo click) que desee adjuntar al informe.',
      ...buttons,
    },
  },
};
