import { useEffect, useState } from 'react';
import { useNotify } from './useNotify';

const auxApi: any = {};
const auxError: any = {};
const auxloading: any = {};
const auxData: any = {};

type props = {
  clearBefore?: boolean;
  showNotification?: {
    error?: {
      show?: { [x: string]: boolean } | boolean;
      message?: { [x: string]: string } | string;
    };
    success?: {
      show?: { [x: string]: boolean } | boolean;
      message?: { [x: string]: string } | string;
    };
  };
};

const useFetch = (api: { [x: string]: Function }, opts?: props) => {
  opts = {
    ...{
      clearBefore: true,
      showNotification: {
        error: { show: undefined, message: undefined },
        success: { show: undefined, message: undefined },
      },
    },
    ...opts,
  };

  const { openSuccessNotify, openErrorNotify } = useNotify();
  const [_innerApi] = useState<any>(api);
  const [loading, setLoading] = useState<any>({});
  const [innerApi, setInnerApi] = useState<any>({});
  const [data, setData] = useState<any>({});

  useEffect(() => {
    Object.keys(_innerApi).forEach((key) => {
      auxApi[key] = async (...args: any[]) => {
        if (opts!.clearBefore) clearData();

        setLoading((state: any) => ({ ...state, [key]: true }));

        const response = await _innerApi[key](...args)
          ?.catch((err: any) => {
            if (
              typeof opts?.showNotification?.error?.show === 'object'
                ? opts?.showNotification?.error?.show?.[key]
                : opts?.showNotification?.error?.show
            )
              openErrorNotify(
                typeof opts?.showNotification?.error?.message === 'string'
                  ? opts?.showNotification?.error?.message
                  : opts?.showNotification?.error?.message?.[key] ||
                      err.response?.data?.errors?.[0]?.detail ||
                      'Algo salio mal!',
              );
            throw err;
          })
          .finally(() => {
            setLoading((state: any) => ({ ...state, [key]: false }));
          });

        if (
          typeof opts?.showNotification?.success?.show === 'object'
            ? opts?.showNotification?.success?.show?.[key]
            : opts?.showNotification?.success?.show
        )
          openSuccessNotify(
            typeof opts?.showNotification?.success?.message === 'string'
              ? opts?.showNotification?.success?.message
              : opts?.showNotification?.success?.message?.[key] ||
                  'Operacion exitosa',
          );

        setData((data: any) => ({ ...data, [key]: response?.data || {} }));
        return response?.data || {};
      };

      auxError[key] = null;
      auxloading[key] = false;
      auxData[key] = null;
    });

    setInnerApi({ ...auxApi });
    clearData();
  }, [_innerApi]);

  const clearData = () => {
    setLoading({ ...auxloading });
    setData({ ...auxData });
  };

  return {
    ...innerApi,
    loading,
    data,
    clearData,
  };
};

export default useFetch;
