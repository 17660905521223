import { FameleIcon, MaleIcon } from 'icons';

export const GENDE_TYPE = [
  { id: 'M', name: 'Masculino', icon: MaleIcon },
  { id: 'F', name: 'Femenino', icon: FameleIcon },
  { id: 'O', name: 'Otro', icon: () => null },
  { id: 'N', name: 'No especificado', icon: () => null },
];

export const FILTER_KEYS = [
  'begin',
  'end',
  'private',
  'public',
  'madeBy',
  'createdBy',
  'sort',
  'order',
];

export const fastModalClass = 'fast-modal';

export const FILTERS_PARAMS = ['b', 'e', 'p', 'pu', 'm', 'c', 's', 'o'];

export enum KEYS_TO_PARAMS {
  'begin' = 'b',
  'end' = 'e',
  'private' = 'p',
  'public' = 'pu',
  'madeBy' = 'm',
  'createdBy' = 'c',
  'sort' = 's',
  'order' = 'o',
}

export enum PARAMS_TO_KEYS {
  'b' = 'begin',
  'e' = 'end',
  'p' = 'private',
  'pu' = 'public',
  'm' = 'madeBy',
  'c' = 'createdBy',
  's' = 'sort',
  'o' = 'order',
}

export const MAX_LENGTH_DESCRIPTION = 3000;

export const CACHE_RECORD_KEY = 'cache-record';
export const CACHE_LAST_RECORD_KEY = 'cache-last-record';
export const CACHE_PERSON_KEY = 'cache-person';
export const CACHE_PERSON_IMG_KEY = 'cache-person-img';
