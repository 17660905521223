import style from './index.module.scss';
import { memo, useEffect, useRef, useState } from 'react';
import { Button, Empty, Modal } from 'antd';
import { HeartOutlined } from '@ant-design/icons';
import useFetch from 'hooks/useFetch';
import { DiagnosticsApi } from 'api';
import DiagnosticsItem from 'components/diagnosticsItem';
import { LoadingOutlined } from '@ant-design/icons';
import { usePerson } from 'hooks/usePerson';

type props = {
  show: boolean;
  onClose: () => void;
};

const DiagnosisModal = ({ show, onClose }: props) => {
  const { person } = usePerson();
  const {
    getDiagnostics,
    loading: { getDiagnostics: loading },
  } = useFetch(DiagnosticsApi);
  const [diagnostics, setDiagnostics] = useState([]);
  const last = useRef();

  const getInnerFiles = async () => {
    const { items, lastElement } = await getDiagnostics(person.doc?.id);
    setDiagnostics(items);
    last.current = lastElement;
  };

  useEffect(() => {
    if (!show || !getDiagnostics) return;
    getInnerFiles();
  }, [show, getDiagnostics]);

  const sort = (array?: any[]) => {
    return (
      array?.sort(({ diagnostics: a }: any, { diagnostics: b }: any) => {
        if (a.length > b.length) return -1;
        if (a.length < b.length) return 1;
        return 0;
      }) || []
    );
  };

  return (
    <>
      {show && (
        <Modal
          footer={false}
          style={{
            margin: 'auto',
            top: '0px',
            height: '90vh',
          }}
          width={'75%'}
          title={<h2 style={{ margin: 0 }}>Diagnosticos</h2>}
          onCancel={onClose}
          className={style.modal_diagnosis}
          open={show}
        >
          {(!!diagnostics.length &&
            !loading &&
            sort(diagnostics).map((a) => (
              <DiagnosticsItem
                type="diagnostics"
                key={a.code}
                diagnostics={a}
              />
            ))) ||
            (!loading && (
              <div className={style.modal_empty}>
                <Empty description="Sin diagnosticos registrados" />
              </div>
            ))}
          {loading && (
            <div className={style.diagnosis_loading}>
              {loading && <LoadingOutlined />} Cargando...
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default memo(DiagnosisModal);
