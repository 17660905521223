import { Button, Form, FormInstance, Input } from 'antd';
import { memo, useState } from 'react';
import { required } from 'utilities';
import style from './index.module.scss';
import { LockOutlined } from '@ant-design/icons';
import { useAuth } from 'hooks/useAuth';

type props = {
  onChange: Function;
  onError?: Function;
  hideTitle?: boolean;
  hideButton?: boolean;
  form?: FormInstance;
};

const ChangePassword = ({
  onChange,
  hideTitle,
  form,
  hideButton,
  onError,
}: props) => {
  const { changePassword } = useAuth();
  const [loading, setLoading] = useState(false);
  const [innerForm] = Form.useForm();

  const onFinish = async (values: any) => {
    try {
      if (values.newPassword === values.repeatPassword) {
        !hideTitle && setLoading(true);
        await changePassword(values.oldPassword, values.newPassword);
        onChange && onChange();
      }
    } catch (error) {
      console.log(error);
      onError && onError(error);
    } finally {
      !hideTitle && setLoading(false);
    }
  };

  return (
    <div className={style.change_password}>
      {!hideTitle && (
        <h1 className={style.change_password_title}>Cambiar contraseña</h1>
      )}
      <Form form={form || innerForm} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="oldPassword"
          label="Contraseña Antigua"
          rules={[required]}
        >
          <Input.Password
            onChange={() =>
              form?.setFieldsValue({
                repeatPassword: undefined,
                newPassword: undefined,
              })
            }
            type="text"
            showCount
            minLength={6}
            maxLength={20}
            placeholder="Contraseña Antigua"
          />
        </Form.Item>
        <Form.Item
          name="newPassword"
          label="Nueva Contraseña"
          rules={[
            required,
            { min: 6, message: 'Longitud minima 6 caracteres' },
          ]}
        >
          <Input.Password
            onChange={() => form?.setFieldsValue({ repeatPassword: undefined })}
            type="text"
            showCount
            minLength={6}
            maxLength={20}
            placeholder="Nueva Contraseña"
          />
        </Form.Item>
        <Form.Item
          name="repeatPassword"
          label="Repetir Contraseña"
          rules={[
            required,
            { min: 6, message: 'Longitud minima 6 caracteres' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('Las contraseñas no coinciden');
              },
            }),
          ]}
        >
          <Input.Password
            type="text"
            showCount
            minLength={6}
            maxLength={20}
            placeholder="Repetir Contraseña"
          />
        </Form.Item>
        {!hideButton && (
          <Button loading={loading} htmlType="submit">
            <LockOutlined /> Cambiar contraseña
          </Button>
        )}
      </Form>
    </div>
  );
};

export default memo(ChangePassword);
