import { useCallback, useEffect, useState } from 'react';
import useFetch from './useFetch';
import { useFile } from './useFiles';

const defaultImg =
  'https://thumbs.dreamstime.com/b/default-profile-picture-avatar-photo-placeholder-vector-illustration-default-profile-picture-avatar-photo-placeholder-vector-189495158.jpg';

type props = {
  api: any;
  speciality: any;
};

const useIcon = ({ api, speciality }: props) => {
  const { getSpecialityIcon } = useFetch(api);
  const [img, setImg] = useState<any>(null);
  const { getBaseFromBlob } = useFile();

  useEffect(() => {
    if (speciality?.icon) {
      getPhoto();
    }
  }, [speciality?.icon?.id, getSpecialityIcon]);

  const getPhoto = useCallback(async () => {
    if (!getSpecialityIcon || !speciality?.icon?.id) return;
    try {
      const a = await getSpecialityIcon(speciality?.id);
      a && setImg(await getBaseFromBlob(a));
    } catch (error) {
      console.log(error);
    }
  }, [speciality?.icon?.id, getSpecialityIcon]);

  return {
    img: img,
    fallback: defaultImg,
  };
};

export default useIcon;
