import * as types from "./types";

const result = (type, payload) => {
  return {
    type: type,
    payload: payload,
  };
};

export const setUploadProcess = payload => result(types.SET_UPLOAD_PROCESS, payload);
export const clearUploadProcess = payload => result(types.CLEAR_UPLOAD_PROCESS, payload);
