import createReducer from "store/createReducer";
import * as types from "./types";
import * as loginTypes from "pages/medicalProfile/redux-sagas/types";

const initialState = {};

/**
 * Este reducer es para guardar los processos
 */
const SystemProcessesReducer = createReducer(initialState, {
  [types.SET_UPLOAD_PROCESS](state, action) {
    return {
      ...state,
      [action.payload.id]: {
        ...(state[action.payload.id] || {}),
        porcent: action.payload.porcent,
        done: action.payload.done,
        title: action.payload.title,
      },
    };
  },
  [types.CLEAR_UPLOAD_PROCESS](state, action) {
    delete state[action.payload.id];
    return {
      ...state,
    };
  },
  [loginTypes.SIGN_OUT](state, action) {
    return {};
  },
});

export default SystemProcessesReducer;
