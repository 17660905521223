import { memo, useState } from 'react';
import { Modal, Button, Row, Checkbox, Col } from 'antd';
import { useSelector } from 'react-redux';
import './index.scss';
import style from './index.module.scss';

const TYPE_BOTH = 'TERMS_AND_CONDITIONS_AND_PRIVACY_POLICY';
const TYPE_TERMS = 'TERMS_AND_CONDITIONS';
const TYPE_POLICY = 'PRIVACY_POLICY';

export type TYPE_BOTH = 'TERMS_AND_CONDITIONS_AND_PRIVACY_POLICY';
export type TYPE_TERMS = 'TERMS_AND_CONDITIONS';
export type TYPE_POLICY = 'PRIVACY_POLICY';

const title = {
  TERMS_AND_CONDITIONS: 'Terminos y Condiciones',
  PRIVACY_POLICY: 'Aviso de Privacidad',
  TERMS_AND_CONDITIONS_AND_PRIVACY_POLICY: 'Terminos y Condiciones',
};

const Terms = ({
  show,
  type,
  onClose,
  onAccept,
}: {
  type: TYPE_BOTH | TYPE_TERMS | TYPE_POLICY | undefined;
  [x: string]: any;
}) => {
  const [disableButton, setDisableButton] = useState(true);
  const { termsAndPrivacity } = useSelector(
    (store: any) => store?.systemConfiguration,
  );
  const { loadingTerms } = useSelector((store: any) => store?.medicalProfile);

  const handleContinuar = async () => {
    onAccept && (await onAccept());
    onClose && onClose(false);
  };

  return (
    <>
      <Modal
        style={{
          margin: 'auto',
          top: '0px',
        }}
        title={<h3 style={{ textAlign: 'center' }}>{type && title[type]}</h3>}
        zIndex={1007}
        open={show}
        onCancel={() => {
          setDisableButton(true);
          onClose && onClose(true);
        }}
        className="terms-and-conditions-modal"
        footer={
          type === TYPE_BOTH
            ? [
                <Row key="row-with-actions">
                  <Col span={24} style={{ textAlign: 'left', padding: '15px' }}>
                    <Checkbox
                      checked={!disableButton}
                      className="check-box"
                      onChange={() => setDisableButton(!disableButton)}
                    >
                      <b>Aceptar Terminos y Condiciones</b>
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Button
                      loading={loadingTerms}
                      onClick={handleContinuar}
                      disabled={disableButton}
                      key="submit"
                      type="primary"
                      className={`continue-button ${style.button}`}
                    >
                      ACEPTO
                    </Button>
                  </Col>
                </Row>,
              ]
            : null
        }
      >
        {type === TYPE_TERMS && (
          <div
            className={style.text}
            dangerouslySetInnerHTML={{ __html: termsAndPrivacity.terms }}
          />
        )}
        {type === TYPE_POLICY && (
          <div
            className={style.text}
            dangerouslySetInnerHTML={{ __html: termsAndPrivacity.privacity }}
          />
        )}
        {type === TYPE_BOTH && (
          <>
            <div
              className={style.text}
              dangerouslySetInnerHTML={{ __html: termsAndPrivacity.terms }}
            />
            <br />
            <h4>Aviso de Privacidad</h4>
            <br />
            <div
              className={style.text}
              dangerouslySetInnerHTML={{ __html: termsAndPrivacity.privacity }}
            />
          </>
        )}
      </Modal>
    </>
  );
};

export default memo(Terms);
