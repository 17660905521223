import { memo } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import style from './index.module.scss';
import { Popover } from 'antd';
import { PenIcon, PhoneIcon } from 'icons';
import useChooseCallOrWhatsapp from 'hooks/useChooseCallOrWhatsapp';
import { PersonExtraInfomation } from 'components';
import { useNavigate } from 'react-router-dom';
import useTour from 'hooks/useTour';
import { usePerson } from 'hooks/usePerson';

const Content = memo(() => {
  const navigate = useNavigate();
  const { person } = usePerson();
  const { openToChoose } = useChooseCallOrWhatsapp({
    personName: `${person?.name} ${person?.lastName}`,
    phoneNumber: `(${person.phoneCode}) ${person?.phone}`,
  });

  const onEditPerson = () => {
    navigate(`/person/${person.doc.id}/edit`, {
      state: { personInformation: person },
    });
  };

  const canCall = !person?.phoneCode && !person?.phone;

  return (
    <div className={style.buttons}>
      <div
        className={(canCall && style.disabled) || ''}
        onClick={(!canCall && openToChoose) || undefined}
        title="Contactar"
      >
        <PhoneIcon />
      </div>
      <PersonExtraInfomation />
      <div onClick={onEditPerson} title="Editar">
        <PenIcon />
      </div>
    </div>
  );
});

const PersonSummaryInformationActions = () => {
  const { ref } = useTour();
  return (
    <div ref={(r) => ref('person_action', r)} className={style.actions}>
      <Popover
        overlayStyle={{ zIndex: 999 }}
        placement="topLeft"
        content={<Content />}
        trigger={'click'}
      >
        <MoreOutlined style={{ fontSize: '25px' }} />
      </Popover>
    </div>
  );
};

export default memo(PersonSummaryInformationActions);
