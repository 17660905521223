import api from '../api';

const baseUrl =
  process.env.REACT_APP_API_URL ||
  process.env.REACT_APP_MEDICAL_STUDY_SERVICE_URL;
// const baseContext = `${baseUrl}/api/v1/medical/study`;
const baseContext = `${baseUrl}${
  process.env.REACT_APP_API_URL ? '/api/v1/medical/study' : ''
}`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  //Get all medical studies by person id
  getAllByPerson: ({ id, ...rest }) => {
    return api.get(`${baseContext}/person/${id}`, { params: rest });
  },

  // Get medical study by id and person id
  getById: (id, peopleId, params) => {
    return api.get(`${baseContext}/${id}/person/${peopleId}`, {
      params: { ...params },
    });
  },

  // Create medical study
  createMedicalStudy: (medicalStudy) => {
    return api.post(`${baseContext}`, medicalStudy);
  },

  // Download a file
  downloadFile: (studyId, fileId) => {
    return api.get(`${baseContext}/${studyId}/file/${fileId}`, {
      responseType: 'blob',
    });
  },

  // Ask to see a medical study
  askToSee: (data) => {
    const { studyId, ...rest } = data;
    return api.post(`${baseContext}/${studyId}/notification`, rest);
  },

  // Approval to see a study
  approval: (notificationId) => {
    return api.post(`${baseContext}/notification/approval`, { notificationId });
  },

  // Reject to see a study
  reject: (notificationId) => {
    return api.post(`${baseContext}/notification/reject`, { notificationId });
  },

  // Delete a medical study
  deleteStudy: (studyId, body) => {
    return api.delete(`${baseContext}/${studyId}`, { data: body });
  },

  // Update a medical study
  updateStudy: (studyId, medicalStudy) => {
    return api.put(`${baseContext}/${studyId}`, medicalStudy);
  },

  // Get last studies from professional
  getLastStudies: (params) => {
    return api.get(`${baseContext}/last`, { params });
  },

  // Get files from studies
  getFiles: (personId) => {
    return api.get(`${baseContext}/person/${personId}/files`);
  },
};
