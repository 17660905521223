import {
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
} from 'antd';
import useDate from 'hooks/useDate';
import { memo, useCallback, useEffect, useState } from 'react';
import { required, email, validateNumber } from 'utilities';
import style from './index.module.scss';
import { PeopleApi } from 'api';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CACHE_LAST_RECORD_KEY,
  CACHE_PERSON_IMG_KEY,
  GENDE_TYPE,
} from 'utilities/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getAllOSS } from 'systemConfiguration/redux-sagas/actions';
import { getNameAndLastName, setGetWitchCache } from 'utilities/tools';
import AddressPerson from 'components/address';
import { CakeIcon, IdPersonIcon } from 'icons';
import dayjs from 'dayjs';
import useNav from 'hooks/useNav';
import ProfileLayout from 'components/profileLayout';
import NameField from 'components/nameField';
import CommonField from 'components/commonField';
import PhoneField from 'components/phoneField';
import { usePerson } from 'hooks/usePerson';
import { useGenderCuil } from 'hooks/useGenderCuil';
import { GenderCuildProvider } from 'provider/genderAndCuilProbider';

const REGEX = /OBRA SOCIAL DEL|OBRA SOCIAL DE|OBRA SOCIAL/g;

const _confirmModal = (title: string, body: any, onOk: any, okText: string) => {
  Modal.confirm({
    maskClosable: true,
    title,
    style: { top: '0', margin: 'auto' },
    content: body,
    okText,
    cancelButtonProps: { style: { display: 'none' } },
    onOk,
  });
};

const OssField = memo(() => {
  const dispatch = useDispatch();
  const { oss } = useSelector(
    ({ systemConfiguration = { oss: [] } }: any) => systemConfiguration,
  );

  useEffect(() => {
    !oss?.length && dispatch(getAllOSS());
    // eslint-disable-next-line
  }, [oss?.length]);

  return (
    <Col span={10}>
      <Form.Item style={{ margin: 0 }} name="socialTypeId">
        <Select
          showSearch
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          allowClear
          placeholder="Obra social"
        >
          {oss?.map(({ rnos, nombre }: any) => (
            <Select.Option key={rnos} value={rnos}>
              {`${rnos} - ${nombre?.replace(REGEX, '')}`}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
  );
});

const CuilField = memo(() => {
  const { checkCuil, cuilAutogenerated } = useGenderCuil();
  const { editing } = usePerson();

  return (
    <Col span={5}>
      <Form.Item normalize={validateNumber} name="cuil" rules={[required]}>
        <Input
          onChange={checkCuil}
          type="text"
          disabled={editing}
          placeholder="Cuil"
          prefix={<IdPersonIcon />}
          maxLength={11}
        />
      </Form.Item>
      {cuilAutogenerated && (
        <small className={style.autogenerated_cuil}>
          Cuil autogenerado, valida que es correcto
        </small>
      )}
    </Col>
  );
});

const GenderField = memo(() => {
  const { editing } = usePerson();
  const { changeGender } = useGenderCuil();

  return (
    <Col span={4}>
      <Form.Item name="genderId" rules={[required]}>
        <Select
          disabled={editing}
          placeholder="Sexo"
          onChange={(v) => changeGender(v)}
        >
          {GENDE_TYPE.map((type) => {
            const Icon: any = type.icon;
            return (
              <Select.Option key={type.id} value={type.id}>
                <Icon /> {type.name}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </Col>
  );
});

const SocialIdField = memo(() => {
  const [selected, setSelected] = useState(false);
  return (
    <Col span={6}>
      <Form.Item
        style={{ margin: 0 }}
        name="socialId"
        label={<div className={style.labels}>N° de afiliado</div>}
      >
        <Input
          onClick={() => setSelected(true)}
          onBlur={() => setSelected(false)}
          bordered={selected}
          placeholder="xxxxxxxx"
          type="text"
        />
      </Form.Item>
    </Col>
  );
});

const FormContent = memo(() => {
  const { person, editing, form, constants, onCreate, onUpdate } = usePerson();
  const navigate = useNavigate();

  const personInitialValue =
    (person?.id && {
      id: person.id,
      'name-lastName': `${person.name} ${person.lastName}`,
      birthdate: person?.birthdate && dayjs(person?.birthdate),
      streetNumber: person.streetNumber,
      email: person.email,
      phone: person.phone,
      phoneCode: person.phoneCode,
      street: person.street,
      zip: person.zip,
      observation: person.observation,
      stateId: person.state?.id,
      departmentId: person.department?.id,
      localityId: person.locality?.id,
      latitude: person.latitude,
      longitude: person.longitude,
      genderId: person.genderId,
      socialId: person.socialId,
      socialTypeId: person.socialType?.rnos,
      cuil: person.cuil,
    }) ||
    {};

  const { getFormat, disabledFutureDate } = useDate();

  const _onEdit = useCallback(
    async (v: any) => {
      constants.current.profilePhoto && setGetWitchCache(CACHE_PERSON_IMG_KEY);
      const personInformation = await onUpdate(person.doc.id, {
        ...v,
        profilePhoto: constants.current.profilePhoto,
      });
      setGetWitchCache(CACHE_LAST_RECORD_KEY);
      navigate(`/person/${person.doc.id}`, {
        state: { personInformation },
      });
    },
    [constants, navigate, onUpdate, person?.doc?.id],
  );

  const _onCreate = useCallback(
    async (v: any) => {
      const newPerson = await onCreate({
        ...v,
        profilePhoto: constants.current.profilePhoto,
      });
      navigate(`/person/${newPerson.doc.id}`, {
        state: { personInformation: newPerson },
      });
    },
    [constants, navigate, onCreate],
  );

  const finish = useCallback(
    async (v: any) => {
      v.birthdate = v.birthdate.toDate();
      const value = getNameAndLastName(v['name-lastName'].split(' '));
      v.name = value[0];
      v.lastName = value[1];
      delete v['name-lastName'];
      if (!editing) {
        _confirmModal(
          '¿Está seguro de crear una nueva persona?',
          <div>
            <p>
              La persona será creada y estará visible para todos los
              profesionales
            </p>
            <p>
              Corrobore que el número de documento <b>{v.id}</b> y cuil{' '}
              <b>{v.cuil}</b> sean correctos
            </p>
          </div>,
          () => _onCreate(v),
          'Crear',
        );
      } else {
        _confirmModal(
          `¿Está seguro de editar la información de ${v.name} ${v.lastName}?`,
          <div>
            <p>
              Los datos de la persona serán editados y estos cambios serán
              vistos por todos los profesionales en el sistema.
            </p>
          </div>,
          () => _onEdit(v),
          'Editar',
        );
      }
    },
    [_onCreate, _onEdit, editing],
  );

  return (
    <Form
      initialValues={personInitialValue}
      form={form}
      className={style.content}
      onFinish={finish}
    >
      <NameField />
      <Divider style={{ marginTop: 10 }} type="horizontal" />
      <h3 className={style.sub_title}>
        Información Básica <p>*</p>
      </h3>
      <GenderCuildProvider form={form}>
        <Row gutter={15}>
          <GenderField />
          <OssField />
          <SocialIdField />
          <Col span={4}>
            <small>
              Puedes validar la obra social haciendo click{' '}
              <a
                href="https://www.sssalud.gob.ar/index.php?page=bus650&user=GRAL&cat=consultas"
                target="_blank"
                rel="noreferrer"
              >
                aquí
              </a>
            </small>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={4}>
            <Form.Item name="id" rules={[required]}>
              <Input
                disabled={editing}
                type="text"
                placeholder="Documento"
                prefix={<IdPersonIcon />}
                maxLength={8}
              />
            </Form.Item>
          </Col>
          <CuilField />
          <Col span={4}>
            <Form.Item name="birthdate" rules={[required]}>
              <DatePicker
                suffixIcon={<CakeIcon />}
                placeholder="Nacimiento"
                disabledDate={disabledFutureDate}
                format={getFormat('DD/MM/YYYY')}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
      </GenderCuildProvider>
      <Divider className={style.divider} type="horizontal" />
      <h3>Información de Contacto</h3>
      <CommonField
        rules={[email]}
        label="Correo electrónico"
        name="email"
        type="email"
        placeholder="Correo electrónico"
        maxLength={254}
      />
      <PhoneField />
      <Divider className={style.divider} type="horizontal" />
      <h3>Información Domiciliaria</h3>
      <AddressPerson form={form} isEditing={editing} />
    </Form>
  );
});

const UpsertPerson = () => {
  const { backNavigate } = useNav();
  const { person, editing, fetching, constants, form, creating, updating } =
    usePerson();
  const { state }: any = useLocation();

  useEffect(() => {
    const id = state?.tempData?.cuil_id;
    if (id?.length > 8) id && form.setFieldsValue({ cuil: id });
    else id && form.setFieldsValue({ id });
  }, [form, state?.tempData?.cuil_id]);

  const innerBack = () => {
    if (!editing) {
      backNavigate(`/`);
    } else {
      backNavigate(`/person/${person.doc.id}`);
    }
  };

  return (
    <ProfileLayout
      loading={fetching}
      editing={editing}
      constants={constants}
      api={PeopleApi}
      okLoading={creating || updating}
      person={person}
      form={form}
      onCancel={innerBack}
    >
      <FormContent />
    </ProfileLayout>
  );
};

export default memo(UpsertPerson);
