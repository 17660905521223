import { memo } from 'react';
import style from './index.module.scss';
import { CurrentUser, SearchHeader } from 'components';
import { useLocation, useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isInit = pathname.includes('/init');

  return (
    <div className={style.header}>
      <div
        className={style.medical_trace_logo}
        onClick={() => navigate(isInit ? '/init' : '/')}
      >
        Medical Trace
      </div>
      {!isInit && (
        <div className={style.search_header}>
          <SearchHeader />
        </div>
      )}
      <div className={style.currently_user}>
        <CurrentUser />
      </div>
    </div>
  );
};

export default memo(Header);
