import { Col, Row } from 'antd';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import style from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import useCommon from 'hooks/useCommon';
import SpecialityTag from 'components/specialityTag';
import PersonChip from 'components/personChip';

type props = {
  study: any;
};

const PersonStudyItem = ({ study }: props) => {
  const { professionalsEmails } = useSelector(
    (state: any) => state.systemConfiguration,
  );

  const navigate = useNavigate();
  const { person, ...auxStudy } = study;

  const { privacity, title, registeredAt, diagnostics, files } = useCommon({
    professionalsEmails,
    study,
  });

  const openStudy = (e: any) => {
    e.stopPropagation();
    const url: string = `/person/${person.doc.id}/record/${auxStudy.doc.id}`;
    navigate(url, {
      state: {
        recordInformation: { ...auxStudy, person: `people/${person.doc.id}` },
        personInformation: person,
      },
    });
  };

  return (
    <div onClick={openStudy} className={style.person_study_item}>
      <Row className={style.person_study_row} gutter={15}>
        <Col span={15} className={style.person_study_information}>
          <Row>
            <Col span={24} className={style.person_study_specialty}>
              <SpecialityTag
                onRecord
                speciality={study.speciality}
                withoutBorder
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className={style.record_title}>
                {title(study.title)}
                {privacity(study)}
                {diagnostics(study)}
                {files(study)}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24} className={style.person_study_date}>
              {registeredAt(study)}
            </Col>
          </Row>
        </Col>
        <Col span={9} className={style.person_study_person}>
          <PersonChip person={person} />
        </Col>
      </Row>
    </div>
  );
};

export default memo(PersonStudyItem);
