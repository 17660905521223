/* eslint-disable react-hooks/exhaustive-deps */
import { MedicalStudyApi } from 'api';
import { List, RecordItem } from 'components';
import useFetch from 'hooks/useFetch';
import useTour from 'hooks/useTour';
import { memo, useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { CACHE_RECORD_KEY, getParamsToObject, getWithCache } from 'utilities';
import style from './index.module.scss';

const SIZE_PAGE = 10;

const RecordsList = () => {
  const { ref } = useTour();
  const [innerList, setInnerList] = useState<any[]>([]);
  const final = useRef(false);
  const refresh = useRef(false);
  const { personId } = useParams();
  const [params] = useSearchParams();
  const filters = getParamsToObject(params);

  const {
    loading: { getAllByPerson: loading },
    getAllByPerson,
  } = useFetch(MedicalStudyApi);

  const getMedicalStudies = async (data?: any) => {
    if (!final.current && !loading) {
      const { items } = await getAllByPerson({
        id: personId,
        size: SIZE_PAGE,
        ...(data || {}),
        ...getWithCache(CACHE_RECORD_KEY),
        ...filters,
      });

      if (items?.length < SIZE_PAGE) final.current = true;

      if (!refresh.current) {
        setInnerList((v) => [
          ...v,
          ...items.filter((d: any) => !v.find((f) => f.doc.id === d.doc.id)),
        ]);
      } else {
        setInnerList(items);
      }

      refresh.current = false;
    }
  };

  useEffect(() => {
    refresh.current = true;
    final.current = false;
    personId && getAllByPerson && getMedicalStudies();
  }, [personId, getAllByPerson, JSON.stringify(filters)]);

  const findMore = useCallback(
    (v: 'visible' | 'hidden') => {
      v === 'visible' &&
        getMedicalStudies({ last: innerList[innerList.length - 1]?.doc.path });
    },
    [getMedicalStudies, innerList],
  );

  return (
    <div
      ref={(r) => ref('person_records_list', r)}
      className={style.records_list}
    >
      <List
        key="study-list"
        loading={loading}
        findAgain={2}
        onSearch={findMore}
        data={innerList}
        onRender={(v: any) => ({ data: v })}
        Item={RecordItem}
      />
    </div>
  );
};

export default memo(RecordsList);
