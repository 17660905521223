import api from '../api';

const baseUrl =
  process.env.REACT_APP_API_URL || process.env.REACT_APP_DIAGNOSIS_SERVICE_URL;
// const baseContext = `${baseUrl}/api/v1/diagnostics`;
const baseContext = `${baseUrl}${process.env.REACT_APP_API_URL ? "/api/v1/diagnostics":""}`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  //Get all diagnostics
  getDiagnostics: function (personId, params) {
    return api.get(`${baseContext}/person/${personId}`, { params });
  },
};
