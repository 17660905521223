import { Form, FormInstance } from 'antd';
import { MedicalApi } from 'api';
import dayjs from 'dayjs';
import { useAuth } from 'hooks/useAuth';
import { getMe } from 'pages/medicalProfile/redux-sagas/actions';

import {
  createContext,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNameAndLastName } from 'utilities';
import useFetch from '../hooks/useFetch';

export type professionalContextType = {
  form: FormInstance<any>;
  professional: any;
  constants: MutableRefObject<{
    [x: string]: any;
  }>;
  loading: boolean;
  initialValues: {
    'name-lastName': string;
    name: string;
    lastName: string;
    birthdate: string;
    phoneCode: string;
    phone: string;
    email: string;
    id: string;
    cuil: string;
  };
  onUpdate: (value: any) => Promise<void>;
  updating: boolean;
};

export const ProfessionalContext = createContext<professionalContextType>(
  {} as professionalContextType,
);

const ProfessionalProvider = ({ children }: any) => {
  const { getToken } = useAuth();
  const constants = useRef<{ [x: string]: any }>({});
  const { medicalProfile: mp, loading } = useSelector(
    (sate: any) => sate.medicalProfile,
  );
  const isProfessionalSetted = !!mp?.doc?.id;
  const dispatch = useDispatch();
  const {
    loading: { updateProfessional: updating },
    updateProfessional,
  } = useFetch(MedicalApi, {
    showNotification: {
      success: {
        message: { updateProfessional: 'Información actualizada!' },
        show: true,
      },
    },
  });
  const [form] = Form.useForm();

  useEffect(() => {
    !isProfessionalSetted &&
      (async () => {
        dispatch(getMe({ token: await getToken() }));
      })();
    // eslint-disable-next-line
  }, [isProfessionalSetted]);

  const onUpdate = useCallback(
    async (values: any) => {
      const value = getNameAndLastName(values['name-lastName'].split(' '));

      const dataToSend = {
        name: value[0],
        lastName: value[1],
        birthdate: values?.birthdate?.toDate(),
        phoneCode: values?.phoneCode,
        phone: values?.phone,
        id: values?.id,
        cuil: values?.cuil,
        genderId: values?.genderId,
        contactEmail: values?.contactEmail,
        profilePhoto: constants.current.profilePhoto,
        ...(constants.current.action &&
          values.stateId && {
            addresses: [
              {
                ...(['delete', 'update'].includes(constants.current.action) && {
                  id: constants.current['addressId'],
                }),
                action: constants.current.action,
                stateId: values.stateId,
                departmentId: values.departmentId,
                localityId: values.localityId,
                street: values.street,
                streetNumber: values.streetNumber,
                observation: values.observation,
                email: values.addressEmail,
                phone: values.addressPhone,
                phoneCode: values.addressPhoneCode,
                zip: values.zip,
                latitude: values.latitude,
                longitude: values.longitude,
              },
            ],
          }),
      };

      if (await updateProfessional(dataToSend)) {
        dispatch(getMe({ noCache: true }));
      }
    },
    [dispatch, updateProfessional],
  );

  const value = useMemo(
    () => ({
      form,
      professional: mp,
      constants,
      loading: !isProfessionalSetted && loading,
      initialValues: {
        'name-lastName': `${mp?.name} ${mp?.lastName}`,
        name: mp?.name,
        genderId: mp?.genderId,
        lastName: mp?.lastName,
        birthdate: mp?.birthdate && dayjs(mp?.birthdate),
        phoneCode: mp?.phoneCode,
        phone: mp?.phone,
        email: mp?.email,
        id: mp?.id,
        cuil: mp?.cuil,
        contactEmail: mp?.contactEmail || mp?.email,
      },
      onUpdate,
      updating,
    }),
    [form, isProfessionalSetted, loading, mp, onUpdate, updating],
  );

  return (
    <ProfessionalContext.Provider value={value}>
      {children}
    </ProfessionalContext.Provider>
  );
};

export { ProfessionalProvider };
