/* eslint-disable */
import Icon from '@ant-design/icons';

export const AttachIcon = (props: any) => {
  const icon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.95"
      height="21"
      viewBox="0 0 11.95 21"
    >
      <path
        id="attach_file_FILL0_wght500_GRAD0_opsz24"
        d="M11.5,22.225A5.678,5.678,0,0,1,5.775,16.5V5.9A4.021,4.021,0,0,1,7,2.95,4.021,4.021,0,0,1,9.95,1.725,4.064,4.064,0,0,1,12.913,2.95,4,4,0,0,1,14.15,5.9v9.55A2.646,2.646,0,0,1,11.5,18.1a2.646,2.646,0,0,1-2.65-2.65V6.75a.809.809,0,0,1,.25-.613A.841.841,0,0,1,9.7,5.9a.814.814,0,0,1,.85.85v8.7a.95.95,0,1,0,1.9,0V5.9a2.476,2.476,0,0,0-2.5-2.5,2.476,2.476,0,0,0-2.5,2.5V16.5a4.054,4.054,0,0,0,4.05,4.05,4.053,4.053,0,0,0,4.05-4.05V6.75a.814.814,0,0,1,.85-.85.8.8,0,0,1,.588.237.832.832,0,0,1,.237.613V16.5A5.678,5.678,0,0,1,11.5,22.225Z"
        transform="translate(-5.525 -1.475)"
        fill="#acb3be"
        stroke="#acb3be"
        strokeWidth="0.5"
      />
    </svg>
  );
  return <Icon component={icon} {...props} />;
};

export const HeartIcon = (props: any) => {
  const icon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.9"
      height="18.575"
      viewBox="0 0 20.9 18.575"
    >
      <path
        id="favorite_FILL0_wght500_GRAD0_opsz24"
        d="M10.475,19.9l-1.75-1.575A66.639,66.639,0,0,1,3.938,13.5,7.85,7.85,0,0,1,1.8,8.15a5.555,5.555,0,0,1,5.7-5.7,6,6,0,0,1,2.475.525A5.79,5.79,0,0,1,12,4.475a5.82,5.82,0,0,1,2.013-1.5A5.97,5.97,0,0,1,16.5,2.45a5.575,5.575,0,0,1,5.7,5.7,7.893,7.893,0,0,1-2.138,5.362A62.414,62.414,0,0,1,15.25,18.35L13.525,19.9a2.173,2.173,0,0,1-3.05,0ZM11,6.775A5.444,5.444,0,0,0,9.488,5.262,3.6,3.6,0,0,0,7.5,4.725,3.333,3.333,0,0,0,4.075,8.15a5.2,5.2,0,0,0,.913,2.75,18.665,18.665,0,0,0,2.187,2.812Q8.45,15.075,9.8,16.262L12,18.2q.85-.775,2.2-1.962t2.625-2.55a19.089,19.089,0,0,0,2.188-2.8,5.14,5.14,0,0,0,.912-2.737A3.333,3.333,0,0,0,16.5,4.725a3.6,3.6,0,0,0-1.987.537A5.438,5.438,0,0,0,13,6.775a1.355,1.355,0,0,1-.462.413A1.152,1.152,0,0,1,12,7.325a1.2,1.2,0,0,1-.562-.137A1.152,1.152,0,0,1,11,6.775ZM12,11.475Z"
        transform="translate(-1.55 -2.2)"
        fill="#acb3be"
        stroke="#acb3be"
        strokeWidth="0.5"
      />
    </svg>
  );
  return <Icon component={icon} {...props} />;
};

export const WordSvgIcon = (props: any) => {
  const icon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width="38px"
      height="38px"
    >
      <path
        fill="#2d92d4"
        d="M42.256,6H15.744C14.781,6,14,6.781,14,7.744v7.259h30V7.744C44,6.781,43.219,6,42.256,6z"
      />
      <path
        fill="#2150a9"
        d="M14,33.054v7.202C14,41.219,14.781,42,15.743,42h26.513C43.219,42,44,41.219,44,40.256v-7.202H14z"
      />
      <path fill="#2d83d4" d="M14 15.003H44V24.005000000000003H14z" />
      <path fill="#2e70c9" d="M14 24.005H44V33.055H14z" />
      <path
        fill="#00488d"
        d="M22.319,34H5.681C4.753,34,4,33.247,4,32.319V15.681C4,14.753,4.753,14,5.681,14h16.638 C23.247,14,24,14.753,24,15.681v16.638C24,33.247,23.247,34,22.319,34z"
      />
      <path
        fill="#fff"
        d="M18.403 19L16.857 26.264 15.144 19 12.957 19 11.19 26.489 9.597 19 7.641 19 9.985 29 12.337 29 14.05 21.311 15.764 29 18.015 29 20.359 19z"
      />
    </svg>
  );
  return <Icon component={icon} {...props} />;
};

export const HomeIcon = (props: any) => {
  const icon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.02"
      height="24"
      viewBox="0 0 22.02 24"
    >
      <path
        id="home_FILL0_wght500_GRAD0_opsz24"
        d="M6.855,24.27H10.58V16.114h8.459V24.27h3.726V12.321L14.81,6.38,6.855,12.321Zm0,3.055A3.037,3.037,0,0,1,3.8,24.27V12.321a3.067,3.067,0,0,1,.32-1.36A2.731,2.731,0,0,1,5.008,9.9l7.955-5.975a3.533,3.533,0,0,1,.89-.454,3.118,3.118,0,0,1,1.913,0,3.541,3.541,0,0,1,.889.454L24.611,9.9a2.742,2.742,0,0,1,.89,1.057,3.078,3.078,0,0,1,.318,1.36V24.27a3.035,3.035,0,0,1-3.055,3.055h-6.68V19.068H13.534v8.257ZM14.81,15.308Z"
        transform="translate(-3.8 -3.325)"
        fill="#fff"
      />
    </svg>
  );
  return <Icon component={icon} {...props} />;
};
export const EnrollmentIcon = (props: any) => {
  const icon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.2"
      height="15.661"
      viewBox="0 0 20.2 15.661"
    >
      <path
        id="badge_FILL0_wght700_GRAD0_opsz24"
        d="M15.766,12.634a.681.681,0,1,0,0-1.362H13.5a.681.681,0,1,0,0,1.362Zm0,2.724a.681.681,0,1,0,0-1.362H13.5a.681.681,0,1,0,0,1.362ZM17.99,5.689a2.827,2.827,0,0,1,2.86,2.86V17.99a2.827,2.827,0,0,1-2.86,2.86H4.01a2.827,2.827,0,0,1-2.86-2.86V8.549a2.827,2.827,0,0,1,2.86-2.86H17.99ZM11,13.27ZM8.277,14a1.358,1.358,0,1,0-.964-.4A1.311,1.311,0,0,0,8.277,14ZM5.553,16.719H11v-.409a1.279,1.279,0,0,0-.216-.715,1.423,1.423,0,0,0-.6-.51,4.664,4.664,0,0,0-.919-.306,4.8,4.8,0,0,0-1.975,0,4.659,4.659,0,0,0-.92.306,1.423,1.423,0,0,0-.6.51,1.279,1.279,0,0,0-.216.715ZM4.01,8.549V17.99H17.99V8.549Z"
        transform="translate(-0.9 -5.439)"
        fill="#179b9a"
        stroke="#fff"
        strokeWidth="0.5"
      />
    </svg>
  );
  return <Icon component={icon} {...props} />;
};
export const SearchIcon = (props: any) => {
  const icon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        id="search_FILL0_wght500_GRAD0_opsz24"
        d="M24.244,26.414,16.8,18.973a8.478,8.478,0,0,1-2.313,1.24,8.172,8.172,0,0,1-2.782.469,8.544,8.544,0,0,1-6.3-2.6,8.627,8.627,0,0,1-2.581-6.318A8.631,8.631,0,0,1,5.406,5.447a8.546,8.546,0,0,1,6.3-2.6,8.6,8.6,0,0,1,6.319,2.6,8.6,8.6,0,0,1,2.6,6.319,8.457,8.457,0,0,1-.452,2.782,7.681,7.681,0,0,1-1.224,2.279l7.475,7.508a1.386,1.386,0,0,1,.4,1.023,1.437,1.437,0,0,1-.436,1.055,1.477,1.477,0,0,1-1.089.436,1.37,1.37,0,0,1-1.057-.436ZM11.708,17.6a5.685,5.685,0,0,0,4.156-1.693,5.6,5.6,0,0,0,1.709-4.139,5.6,5.6,0,0,0-1.709-4.14,5.688,5.688,0,0,0-4.156-1.692,5.78,5.78,0,0,0-5.832,5.832A5.782,5.782,0,0,0,11.708,17.6Z"
        transform="translate(-2.825 -2.85)"
        fill="#fff"
      />
    </svg>
  );
  return <Icon component={icon} {...props} />;
};

export const IcdIcon = (props: any) => {
  const icon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        id="collections_bookmark_FILL0_wght500_GRAD0_opsz24"
        d="M9.249,22.326H26.6V4.974H23.788v8.767a.8.8,0,0,1-.419.75.749.749,0,0,1-.823-.019l-2.411-1.461-2.374,1.461a.809.809,0,0,1-.858.019.8.8,0,0,1-.421-.75V4.974H9.249Zm0,3.324a3.2,3.2,0,0,1-2.338-.986,3.2,3.2,0,0,1-.986-2.338V4.974a3.2,3.2,0,0,1,.986-2.338A3.2,3.2,0,0,1,9.249,1.65H26.6a3.2,3.2,0,0,1,2.338.986,3.2,3.2,0,0,1,.986,2.338V22.326A3.368,3.368,0,0,1,26.6,25.65ZM16.482,4.974h0Zm-7.233,0h0Z"
        transform="translate(-5.925 -1.65)"
        fill="#fff"
      />
    </svg>
  );
  return <Icon component={icon} {...props} />;
};
export const LogOutIcon = (props: any) => {
  const icon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '29.417'}
      height={props.height || '30'}
      viewBox="0 0 29.417 30"
    >
      <path
        id="logout_FILL0_wght400_GRAD0_opsz40"
        d="M26.417,26.625a1.521,1.521,0,0,1,0-2l3.25-3.25H16.792a1.375,1.375,0,1,1,0-2.75H29.625l-3.292-3.292a1.3,1.3,0,0,1-.375-.958,1.364,1.364,0,0,1,.417-1,1.231,1.231,0,0,1,.958-.417,1.362,1.362,0,0,1,1,.417l5.709,5.667a1.64,1.64,0,0,1,.291.479,1.506,1.506,0,0,1,.084.521,1.562,1.562,0,0,1-.084.5,1.165,1.165,0,0,1-.291.458l-5.667,5.667a1.27,1.27,0,0,1-.979.4A1.413,1.413,0,0,1,26.417,26.625ZM7.792,35a2.683,2.683,0,0,1-1.959-.833A2.683,2.683,0,0,1,5,32.208V7.792a2.683,2.683,0,0,1,.833-1.959A2.683,2.683,0,0,1,7.792,5H18.375A1.353,1.353,0,0,1,19.75,6.375a1.382,1.382,0,0,1-.4,1.021,1.33,1.33,0,0,1-.979.4H7.792V32.208H18.375a1.3,1.3,0,0,1,.979.417,1.4,1.4,0,0,1,.4,1A1.353,1.353,0,0,1,18.375,35Z"
        transform="translate(-5 -5)"
        fill="#ef667c"
      />
    </svg>
  );
  return <Icon component={icon} {...props} />;
};

export const OpenNewIcon = (props: any) => {
  const icon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path
        d="M5.8 19.7q-.65 0-1.075-.425Q4.3 18.85 4.3 18.2V5.8q0-.65.425-1.075Q5.15 4.3 5.8 4.3H11V5H5.8q-.3 0-.55.25Q5 5.5 5 5.8v12.4q0 .3.25.55.25.25.55.25h12.4q.3 0 .55-.25.25-.25.25-.55V13h.7v5.2q0 .65-.425 1.075-.425.425-1.075.425Zm3.95-4.95-.5-.5L18.5 5H14v-.7h5.7V10H19V5.5Z"
        fill="white"
      />
    </svg>
  );
  return <Icon component={icon} {...props} />;
};

export const LockEyeIcon = (props: any) => {
  const icon = () => (
    <svg
      style={{ fill: '#acb3be' }}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
    >
      <path d="m16.05 13.05-1.975-1.95q.15-.775-.4-1.363-.55-.587-1.375-.437l-1.85-1.85q.375-.15.775-.225.4-.075.775-.075 1.825 0 3.088 1.262Q16.35 9.675 16.35 11.5q0 .35-.075.787-.075.438-.225.763Zm3.45 3.475L17.875 14.9q.825-.675 1.525-1.5t1.25-1.9q-1.25-2.475-3.525-3.95T12 6.075q-.7 0-1.362.1-.663.1-1.213.25L7.575 4.6q1-.425 2.15-.638Q10.875 3.75 12 3.75q3.75 0 6.75 2.112 3 2.113 4.425 5.638-.575 1.45-1.525 2.75t-2.15 2.275Zm0 5.925-3.825-3.8q-.8.275-1.725.437-.925.163-1.95.163-3.775 0-6.775-2.125-3-2.125-4.4-5.625.525-1.275 1.338-2.438Q2.975 7.9 3.95 6.95L1.3 4.225l1.45-1.45L20.975 21ZM5.6 8.525q-.7.65-1.25 1.375t-.975 1.6q1.225 2.475 3.513 3.95Q9.175 16.925 12 16.925q.4 0 .863-.037.462-.038.937-.138l-.95-1q-.2.05-.425.075-.225.025-.425.025-1.825 0-3.087-1.263Q7.65 13.325 7.65 11.5q0-.2.013-.413.012-.212.062-.412Zm8.05 2.125ZM9.7 12.625Z" />
    </svg>
  );
  return <Icon component={icon} {...props} />;
};

export const AlertIcon = (props: any) => {
  const icon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '26.666'}
      height={props.height || '33.334'}
      viewBox="0 0 26.666 33.334"
    >
      <path
        id="notifications_FILL0_wght400_GRAD0_opsz40"
        d="M8.042,31.667a1.351,1.351,0,0,1-1.375-1.375,1.384,1.384,0,0,1,.395-1.021,1.331,1.331,0,0,1,.98-.4h2.083V16.5a10.024,10.024,0,0,1,2.063-6.167,9.037,9.037,0,0,1,5.437-3.5V5.625A2.179,2.179,0,0,1,18.312,4a2.469,2.469,0,0,1,3.376,0,2.179,2.179,0,0,1,.687,1.625V6.833a9.126,9.126,0,0,1,5.458,3.5A9.958,9.958,0,0,1,29.917,16.5V28.875h2.041a1.3,1.3,0,0,1,.98.417,1.4,1.4,0,0,1,.4,1,1.351,1.351,0,0,1-1.375,1.375ZM20,19.292Zm0,17.375a3.231,3.231,0,0,1-2.333-.979,3.179,3.179,0,0,1-1-2.355h6.666A3.343,3.343,0,0,1,20,36.667Zm-7.125-7.792h14.25V16.5a6.987,6.987,0,0,0-2.042-5.083,6.776,6.776,0,0,0-5.041-2.084A7.114,7.114,0,0,0,12.875,16.5Z"
        transform="translate(-6.667 -3.333)"
        fill="#03366b"
      />
    </svg>
  );
  return <Icon component={icon} {...props} />;
};

export const PersonIcon = (props: any) => {
  const icon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '26.666'}
      height={props.height || '26.125'}
      viewBox="0 0 26.666 26.125"
    >
      <path
        id="person_FILL0_wght400_GRAD0_opsz40"
        d="M20,19.958a6.2,6.2,0,0,1-6.375-6.375A6.2,6.2,0,0,1,20,7.208a6.2,6.2,0,0,1,6.375,6.375A6.2,6.2,0,0,1,20,19.958ZM30.542,33.333H9.458a2.773,2.773,0,0,1-2.791-2.791V29.167a4.705,4.705,0,0,1,.77-2.688A4.877,4.877,0,0,1,9.458,24.75a29.866,29.866,0,0,1,5.313-1.9A21.642,21.642,0,0,1,20,22.208a21.388,21.388,0,0,1,5.208.646,31.236,31.236,0,0,1,5.334,1.9,5.246,5.246,0,0,1,2.02,1.75,4.6,4.6,0,0,1,.771,2.667v1.375a2.773,2.773,0,0,1-2.791,2.791ZM9.458,30.542H30.542V29.167a2.112,2.112,0,0,0-.334-1.125,2.061,2.061,0,0,0-.833-.792A21.664,21.664,0,0,0,24.6,25.5,20.972,20.972,0,0,0,20,25a21.256,21.256,0,0,0-4.625.5,20.783,20.783,0,0,0-4.75,1.75,1.877,1.877,0,0,0-.854.792,2.216,2.216,0,0,0-.313,1.125ZM20,17.208a3.558,3.558,0,0,0,3.625-3.625A3.56,3.56,0,0,0,20,9.958a3.56,3.56,0,0,0-3.625,3.625A3.558,3.558,0,0,0,20,17.208ZM20,13.583ZM20,30.542Z"
        transform="translate(-6.667 -7.208)"
        fill="#03366b"
      />
    </svg>
  );
  return <Icon component={icon} {...props} />;
};

export const LockIcon = (props: any) => {
  const icon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.95"
      height="21.825"
      viewBox="0 0 16.95 21.825"
      style={{ fill: '#091f3e', opacity: 0.38 }}
    >
      <path
        id="lock_FILL0_wght600_GRAD0_opsz24"
        d="M6.175,22.45A2.535,2.535,0,0,1,4.3,21.663,2.568,2.568,0,0,1,3.525,19.8V10.125a2.646,2.646,0,0,1,2.65-2.65H6.8v-1.6A5.1,5.1,0,0,1,8.312,2.162,4.979,4.979,0,0,1,12,.625a4.979,4.979,0,0,1,3.688,1.537A5.1,5.1,0,0,1,17.2,5.875v1.6h.625a2.646,2.646,0,0,1,2.65,2.65V19.8a2.568,2.568,0,0,1-.775,1.863,2.535,2.535,0,0,1-1.875.787Zm0-2.65h11.65V10.125H6.175V19.8ZM12,16.95a2.006,2.006,0,1,0-1.412-.587A1.928,1.928,0,0,0,12,16.95ZM9.45,7.475h5.1v-1.6a2.549,2.549,0,0,0-.738-1.838,2.535,2.535,0,0,0-3.624,0A2.549,2.549,0,0,0,9.45,5.875ZM6.175,19.8v0Z"
        transform="translate(-3.525 -0.625)"
      />
    </svg>
  );
  return <Icon component={icon} {...props} />;
};

export const MedicalCenterIcon = (props: any) => {
  const icon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.958"
      height="13.979"
      viewBox="0 0 11.958 13.979"
    >
      <path
        id="home_health_FILL0_wght500_GRAD-25_opsz20"
        d="M8.938,14.062h2.124v-2h2V9.938h-2v-2H8.938v2h-2v2.124h2ZM4.021,16.979V7.5L10,3l5.979,4.5v9.479Zm1.625-1.625h8.708V8.292L10,5.042,5.646,8.292ZM10,10.188Z"
        transform="translate(-4.021 -3)"
        fill="#179b9a"
      />
    </svg>
  );
  return <Icon component={icon} {...props} />;
};
export const WorldIcon = (props: any) => {
  const icon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.95"
      height="20.95"
      viewBox="0 0 20.95 20.95"
    >
      <path
        id="public_FILL0_wght600_GRAD0_opsz24"
        d="M12.025,22.475a10.2,10.2,0,0,1-4.087-.825A10.726,10.726,0,0,1,4.6,19.413a10.471,10.471,0,0,1-2.25-3.326A10.2,10.2,0,0,1,1.525,12,10.212,10.212,0,0,1,2.35,7.912,10.474,10.474,0,0,1,4.6,4.587,10.726,10.726,0,0,1,7.938,2.35a10.2,10.2,0,0,1,4.087-.825A10.12,10.12,0,0,1,16.1,2.35a10.469,10.469,0,0,1,5.55,5.562A10.212,10.212,0,0,1,22.475,12a10.2,10.2,0,0,1-.825,4.087,10.432,10.432,0,0,1-5.563,5.563A10.143,10.143,0,0,1,12.025,22.475Zm-1-2.7V17.85a1.963,1.963,0,0,1-1.95-1.95v-.975l-4.7-4.7q-.075.45-.138.875a6.055,6.055,0,0,0-.062.875,7.691,7.691,0,0,0,1.95,5.213A7.455,7.455,0,0,0,11.025,19.775Zm6.75-2.5a7.067,7.067,0,0,0,.888-1.15,7.884,7.884,0,0,0,.649-1.3,7.229,7.229,0,0,0,.388-1.4,8.519,8.519,0,0,0,.125-1.45A7.738,7.738,0,0,0,18.513,7.6,7.387,7.387,0,0,0,14.95,4.75v.375A1.857,1.857,0,0,1,14.363,6.5a1.919,1.919,0,0,1-1.388.575h-1.95V9.05a.982.982,0,0,1-.975.975H8.075v1.95h5.9a.98.98,0,0,1,.975.975v2.925h.975a1.91,1.91,0,0,1,1.85,1.4Z"
        transform="translate(-1.525 -1.525)"
        fill="#acb3be"
      />
    </svg>
  );
  return <Icon component={icon} {...props} />;
};

export const MaleIcon = (props: any) => {
  const icon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.759"
      height="12.722"
      viewBox="0 0 12.759 12.722"
    >
      <path
        id="male_FILL0_wght600_GRAD0_opsz24"
        d="M7.948,16.2a4.241,4.241,0,0,1-3.114-1.283A4.241,4.241,0,0,1,3.55,11.8,4.241,4.241,0,0,1,4.833,8.684,4.241,4.241,0,0,1,7.948,7.4a4.667,4.667,0,0,1,1.218.16,3.488,3.488,0,0,1,1.1.519l2.6-2.6h-.661a1.133,1.133,0,0,1-.661-.321.92.92,0,0,1-.264-.679.968.968,0,0,1,1-1h3.02a.979.979,0,0,1,.707.283.955.955,0,0,1,.293.717V7.5a1,1,0,0,1-1,1,.926.926,0,0,1-.7-.274,1.082,1.082,0,0,1-.3-.67V6.891L11.685,9.477a4.021,4.021,0,0,1,.5,1.114,4.557,4.557,0,0,1,.16,1.208,4.366,4.366,0,0,1-4.4,4.4Zm0-6.795a2.32,2.32,0,0,0-1.689.7,2.391,2.391,0,0,0,0,3.4,2.4,2.4,0,0,0,3.388,0,2.415,2.415,0,0,0,0-3.4,2.312,2.312,0,0,0-1.7-.7Z"
        transform="translate(-3.55 -3.475)"
        fill="#148ac4"
      />
    </svg>
  );
  return <Icon component={icon} {...props} />;
};

export const FameleIcon = (props: any) => {
  const icon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.647"
      height="16.125"
      viewBox="0 0 10.647 16.125"
    >
      <path
        id="female_FILL0_wght600_GRAD200_opsz24"
        d="M9.951,13.945A5.256,5.256,0,0,1,5.975,8.821a5.145,5.145,0,0,1,1.558-3.8A5.148,5.148,0,0,1,11.3,3.475a5.3,5.3,0,0,1,5.323,5.346,5.254,5.254,0,0,1-3.976,5.125v1.48h.574a1.135,1.135,0,0,1,.85.353,1.191,1.191,0,0,1,.343.861,1.227,1.227,0,0,1-.343.872,1.117,1.117,0,0,1-.85.365h-.574v.376a1.347,1.347,0,0,1-2.695,0v-.376H9.4a1.154,1.154,0,0,1-.861-.365,1.208,1.208,0,0,1-.353-.872A1.207,1.207,0,0,1,9.4,15.425h.552ZM11.3,11.427A2.64,2.64,0,0,0,13.927,8.8,2.64,2.64,0,0,0,11.3,6.17,2.64,2.64,0,0,0,8.67,8.8,2.64,2.64,0,0,0,11.3,11.427Z"
        transform="translate(-5.975 -3.475)"
        fill="#9f5cb5"
      />
    </svg>
  );
  return <Icon component={icon} {...props} />;
};

export const IdPersonIcon = (props: any) => {
  const icon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.2"
      height="15.661"
      viewBox="0 0 20.2 15.661"
    >
      <path
        id="badge_FILL0_wght700_GRAD0_opsz24"
        d="M15.766,12.634a.681.681,0,1,0,0-1.362H13.5a.681.681,0,1,0,0,1.362Zm0,2.724a.681.681,0,1,0,0-1.362H13.5a.681.681,0,1,0,0,1.362ZM17.99,5.689a2.827,2.827,0,0,1,2.86,2.86V17.99a2.827,2.827,0,0,1-2.86,2.86H4.01a2.827,2.827,0,0,1-2.86-2.86V8.549a2.827,2.827,0,0,1,2.86-2.86H17.99ZM11,13.27ZM8.277,14a1.358,1.358,0,1,0-.964-.4A1.311,1.311,0,0,0,8.277,14ZM5.553,16.719H11v-.409a1.279,1.279,0,0,0-.216-.715,1.423,1.423,0,0,0-.6-.51,4.664,4.664,0,0,0-.919-.306,4.8,4.8,0,0,0-1.975,0,4.659,4.659,0,0,0-.92.306,1.423,1.423,0,0,0-.6.51,1.279,1.279,0,0,0-.216.715ZM4.01,8.549V17.99H17.99V8.549Z"
        transform="translate(-0.9 -5.439)"
        fill="#03366b"
        stroke="#f7f7f7"
        strokeWidth="0.5"
      />
    </svg>
  );
  return <Icon component={icon} {...props} />;
};

export const SocialWorkIcon = (props: any) => {
  const icon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.763"
      height="16.517"
      viewBox="0 0 13.763 16.517"
    >
      <path
        id="health_and_safety_FILL0_wght700_GRAD200_opsz24"
        d="M9.482,11.509a1.084,1.084,0,0,0,1.087-1.087V9.7h.725a1.087,1.087,0,1,0,0-2.174h-.725V6.8a1.087,1.087,0,1,0-2.174,0v.725H7.67a1.087,1.087,0,1,0,0,2.174h.725v.725a1.084,1.084,0,0,0,1.087,1.087Zm0,5.508a1.9,1.9,0,0,1-.335-.028.954.954,0,0,1-.263-.081,8.271,8.271,0,0,1-4.349-3.27A9.1,9.1,0,0,1,2.85,8.32v-3.3a2.379,2.379,0,0,1,.444-1.4,2.44,2.44,0,0,1,1.168-.906L8.63,1.163a2.305,2.305,0,0,1,1.7,0L14.5,2.721a2.444,2.444,0,0,1,1.169.906,2.381,2.381,0,0,1,.444,1.4v3.3a9.1,9.1,0,0,1-1.685,5.318,8.271,8.271,0,0,1-4.349,3.27Q10.043,16.926,9.482,17.017Zm0-2.464a6.7,6.7,0,0,0,2.971-2.419,6.426,6.426,0,0,0,1.2-3.8V5.022L9.482,3.482,5.314,5.041V8.32a6.454,6.454,0,0,0,1.2,3.814A6.7,6.7,0,0,0,9.482,14.553ZM9.482,9.009Z"
        transform="translate(-2.6 -0.75)"
        fill="#179b9a"
        stroke="#f7f7f7"
        strokeWidth="0.5"
      />
    </svg>
  );
  return <Icon component={icon} {...props} />;
};

export const PhoneIcon = (props: any) => {
  const icon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.5"
      height="18.5"
      viewBox="0 0 18.5 18.5"
    >
      <path
        id="call_FILL0_wght400_GRAD0_opsz24"
        d="M19.95,21a14.639,14.639,0,0,1-6.287-1.438,19.09,19.09,0,0,1-9.225-9.225A14.639,14.639,0,0,1,3,4.05,1.025,1.025,0,0,1,4.05,3H8.1a.96.96,0,0,1,.625.225A.88.88,0,0,1,9.05,3.8L9.7,7.3a1.8,1.8,0,0,1-.012.637A1.027,1.027,0,0,1,9.4,8.45L6.975,10.9a16.378,16.378,0,0,0,2.638,3.375A18.626,18.626,0,0,0,13.1,17l2.35-2.35a1.4,1.4,0,0,1,.588-.338,1.612,1.612,0,0,1,.712-.062l3.45.7a1.017,1.017,0,0,1,.575.337A.914.914,0,0,1,21,15.9v4.05A1.025,1.025,0,0,1,19.95,21ZM6.025,9l1.65-1.65L7.25,5H5.025q.125,1.025.35,2.025A10.952,10.952,0,0,0,6.025,9ZM19,18.95v-2.2l-2.35-.475L14.975,17.95a12.408,12.408,0,0,0,1.988.675A12.189,12.189,0,0,0,19,18.95ZM15,17.925ZM6.025,9Z"
        transform="translate(-2.75 -2.75)"
        fill="#a0a5a8"
        stroke="#fff"
        strokeWidth="0.5"
      />
    </svg>
  );
  return <Icon component={icon} {...props} />;
};

export const InfoIcon = (props: any) => {
  const icon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.236"
      height="18"
      viewBox="0 0 17.236 18"
    >
      <path
        id="info_FILL0_wght500_GRAD0_opsz48"
        d="M12.382,17.2a.663.663,0,0,0,.5-.211.724.724,0,0,0,.2-.521V12.523a.723.723,0,0,0-.7-.709.663.663,0,0,0-.5.211.724.724,0,0,0-.2.521v3.946a.723.723,0,0,0,.7.709Zm-.064-6.938a.765.765,0,0,0,.562-.222.777.777,0,0,0,.223-.576.855.855,0,0,0-.223-.6.771.771,0,0,0-1.125,0,.855.855,0,0,0-.223.6.777.777,0,0,0,.223.576A.765.765,0,0,0,12.318,10.262Zm0,11.438a8.454,8.454,0,0,1-3.407-.687,8.36,8.36,0,0,1-2.738-1.9,8.787,8.787,0,0,1-1.815-2.86A9.524,9.524,0,0,1,3.7,12.7a9.464,9.464,0,0,1,.658-3.536A8.787,8.787,0,0,1,6.173,6.3,8.5,8.5,0,0,1,8.911,4.4a8.608,8.608,0,0,1,6.792,0A8.5,8.5,0,0,1,18.442,6.3a8.962,8.962,0,0,1,1.825,2.849,9.306,9.306,0,0,1,.669,3.547,9.39,9.39,0,0,1-.669,3.558,8.932,8.932,0,0,1-1.825,2.86A8.43,8.43,0,0,1,12.318,21.7ZM12.318,12.7Zm0,7.493A6.779,6.779,0,0,0,17.38,18a7.364,7.364,0,0,0,2.112-5.3A7.375,7.375,0,0,0,17.38,7.413a6.763,6.763,0,0,0-5.062-2.206A6.763,6.763,0,0,0,7.255,7.413,7.375,7.375,0,0,0,5.143,12.7,7.364,7.364,0,0,0,7.255,18,6.779,6.779,0,0,0,12.318,20.193Z"
        transform="translate(-3.7 -3.7)"
        fill="#a0a5a8"
      />
    </svg>
  );
  return <Icon component={icon} {...props} />;
};

export const PenIcon = (props: any) => {
  const icon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17.992"
      viewBox="0 0 18 17.992"
    >
      <path
        id="edit_FILL0_wght500_GRAD0_opsz48"
        d="M7.493,21.873h1l10.8-10.8-1-1-10.8,10.8ZM22.5,10.017l-3.159-3.16,1-1a1.424,1.424,0,0,1,1.077-.429,1.6,1.6,0,0,1,1.1.453l.98.98a1.351,1.351,0,0,1,.441,1.078,1.606,1.606,0,0,1-.441,1.078ZM6.783,23.416a.8.8,0,0,1-.588-.245.831.831,0,0,1-.245-.612V20.6a.82.82,0,0,1,.061-.318.833.833,0,0,1,.184-.269L18.317,7.886l3.159,3.16L9.354,23.171a.8.8,0,0,1-.282.184.851.851,0,0,1-.306.061Zm12.024-12.86-.514-.49,1,1Z"
        transform="translate(-5.95 -5.424)"
        fill="#a0a5a8"
      />
    </svg>
  );
  return <Icon component={icon} {...props} />;
};

export const CakeIcon = (props: any) => {
  const icon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.83"
      height="17.5"
      viewBox="0 0 15.83 17.5"
    >
      <path
        id="cake_FILL0_wght500_GRAD0_opsz20"
        d="M7.15,21.784a1.309,1.309,0,0,1-1.33-1.33V16.372a1.771,1.771,0,0,1,.462-1.218,1.727,1.727,0,0,1,1.161-.587h.541V10.96a1.821,1.821,0,0,1,.541-1.32,1.79,1.79,0,0,1,1.33-.552h2.931V8.006a2.814,2.814,0,0,1-.6-.6,1.454,1.454,0,0,1-.214-.846A1.678,1.678,0,0,1,12.1,5.92a1.741,1.741,0,0,1,.394-.576l.9-.9.338-.158a.927.927,0,0,1,.338.135L15,5.344a1.6,1.6,0,0,1,.383.576,1.834,1.834,0,0,1,.113.642,1.454,1.454,0,0,1-.214.846,2.814,2.814,0,0,1-.6.6V9.088h2.931a1.79,1.79,0,0,1,1.33.552,1.821,1.821,0,0,1,.541,1.32v3.608h.541a1.727,1.727,0,0,1,1.161.587,1.771,1.771,0,0,1,.462,1.218v4.082a1.309,1.309,0,0,1-1.33,1.33ZM9.856,14.5h7.757V10.96H9.856ZM7.691,19.913H19.778V16.372H7.691ZM9.856,14.5h0ZM7.691,19.913h0ZM20.025,14.5h0Z"
        transform="translate(-5.819 -4.284)"
        fill="#03366b"
      />
    </svg>
  );
  return <Icon component={icon} {...props} />;
};
