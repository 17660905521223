/* eslint-disable react-hooks/rules-of-hooks */
import { MedicalStudyApi } from 'api';
import useFetch from 'hooks/useFetch';
import { createContext, useContext, useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

type props = {
  record?: any;
  isNewRecord: boolean;
  isPreviewRecord: boolean;
  imOwner: boolean;
  fetching: boolean;
  errorMessage: string | any;
};

const RecordContext = createContext<props>({} as props);

const RecordProvider = ({ children }: any) => {
  const { recordId, personId } = useParams();
  const { state, pathname }: any = useLocation();
  const {
    getById,
    loading: { getById: fetching },
  } = useFetch(MedicalStudyApi);
  const record =
    state?.recordInformation?.doc?.id === recordId
      ? state?.recordInformation
      : null;

  const [innerRecord, setInnerRecord] = useState(record);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    setError(null);
    (async () => {
      if (getById && !record?.doc?.id && recordId)
        setInnerRecord(
          (recordId &&
            (await getById(recordId, personId, {
              noCache: true,
            }).catch(setError))) ||
            null,
        );
    })();
  }, [getById, personId, record?.doc?.id, recordId]);

  const getErrorMessage = () => {
    if ([404, 401].includes(error?.response.status))
      return 'El Informe que intentas acceder no existe';
  };

  const isNewRecord = !!(pathname.match('/record') && !recordId);
  const erroAndNew = !error && !isNewRecord;

  const value = {
    record: (!isNewRecord && (record || innerRecord)) || null,
    isNewRecord,
    isPreviewRecord: !!(pathname.match('/record') && recordId),
    imOwner: !!(record || innerRecord)?.imOwner,
    fetching: ((!record && !innerRecord) || fetching) && erroAndNew,
    errorMessage: getErrorMessage(),
  };

  return (
    <RecordContext.Provider value={value}>{children}</RecordContext.Provider>
  );
};

const useRecord = () => useContext<props>(RecordContext);

export { RecordProvider, useRecord };
