import { Radio, RadioChangeEvent, Space } from 'antd';
import SpecialityTag from 'components/specialityTag';
import { memo, useState } from 'react';

const ChooseSpeciality = ({
  specialities,
  onChange,
  defaultSpeciality,
}: any) => {
  const [speciality, setSpeciality] = useState<string>(defaultSpeciality || specialities[0].id);

  const innerChange = (v: RadioChangeEvent) => {
    setSpeciality(v.target.value);
    onChange(v.target.value);
  };

  return (
    <Radio.Group onChange={innerChange} value={speciality}>
      <Space direction="vertical">
        {specialities.map((sp: any) => (
          <Radio key={sp.id} value={sp.id}>
            <SpecialityTag withoutBorder key={sp.id} speciality={sp} />
          </Radio>
        ))}
      </Space>
    </Radio.Group>
  );
};

export default memo(ChooseSpeciality);
