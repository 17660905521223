import {
  FiltersRecords,
  HeaderPerson,
  IcdFabButton,
  Loading,
  MainContentRecords,
  Record,
  RecordsList,
} from 'components';
import { memo, useCallback, useEffect, useLayoutEffect } from 'react';
import style from './index.module.scss';
import { PersonProvider } from 'provider/personProvider';
import {
  getAllProfessionalsEmails,
  getSpecialities,
} from 'systemConfiguration/redux-sagas/actions';
import { useDispatch } from 'react-redux';
import { RecordProvider } from 'provider/recordProvider';
import { RecordUpsertProvider } from 'provider/recordUpsertProvider';
import { useAuth } from 'hooks/useAuth';
import useTourConfig from 'hooks/useTourConfig';

const PeoplePage = () => {
  const { init } = useTourConfig({ screen: 'people' });

  useLayoutEffect(() => {
    init();
  });

  const { getClaims } = useAuth();
  const dispatch = useDispatch();

  const getData = useCallback(async () => {
    await getClaims();
    dispatch(getAllProfessionalsEmails());
    dispatch(getSpecialities());
  }, [dispatch, getClaims]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className={style.people}>
      <div className={style.content}>
        <PersonProvider>
          <HeaderPerson />
        </PersonProvider>
        <div className={style.main}>
          <RecordProvider>
            <MainContentRecords
              loading={<Loading />}
              recordFocus={
                <RecordUpsertProvider>
                  <Record />
                </RecordUpsertProvider>
              }
              recordsList={
                <>
                  <FiltersRecords />
                  <RecordsList />
                </>
              }
            />
          </RecordProvider>
        </div>
      </div>
      <IcdFabButton />
    </div>
  );
};

export default memo(PeoplePage);
