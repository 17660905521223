import { Avatar, Badge, Popover } from 'antd';
import { memo, useRef, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import usePhoto from 'hooks/usePhoto';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { NotificationModal } from 'components';
import { MedicalApi } from 'api';
import style from './index.module.scss';
import { AlertIcon, LogOutIcon, PersonIcon } from 'icons';
import useNotification from 'hooks/useNotification';
import { useAuth } from 'hooks/useAuth';
import useTour from 'hooks/useTour';

const Menu = () => {
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const { pathname } = useLocation();
  const isInit = pathname.includes('/init');
  const { notifications } = useNotification();

  return (
    <>
      <div className={style.menu}>
        {!isInit && (
          <div onClick={() => navigate('/professional/profile')}>
            <PersonIcon height="29" width="29" />
            Mi Perfil
          </div>
        )}
        {!isInit && (
          <div onClick={() => setOpenNotificationModal(true)}>
            <Badge
              size="small"
              dot={!!notifications.filter((e: any) => !e.done)?.length}
            >
              <AlertIcon height="32" width="32" />
            </Badge>
            Avisos
          </div>
        )}
        <div onClick={signOut}>
          <LogOutIcon height="29" width="29" />
          Salir
        </div>
      </div>
      {/* Notification Modal */}
      <NotificationModal
        onClose={setOpenNotificationModal}
        show={openNotificationModal}
      />
    </>
  );
};

const CurrentUser = () => {
  const { ref } = useTour();
  const userRef = useRef<any>();
  const { notifications } = useNotification();
  const { medicalProfile } = useSelector((state: any) => state.medicalProfile);
  const { img } = usePhoto({ api: MedicalApi, person: medicalProfile });

  const { offsetLeft, offsetTop, offsetWidth } = userRef.current || {};

  return (
    <div
      className={style.curren_user_component}
      ref={(r) => {
        ref('profile', r);
        userRef.current = r;
      }}
    >
      <Popover
        className={style.current_user}
        content={<Menu />}
        overlayClassName={style.current_user_open_popover_overlay}
        overlayInnerStyle={{
          width: offsetWidth,
          top: offsetTop,
          left: offsetLeft,
        }}
        openClassName={style.current_user_open_popover}
        arrow={false}
        placement="bottom"
        trigger={'click'}
        children={
          <>
            <Badge
              size="small"
              count={notifications?.filter((n: any) => !n.done)?.length}
              overflowCount={10}
            >
              <Avatar size={'small'} src={img} />
            </Badge>
            {`${medicalProfile.name || ''} ${medicalProfile.lastName || ''}`}
            <DownOutlined />
          </>
        }
      />
    </div>
  );
};

export default memo(CurrentUser);
