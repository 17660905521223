import createReducer from 'store/createReducer';
import * as types from './types';

const initialState = {
  medicalProfile: {
    claims: {},
  },
  isLogged: false,
  loading: false,
  loadingTerms: false,
  /*   loadingChangePassword: false, */
  error: undefined,
};

/**
 * Este reducer es para guardar datos del medico
 */
const MedicalProfileReducer = createReducer(initialState, {
  [types.GET_ME](state, action) {
    return {
      ...state,
      loading: true,
    };
  },
  [types.GET_ME_SUCCESS](state, action) {
    return {
      ...state,
      medicalProfile: {
        ...action.payload,
        claims: { ...state.medicalProfile.claims },
      },
      loading: false,
    };
  },
  [types.GET_ME_ERROR](state, action) {
    return {
      ...state,
      loading: false,
    };
  },
  //////////////////////////////////////////////////////
  [types.MEDICAL_CLEAR](state, action) {
    return {
      ...state,
      error: undefined,
    };
  },
  //////////////////////////////////////////////////////
  [types.SET_LOGGED](state, action) {
    return {
      ...state,
      isLogged: action.payload,
    };
  },
  //////////////////////////////////////////////////////
  [types.SIGN_OUT](state, action) {
    action.$isSync && window.close();
    return {
      ...state,
      isLogged: false,
      medicalProfile: { claims: {} },
    };
  },
  //////////////////////////////////////////////////////
  [types.SET_TERMS_AS_READ](state, action) {
    return {
      ...state,
      isLogged: false,
      loadingTerms: true,
    };
  },
  [types.SET_TERMS_AS_READ_SUCCESS](state, action) {
    return {
      ...state,
      isLogged: true,
      loadingTerms: false,
    };
  },
  [types.SET_TERMS_AS_READ_ERROR](state, action) {
    return {
      ...state,
      loadingTerms: false,
      isLogged: false,
    };
  },
  //////////////////////////////////////////////////////
  [types.UPDATE_MEDICAL_CACHE](state, action) {
    return {
      ...state,
      medicalProfile: {
        ...state.medicalProfile,
        claims: { ...state.medicalProfile.claims, ...action.payload },
      },
    };
  },
});

export default MedicalProfileReducer;
