import { Col, Form, Row, Select } from 'antd';
import { memo, useState } from 'react';
import { GENDE_TYPE } from 'utilities';
import style from './index.module.scss';

const GenderField = () => {
  const [open, setOpen] = useState(false);
  return (
    <Row className={style.gender_field}>
      <Col span={5}>
        <p className={style.label}>Sexo:</p>
      </Col>
      <Col span={4}>
        <Form.Item name="genderId">
          <Select
            onClick={() => setOpen(true)}
            onBlur={() => setOpen(false)}
            bordered={open}
            placeholder="Sexo"
          >
            {GENDE_TYPE.map((type) => {
              const Icon: any = type.icon;
              return (
                <Select.Option key={type.id} value={type.id}>
                  <Icon /> {type.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default memo(GenderField);
