import { useEffect } from 'react';
import api from 'api/api';
import { useDispatch } from 'react-redux';
import { setUploadProcess } from 'systemProcesses/redux-sagas/actions';
import moment from 'moment';
import { useAuth } from 'hooks/useAuth';

const URL_AND_METHODS_TO_MATCH: any = [
  {
    url: '/professional/study',
    payload: 'files',
    methods: [
      {
        post: {
          title: 'Subiendo registro',
          titleDone: 'Registro subido con éxito',
        },
      },
      {
        put: {
          title: 'Actualizando registro',
          titleDone: 'Registro actualizado con éxito',
        },
      },
    ],
  },
];

const ApiProvider = ({ children }: any) => {
  const { getToken } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    // Register interceptor
    const interceptRequest = api.interceptors.request.use(async (config) => {
      const token = await getToken(false);

      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      };

      //Validate if the request is a request with files payload
      const matched = URL_AND_METHODS_TO_MATCH.find(
        ({ url, methods, payload }: any) =>
          config.url?.match(url) &&
          !!methods.find((m: any) => !!m[config.method!]) &&
          !!config.data?.files?.find((f: any) => f.fileName),
      );

      // Listener process
      if (matched) {
        const id = moment().milliseconds();
        config.onUploadProgress = (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          const done = percentCompleted === 100;
          dispatch(
            setUploadProcess({
              id,
              porcent: percentCompleted,
              title: matched.methods.find((m: any) => m[config.method!])[
                config.method!
              ][!done ? 'title' : 'titleDone'],
              done,
            }),
          );
        };
      }

      return config;
    });

    // Response intereptor
    const interceptResponse = api.interceptors.response.use(
      (response) => response,
      (error) => {
        // Reject promise if usual error
        if (
          error.response?.status !== 401 ||
          error.response.data?.errors?.[0].code === '004'
        ) {
          return Promise.reject(error);
        }

        /*
         * When response code is 401, try to refresh the token.
         * Eject the interceptor so it doesn't loop in case
         * token refresh causes the 401 response
         */
        api.interceptors.response.eject(interceptResponse);

        return getToken(true)
          .then((token: string) => {
            error.response.config.headers['Authorization'] = 'Bearer ' + token;
            error.response.config.data = {
              ...JSON.parse(error.response?.config?.data || JSON.stringify({})),
            };
            return api.request(error.response.config);
          })
          .catch((error: Error) => {
            /* IR AL LOGIN PONER NAV */
            return Promise.reject(error);
          });
      },
    );

    return () => {
      api.interceptors.request.eject(interceptRequest);
      api.interceptors.response.eject(interceptResponse);
    };
  }, []);

  return children;
};

export default ApiProvider;
