import { UpsertPerson } from 'components';
import { PersonProvider } from 'provider/personProvider';
import { memo } from 'react';
import style from './index.module.scss';

const UpsertPersonPage = () => {
  return (
    <div className={style.person_profile}>
      <PersonProvider>
        <UpsertPerson />
      </PersonProvider>
    </div>
  );
};

export default memo(UpsertPersonPage);
