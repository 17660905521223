import style from './index.module.scss';
import { memo } from 'react';
import { Button, Col, Row } from 'antd';

type props = {
  choose: (v: 'call' | 'whatsapp') => void;
};

const ChooseCallOrWhatsapp = ({ choose }: props) => (
  <Row>
    <Col className={style.col} span={12}>
      <Button
        className={`mt_primary_button ${style.button}`}
        title="Llamar"
        onClick={() => choose('call')}
      >
        Llamar por teléfono
      </Button>
    </Col>
    <Col className={style.col} span={12}>
      <Button
        className={`mt_primary_button ${style.whatsapp}  ${style.button}`}
        title="Enviar whatsapp"
        onClick={() => choose('whatsapp')}
      >
        Mensaje por WhatsApp
      </Button>
    </Col>
  </Row>
);

export default memo(ChooseCallOrWhatsapp);
