import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { memo, useState } from 'react';
import { SaveOutlined, CloseOutlined } from '@ant-design/icons';
import useFetch from 'hooks/useFetch';
import { MedicalApi } from 'api';
import { removeHtmlTags, required } from 'utilities';
import { useRecordUpsert } from 'hooks/useRecord';

const SaveTemplate = () => {
  const [show, setShow] = useState(false);
  const { data } = useRecordUpsert();
  const [form] = Form.useForm();
  const {
    loading: { saveTemplate: loading },
    saveTemplate,
  } = useFetch(MedicalApi);

  const onSave = async (v: any) => {
    await saveTemplate({ ...v, template: data.current.description });
    innerCancel();
  };

  const innerCancel = () => {
    form.resetFields();
    setShow(false);
  };

  return (
    <>
      <Button title="Guardar plantilla" onClick={() => setShow(true)}>
        <SaveOutlined />
      </Button>
      <Modal
        title="Guardar plantilla"
        width={'fit-content'}
        style={{
          margin: 'auto',
          top: '0px',
        }}
        onCancel={innerCancel}
        footer={[
          <Button key="back" onClick={innerCancel}>
            <CloseOutlined />
            Cancelar
          </Button>,
          <Button
            key="submit"
            disabled={!removeHtmlTags(data.current.description)?.length}
            onClick={() => form.submit()}
            loading={loading}
          >
            <SaveOutlined /> Guardar
          </Button>,
        ]}
        open={show}
      >
        <Form onFinish={onSave} form={form} layout="vertical">
          <Row>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Nombre de la Plantilla"
                rules={[required]}
              >
                <Input
                  autoFocus={true}
                  type={'text'}
                  maxLength={20}
                  showCount
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default memo(SaveTemplate);
