import { Button, Form, Input, Modal } from "antd";
import style from "./index.module.scss";
import { SendOutlined, CloseOutlined } from "@ant-design/icons";
import { required } from "utilities";
import { memo, useEffect } from "react";

type props = {
  onCancel: () => void;
  onSend: ({ email }: { email: string }) => void;
  visible: boolean;
  loading: boolean;
};

const ForgetPassword = ({ onCancel, onSend, visible, loading }: props) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  return (
    <Modal
      title="Recuperar contraseña"
      style={{
        margin: "auto",
        top: "0px",
      }}
      closable={true}
      onCancel={onCancel}
      className={style.forget_password}
      footer={[
        <Button key="back" onClick={onCancel}>
          <CloseOutlined />
          Cancelar
        </Button>,
        <Button loading={loading} key="submit" onClick={() => form.validateFields().then(() => form.submit())}>
          <SendOutlined /> Enviar
        </Button>,
      ]}
      open={visible}>
      <Form layout="vertical" form={form} onFinish={onSend}>
        <Form.Item
          name={"email"}
          label="Correo electrónico"
          rules={[required, { type: "email", message: "Correo no valido" }]}>
          <Input type={"email"} placeholder="example@gmail.com" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default memo(ForgetPassword);
