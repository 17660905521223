import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import '@whoicd/icd11ect/style.css';
import * as ECT from '@whoicd/icd11ect';

(window as any)['ECT'] = ECT;

type props = {
  callBackSelect?: (v: any) => void;
};

const useIcd = ({ callBackSelect }: props) => {
  const [loading, setLoading] = useState(false);
  const [visible, setBrowserVisible] = useState<any>(false);

  useEffect(() => {
    ECT.Handler.configure(
      {
        language: 'es',
        icdLinearization: 'mms',
        searchByCodeOrURI: true,
        height: 'calc(100% - 20px)',
        autoBind: false,
        apiServerUrl: process.env.REACT_APP_ICD_API_URL,
      },
      {
        selectedEntityFunction: (v: any) => {
          callBackSelect && callBackSelect(v);
        },
        searchStartedFunction: () => setLoading(true),
        searchEndedFunction: () => setLoading(false),
      },
    );
  }, []);

  const bind = () => {
    ECT.Handler.bind('1');
  };

  const clearInput = () => {
    ECT.Handler.clear('1');
    ECT.Handler.bind('1');
  };

  const BrowserModal = () => {
    useEffect(() => {
      if (visible) {
        ECT.Handler.bind('2');
        setTimeout(() => {
          ECT.Handler.setBrowserCode('2', visible);
        }, 500);
      } else {
        ECT.Handler.bind('1');
      }
    }, [visible]);

    return (
      visible &&
      ((
        <Modal
          style={{
            margin: 'auto',
            top: '0px',
            height: '90vh',
            borderRadius: '10px',
            overflow: 'hidden',
            padding: 0,
          }}
          width={'90%'}
          bodyStyle={{ overflow: 'hidden' }}
          title={
            <div style={{ display: 'flex', gap: 'var(--gap)' }}>
              <h2 style={{ margin: '0px' }}>CIE-11 Diagnosticos</h2>{' '}
              <i style={{ margin: 0 }}>{visible}</i>
            </div>
          }
          footer={false}
          onCancel={() => setBrowserVisible(false)}
          open={visible}
        >
          <div
            className="ctw-eb-window"
            style={{ height: '100%', display: 'flex', width: '100%' }}
            data-ctw-ino="2"
          ></div>
        </Modal>
      ) ||
        null)
    );
  };

  return { bind, clearInput, loading, setBrowserVisible, BrowserModal };
};

export default useIcd;
