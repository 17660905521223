import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CACHE_PERSON_IMG_KEY, getWithCache } from 'utilities';
import useFetch from './useFetch';
import { useFile } from './useFiles';

const defaultImg = `/assets/icons/default-profile.png`;

type props = {
  api: any;
  person: any;
};

const usePhoto = ({ api, person }: props) => {
  const { downloadProfilePhoto } = useFetch(api);
  const [img, setImg] = useState<any>(defaultImg);
  const { getBaseFromBlob } = useFile();

  useEffect(() => {
    if (person?.profilePhoto) {
      getPhoto();
    }
  }, [person?.doc?.id, person?.profilePhoto?.id, downloadProfilePhoto]);

  const getPhoto = useCallback(async () => {
    if (!downloadProfilePhoto) return;
    try {
      const a = await downloadProfilePhoto(
        person?.doc?.id,
        person.profilePhoto.id,
        getWithCache(CACHE_PERSON_IMG_KEY),
      );
      a && setImg(await getBaseFromBlob(a));
    } catch (error) {
      console.log(error);
    }
  }, [person?.doc?.id, person?.profilePhoto?.id, downloadProfilePhoto]);

  return {
    img: img || defaultImg,
    fallback: defaultImg,
  };
};

export default usePhoto;
