import { Image, Tour, TourProps } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import {
  createContext,
  MutableRefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { fastModalClass } from 'utilities';
import { builderSteps, stepFather, tourBuilder } from 'utilities/tour.steps';

const ALREADY_SEEN_STEPS = 'already-seen-steps';

export type tourContextType = {
  ref: (key: builderSteps, ref: any) => void;
  TourComponent: () => JSX.Element;
  init: () => void;
  setScreen: (screen: stepFather) => void;
};

export const TourContext = createContext<tourContextType>(
  {} as tourContextType,
);

type props = { children: any };

const TourProvider = ({ children }: props) => {
  const refs = useRef(new Map());
  const [screenSetted, setScreenSetted] = useState<stepFather>('home');
  const [open, setOpen] = useState<boolean>(false);

  const ref = useCallback(
    (key: builderSteps, ref: MutableRefObject<any>) =>
      refs.current.set(key, ref),
    [],
  );

  const isAlreadySeen = (key: builderSteps) => {
    const alreadySeen: string[] = JSON.parse(
      localStorage.getItem(ALREADY_SEEN_STEPS) || JSON.stringify([]),
    );
    return alreadySeen.includes(key);
  };

  const setTourSee = (key: builderSteps) => {
    const alreadySeen = JSON.parse(
      localStorage.getItem(ALREADY_SEEN_STEPS) || JSON.stringify([]),
    );
    localStorage.setItem(
      ALREADY_SEEN_STEPS,
      JSON.stringify([...alreadySeen, key]),
    );
  };

  const steps: TourProps['steps'] = Object.entries(tourBuilder[screenSetted])
    .filter(({ 0: key }: any) => !isAlreadySeen(key))
    .map(([key, step]: any) => ({
      ...step,
      ...(step.cover && {
        cover: (
          <Image style={{ width: '200px' }} alt={step.title} src={step.cover} />
        ),
      }),
      target: () => refs.current.get(key),
      nextButtonProps: {
        ...step.nextButtonProps,
        onClick: () => setTourSee(key),
      },
      prevButtonProps: {
        ...step.prevButtonProps,
        onClick: () => setTourSee(key),
      },
    }));

  const onCalose = useCallback(
    (step: number) => {
      setOpen(false);
      step + 1 < steps.length &&
        confirm({
          type: 'warning',
          maskClosable: true,
          closable: true,
          className: fastModalClass,
          title: 'Eliminar ayuda',
          content: 'Eliminar toda la ayuda de esta pantalla?',
          cancelText: 'No',
          okText: 'Eliminar',
          okType: 'text',
          onOk: () => {
            Object.entries(tourBuilder[screenSetted]).forEach(
              ({ 0: key }: any) => setTourSee(key),
            );
          },
        });
    },
    [screenSetted, steps?.length],
  );

  const TourComponent = useCallback(
    () => <Tour open={open} onClose={onCalose} steps={steps} />,
    [onCalose, open, steps],
  );

  const init = useCallback(() => !!refs.current.size && setOpen(true), []);

  const setScreen = useCallback((screen: stepFather) => {
    refs.current = new Map();
    setScreenSetted(screen);
  }, []);

  const value = useMemo(
    () => ({ ref, TourComponent, init, setScreen }),
    [TourComponent, init, setScreen, ref],
  );

  return <TourContext.Provider value={value}>{children}</TourContext.Provider>;
};

export { TourProvider };
