import { memo, useState } from 'react';
import style from './index.module.scss';
import { AuditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { MedicalStudyApi } from 'api';
import useFetch from 'hooks/useFetch';

type props = {
  notification: any;
};

const Notification = ({ notification }: props) => {
  const {
    loading: { approval: loadingApproval, reject: loadingReject },
    approval,
    reject,
  } = useFetch(MedicalStudyApi, {
    showNotification: {
      error: { show: true },
      success: {
        show: { approval: true, reject: true },
        message: {
          approval: 'Solicitud aceptada',
          reject: 'Solicitud rechazada',
        },
      },
    },
  });

  const [isDisabled, setDisabled] = useState(false);

  const notificationType: any = {
    ASK_TO_SEE: {
      icon: <AuditOutlined style={{ fontSize: '30px' }} />,
      text: (
        <p>
          El profesional{' '}
          <b>
            {notification.data.professional?.name || ''}{' '}
            {notification.data.professional?.lastName || ''}
          </b>{' '}
          ha solicitado ver el informe{' '}
          <a
            href={`${window.location.origin}/person/${notification.data.person?.id}/record/${notification.data.studyId}`}
            target="_blank"
            rel="noreferrer"
          >
            {notification.data.study?.title || ''}
          </a>{' '}
          de{' '}
          <b>
            {notification.data.person?.name || ''}{' '}
            {notification.data.person?.lastName || ''}
          </b>
        </p>
      ),
      buttons: [
        {
          key: 'reject',
          label: 'Rechazar',
          type: 'primary',
          loading: loadingReject,
          mode: { danger: 'danger' },
          onClick: async (notificationId: string) => {
            await reject(notificationId);
            setDisabled(true);
          },
        },
        {
          key: 'accept',
          label: 'Aceptar',
          type: 'primary',
          loading: loadingApproval,
          onClick: async (notificationId: string) => {
            await approval(notificationId);
            setDisabled(true);
          },
        },
      ],
    },
  };

  return (
    <div className={style.notification} data-disabled={isDisabled}>
      <div>{notificationType[notification.action].icon}</div>
      <div>
        <div>{notificationType[notification.action].text}</div>
        <div className={style.notification_buttons}>
          {notificationType[notification.action].buttons.map((b: any) => (
            <Button
              disabled={isDisabled}
              loading={b.loading}
              onClick={() => b.onClick(notification.id)}
              type={b.type}
              size="small"
              key={b.key}
              style={{ boxShadow: 'none' }}
              {...b.mode}
            >
              {b.label}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(Notification);
