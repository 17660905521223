import { Button, Form, Input } from 'antd';
import { ForgetPassword, Terms } from 'components';
import { TYPE_TERMS, TYPE_POLICY, TYPE_BOTH } from 'components/terms';
import LottieComponent from 'components/lottie';
import { useAuth } from 'hooks/useAuth';
import { setTermsAsRead } from 'pages/medicalProfile/redux-sagas/actions';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import style from './index.module.scss';
import { OpenNewIcon } from 'icons';
import { sendEmail } from '../../utilities/tools';
import { getTermsAndPrivacity } from 'systemConfiguration/redux-sagas/actions';

const Login = () => {
  const [form] = Form.useForm();
  const { signIn, isLoading, signOut, getToken, forgetPassword } = useAuth();
  const {
    medicalProfile: { claims },
  } = useSelector((state: any) => state.medicalProfile);
  const dispatch = useDispatch();
  const [termsPrivacity, openTermsPrivacity] = useState<
    TYPE_TERMS | TYPE_POLICY | TYPE_BOTH
  >();
  const [visibleForgetPassword, setVisibleForgetPassword] = useState(false);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    dispatch(getTermsAndPrivacity());
    if ('readTerms' in claims && !claims.readTerms)
      openTermsPrivacity('TERMS_AND_CONDITIONS_AND_PRIVACY_POLICY');
  }, [claims, claims?.readTerms]);

  const onFinish = (values: any) => signIn(values.email, values.password);
  const openSendEmail = () => sendEmail('info@ewents.io');

  return (
    <div className={style.login}>
      <h4>Medical Trace</h4>
      <div>
        <p className={style.slogan}>
          Diagnósticos precisos y seguimiento eficiente.
        </p>
        <h2 className={style.sub_slogan}>Todo en un mismo lugar.</h2>
        <p onClick={openSendEmail} className={style.more}>
          Quiero saber más <OpenNewIcon />
        </p>

        <div className={style.content}>
          <div className={style.form_box}>
            <h4>Ingresar</h4>
            <Form layout="vertical" form={form} onFinish={onFinish}>
              <Form.Item
                name="email"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input
                  onChange={() => form.setFieldValue('password', null)}
                  type="email"
                  placeholder="Correo Electrónico"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input.Password placeholder="Contraseña" type="password" />
              </Form.Item>
              <p
                onClick={() => setVisibleForgetPassword(true)}
                className={style.forgot_password}
              >
                Olvidó su contraseña?
              </p>
              <div className={style.button}>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Ingresar
                </Button>
              </div>
            </Form>
            <p
              onClick={() => openTermsPrivacity('TERMS_AND_CONDITIONS')}
              className={style.terms}
            >
              Términos y Condiciones
            </p>
            <p
              onClick={() => openTermsPrivacity('PRIVACY_POLICY')}
              className={style.terms}
            >
              Política de Privacidad
            </p>
          </div>

          <div className={style.lottie_box}>
            <LottieComponent />
          </div>
        </div>
      </div>

      <Terms
        show={!!termsPrivacity}
        type={termsPrivacity}
        onAccept={async () => {
          if (!claims.readTerms) {
            const token = await getToken();
            dispatch(setTermsAsRead(token));
          }
        }}
        onClose={async (close: boolean) => {
          if (!close) return;
          if (!claims.readTerms) signOut();
          openTermsPrivacity(undefined);
        }}
      />
      <ForgetPassword
        visible={visibleForgetPassword}
        loading={sending}
        onCancel={() => setVisibleForgetPassword(false)}
        onSend={async ({ email }) => {
          try {
            setSending(true);
            await forgetPassword(email);
            setVisibleForgetPassword(false);
          } catch (error) {
          } finally {
            setSending(false);
          }
        }}
      />
      <small className={style.version}>V 1.0</small>
    </div>
  );
};

export default memo(Login);
