import { createContext, useCallback, useMemo, useRef, useState } from 'react';
import { getCUIT } from 'utilities';

export type GenderAndCuilContextType = {
  cuilAutogenerated: boolean;
  changeGender: (value: any) => void;
  checkCuil: (value: any) => void;
};

export const GenderAndCuilContext = createContext<GenderAndCuilContextType>(
  {} as GenderAndCuilContextType,
);

const GenderCuildProvider = ({ children, form }: any) => {
  const [cuilAutogenerated, setCuilAutogenerated] = useState(false);

  const changeGender = useCallback(
    (gender: any) => {
      if (
        !gender ||
        !['M', 'F'].includes(gender) ||
        !form.getFieldValue('id') ||
        form.getFieldValue('id').length < 7
      )
        return;
      setCuilAutogenerated(true);
      form.setFieldsValue({
        cuil: getCUIT(form.getFieldValue('id'), gender)?.replace(/-/g, ''),
      });
    },
    [form],
  );

  const checkCuil = useCallback(
    (v: any) => {
      if (
        v.target?.value &&
        (!v.target?.value?.match(form.getFieldValue('id')) ||
          v.target?.value?.length !== 11)
      )
        form.setFields([{ name: 'cuil', errors: ['El CUIL no es correcto'] }]);
      else form.setFields([{ name: 'cuil', errors: [] }]);
      setCuilAutogenerated(false);
    },
    [form],
  );

  const value = useMemo(
    () => ({
      checkCuil,
      changeGender,
      cuilAutogenerated,
    }),
    [changeGender, checkCuil, cuilAutogenerated],
  );

  return (
    <GenderAndCuilContext.Provider value={value}>
      {children}
    </GenderAndCuilContext.Provider>
  );
};

export { GenderCuildProvider };
