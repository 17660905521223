import { useEffect } from "react";
import { useSelector } from "react-redux";
import en from "antd/lib/locale/en_US";
import es from "antd/lib/locale/es_ES";
import { ConfigProvider } from "antd";
import moment from "moment";
import "moment/locale/es";
import { I18nextProvider } from "react-i18next";

const localeMap = {
  es: es,
  en: en,
};

const LanguageProvider = ({ children, i18n }: any) => {
  const i18nSelected = useSelector((store: any) => store?.profile?.i18n);

  useEffect(() => {
    i18nSelected && moment.locale(i18nSelected);
  }, [i18nSelected]);

  return (
    <ConfigProvider locale={localeMap.es}>
      <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
    </ConfigProvider>
  );
};

export default LanguageProvider;
