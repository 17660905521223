import { ConfigurationApi } from 'api';
import classNames from 'classnames';
import useIcon from 'hooks/useIcon';
import { memo } from 'react';
import style from './index.module.scss';

type props = {
  speciality: any;
  isSelected?: boolean;
  onClick?: () => void;
  withoutBorder?: boolean;
  onRecord?: boolean;
};

const SpecialityTag = ({
  speciality,
  isSelected,
  onClick,
  withoutBorder,
  onRecord,
}: props) => {
  const { img } = useIcon({ api: ConfigurationApi, speciality });
  return (
    <div
      style={{ color: speciality.color, borderColor: speciality.color }}
      className={classNames(style.speciality, {
        [style.without_border]: withoutBorder,
        [style.selected]: isSelected,
        [style.on_record]: onRecord,
      })}
      onClick={onClick}
    >
      {img && <img className={style.icon} src={img} alt={speciality.name} />}
      {speciality.name}
    </div>
  );
};

export default memo(SpecialityTag);
