import {
  Home,
  InitProcess,
  ProfessionalProfile,
  PeoplePage,
  Search,
  SearchICD,
  UpsertPersonPage,
} from 'pages';

export const routes: any[] = [
  {
    path: '/',
    component: Home,
  },
  { path: '/init', component: InitProcess },
  { path: '/person/search', component: Search },
  { path: '/person/:personId/edit', component: UpsertPersonPage },
  { path: '/person/:personId', component: PeoplePage },
  { path: '/person', component: UpsertPersonPage },
  { path: '/person/:personId/record', component: PeoplePage },
  { path: '/person/:personId/record/:recordId', component: PeoplePage },
  { path: '/professional/profile', component: ProfessionalProfile },
  { path: '/search-icd', component: SearchICD },
];
