import { Input } from 'antd';
import { SearchIcon } from 'icons';
import style from './index.module.scss';
import { memo, useState, useEffect } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useTour from 'hooks/useTour';
import classNames from 'classnames';

const SearchHeaderInput = () => {
  const { ref } = useTour();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [cuit_id, setCuidId] = useState<any>(searchParams.get('cuil_id'));
  const { pathname } = useLocation();

  const navigateToSearch = () => {
    if (!cuit_id) {
      close();
      return;
    }
    navigate(`/person/search?cuil_id=${cuit_id}`);
  };

  useEffect(() => {
    setCuidId(searchParams.get('cuil_id'));
  }, [searchParams.get('cuil_id')]);

  const close = () => {
    navigate(`/`);
  };

  const setCuilId = (v: any) => {
    setCuidId(v.target.value?.replace(/[.,]/g, ''));
  };

  return (
    <div
      ref={(r) => ref('search_bar', r)}
      className={classNames(
        { [style.searched]: !!cuit_id },
        style.search_header,
      )}
    >
      <Input
        type="number"
        bordered={false}
        value={cuit_id}
        onChange={setCuilId}
        onPressEnter={navigateToSearch}
        placeholder="Buscar por usuario, DNI o CUIT"
        prefix={<SearchIcon />}
        suffix={
          cuit_id && pathname !== '/person/search' ? (
            'Pulse ‘ENTER’ para realizar su búsqueda'
          ) : cuit_id ? (
            <div className={style.close} onClick={close}>
              <CloseOutlined /> Limpiar
            </div>
          ) : (
            ''
          )
        }
      />
    </div>
  );
};

export default memo(SearchHeaderInput);
