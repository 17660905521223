import { Col, Form, Input, Row } from 'antd';
import { memo, useState } from 'react';
import { removeFirstAndSecondLastEmptySpace } from 'utilities';
import style from './index.module.scss';

type props = {
  label: string;
  name: string;
  type: string;
  placeholder: string;
  rules?: any[];
  formProps?: { [x: string]: any };
  labelSpan?: number;
  inputSpan?: number;
  rowWidth?: string;
  [x: string]: any;
};

const CommonField = ({
  label,
  name,
  type,
  placeholder,
  rules,
  formProps,
  rowWidth,
  labelSpan,
  inputSpan,
  ...props
}: props) => {
  const [selected, setSelected] = useState(false);
  return (
    <Row style={{ width: rowWidth }} className={style.common_field}>
      <Col span={labelSpan || 5}>
        <p className={style.label}>{label}</p>
      </Col>
      <Col span={inputSpan || 5}>
        <Form.Item
          normalize={removeFirstAndSecondLastEmptySpace}
          name={name}
          rules={rules}
          {...formProps}
        >
          <Input
            onClick={() => setSelected(true)}
            onBlur={() => setSelected(false)}
            autoComplete="off"
            bordered={selected}
            type={type}
            placeholder={placeholder}
            {...props}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default memo(CommonField);
