import { Avatar, Col, Row } from 'antd';
import { PeopleApi } from 'api';
import classNames from 'classnames';
import useDate from 'hooks/useDate';
import usePhoto from 'hooks/usePhoto';
import { FameleIcon, MaleIcon } from 'icons';
import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import style from './index.module.scss';

type props = {
  person: any;
  big?: boolean;
  pointer?: boolean;
};

const PeronChip = ({ person, big, pointer }: props) => {
  const navigate = useNavigate();
  const { img, fallback } = usePhoto({ api: PeopleApi, person });
  const dni = useCallback((dni: string) => <div>DNI: {dni}</div>, []);
  const cuil = useCallback((cuil: string) => <div>CUIL: {cuil}</div>, []);
  const { getDifference } = useDate();

  const openPerson = (e: any) => {
    e.stopPropagation();
    const url: string = `/person/${person.doc.id}`;
    navigate(url, {
      state: { personInformation: person },
    });
  };

  return (
    <div
      className={classNames(
        { [style.pointer]: pointer },
        style.person_study_chip,
      )}
      onClick={openPerson}
    >
      <Row gutter={big ? 15 : 0}>
        <Col span={big ? 4 : 5}>
          <Avatar
            className={classNames({ [style.big]: big })}
            src={img || fallback}
            size="large"
          />
        </Col>
        <Col span={big ? 17 : 15} className={style.person_info}>
          <Row>
            <Col span={24}>
              <div className={style.person_name}>
                {person.name} {person.lastName}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className={style.person_id} span={big ? 12 : 24}>
              {dni(person.id)}
            </Col>
            {big && (
              <Col className={style.person_id} span={12}>
                {cuil(person.cuil)}
              </Col>
            )}
          </Row>
        </Col>
        <Col className={style.person_study_person_gender} span={big ? 2 : 4}>
          <div>
            {person.genderId === 'M' ? <MaleIcon /> : <FameleIcon />}
            {getDifference(person.birthdate, 'years')}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default memo(PeronChip);
