import { useEffect } from "react";

const useOnScreen = (callBack?: Function) => {
  const setObserver = (elementId: string) => {
    const el = document.getElementById(elementId);
    el && observer.disconnect();
    el && observer.observe(el);
  };

  const observer = new IntersectionObserver(([entry]) => {
    entry.isIntersecting ? callBack && callBack("visible") : callBack && callBack("hidden");
  });

  useEffect(() => {
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return { setObserver };
};

export default useOnScreen;
