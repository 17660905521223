import { Divider, Form } from 'antd';
import {
  ProfessionalInformation,
  ProfessionalAddresses,
  PhoneField,
  CommonField,
  ProfileLayout,
  NameField,
  BirthDateField,
} from 'components';
import { memo, useState } from 'react';
import { CUIL, DNI, email, validateNumber } from 'utilities';
import style from './index.module.scss';
import { MedicalApi } from 'api';
import { IdPersonIcon } from 'icons';
import { ProfessionalProvider } from 'provider/professionalProvider';
import useNav from 'hooks/useNav';
import ChangePasswordModal from 'components/changePasswordModal';
import { useProfessional } from 'hooks/useProfessional';
import GenderField from 'components/genderField';

const ChangePassword = memo(() => {
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  return (
    <>
      <i
        onClick={() => setOpenChangePasswordModal(true)}
        className={style.change_password_label}
      >
        Cambiar Contraseña
      </i>
      {/* Change password modal */}
      <ChangePasswordModal
        onClose={setOpenChangePasswordModal}
        show={openChangePasswordModal}
      />
    </>
  );
});

const FormContent = memo(() => {
  const { form, professional, initialValues, onUpdate } = useProfessional();
  return (
    <Form
      initialValues={initialValues}
      form={form}
      className={style.content}
      onFinish={onUpdate}
    >
      <NameField />
      <Divider style={{ marginTop: 10 }} type="horizontal" />
      <h3 className={style.sub_title}>Información Profesional</h3>
      <GenderField />
      <BirthDateField
        bordered={false}
        label="Nacimiento:"
        placeholder="dd/mm/yyyy"
      />
      <CommonField
        label="DNI:"
        rules={[DNI]}
        placeholder="xxxxxxxx"
        maxLength={8}
        type="text"
        name="id"
        autoComplete="off"
        prefix={<IdPersonIcon />}
        formProps={{ normalize: validateNumber }}
      />
      <CommonField
        label="CUIL:"
        rules={[CUIL]}
        placeholder="xx-xxxxxxxx-x"
        maxLength={13}
        type="text"
        name="cuil"
        autoComplete="off"
        prefix={<IdPersonIcon />}
        formProps={{ normalize: validateNumber }}
      />
      <h6 style={{ marginTop: 10 }}>{`Especialida${
        professional.specialities?.length > 1 ? 'des' : 'd'
      }:`}</h6>
      <ProfessionalInformation professional={professional} />
      <Divider className={style.divider} type="horizontal" />
      <h3>Información de Cuenta</h3>
      <CommonField
        label="Correo electrónico:"
        rules={[email]}
        placeholder="Correo electrónico"
        maxLength={254}
        disabled
        type="email"
        name="email"
      />
      <CommonField
        label="Contraseña:"
        rules={[email]}
        placeholder="********************"
        maxLength={254}
        disabled
        type="password"
        name="password"
        autoComplete="off"
      />
      <ChangePassword />
      <Divider className={style.divider} type="horizontal" />
      <h3>Información de Contacto</h3>
      <PhoneField />
      <CommonField
        label="Correo electrónico:"
        rules={[email]}
        placeholder="Correo electrónico"
        maxLength={254}
        inputSpan={6}
        type="email"
        name="contactEmail"
      />
      <Divider className={style.divider} type="horizontal" />
      <h3>Información de Atención</h3>
      <ProfessionalAddresses />
      <Divider className={style.divider} type="horizontal" />
    </Form>
  );
});

const ProfesisonalProfileProvider = memo(() => {
  const { constants, form, loading, updating, professional } =
    useProfessional();
  const { backNavigate } = useNav();

  return (
    <div className={style.professional_profile}>
      <ProfileLayout
        onCancel={() => backNavigate('/')}
        api={MedicalApi}
        okButtonLabel="Guardar"
        editing
        constants={constants}
        form={form}
        loading={loading}
        person={professional}
        okLoading={updating}
      >
        <FormContent />
      </ProfileLayout>
    </div>
  );
});

const ProfessionalProfile = () => {
  return (
    <ProfessionalProvider>
      <ProfesisonalProfileProvider />
    </ProfessionalProvider>
  );
};

export default memo(ProfessionalProfile);
