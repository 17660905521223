/* Setea un string por caracteres alfanuméricos sin espacio al inicio y no mas de un espacio seguido. */
export const validateString = (value:any, preValue:any) => {
  if (value === undefined) return '';
  const reg = /.+|[\s\uFEFF\xA0]+$/gi;
  if (value === '' || reg.test(value)) {
    return value.replace(/^\s+/, '').replace(/(\s{2,})/g, ' ');
  } else {
    return preValue
      ? preValue.replace(/^\s+/, '').replace(/(\s{2,})/g, ' ')
      : '';
  }
};
/* Setea un string a solo caracteres numéricos sin espacios. */
export const validateNumber = (value: any, preValue:any) => {
  const reg = /^[0-9]+$/;
  if (value === '' || reg.test(value)) {
    return value.trim();
  } else {
    return preValue ? preValue?.trim() : '';
  }
};
/* Valida que la cadena ingresada solo contenga caracteres alfanuméricos y (){}[]“·$@%&*^! */
export const validatePassword = (value:any, preValue:any) => {
  const reg = /^[a-zA-Z0-9(){}[]“·$@%&*^!]+$/;
  if (value === '' || reg.test(value)) {
    return value.trim();
  } else {
    return preValue ? preValue?.trim() : '';
  }
};

export const disabledAutocomplate = () => {
  for (const el of document.getElementsByClassName(
    'ant-select-selection-search-input',
  ) || []) {
    el.setAttribute('autocomplete', 'registration-select');
  }
};
