import { Col, Form, FormInstance, Input, Row, Select } from 'antd';
import { ConfigurationApi } from 'api';
import useFetch from 'hooks/useFetch';
import { memo, useEffect, useState } from 'react';
import style from '././index.module.scss';
import { GoogleMap } from '../map';
import { getAllZip } from 'systemConfiguration/redux-sagas/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AddressSelectField } from 'components';
import AddressField from 'components/addressField';
import CommonField from 'components/commonField';
import { email } from 'utilities';
import PhoneField from 'components/phoneField';
import { EnvironmentOutlined } from '@ant-design/icons';

type propsAddress = {
  form: FormInstance<any>;
  center?: any;
  isEditing?: boolean;
  observation?: string;
  observationPlaceholder?: string;
  allowContact?: boolean;
};

const PostalCode = () => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(false);
  const { zip } = useSelector((state: any) => state.systemConfiguration);

  useEffect(() => {
    !zip?.length && dispatch(getAllZip());
  }, [dispatch, zip?.length]);

  return (
    <Col span={10}>
      <Row>
        <Col span={8}>
          <p className={style.label}>Codigo postal</p>
        </Col>
        <Col span={14}>
          <Form.Item name="zip">
            <Select
              bordered={selected}
              showSearch
              onBlur={() => setSelected(false)}
              onFocus={() => setSelected(true)}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              allowClear
              placeholder="Seleccione una opción"
            >
              {zip?.map((zip: any) => (
                <Select.Option
                  key={`${zip.CP} - ${zip.Localidad}`}
                  value={`${zip.CP} - ${zip.Localidad}`}
                >
                  {`${zip.CP} - ${zip.Localidad}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Col>
  );
};

const ContactAddress = memo(
  ({ forceOpen = false }: { forceOpen?: boolean }) => {
    const [allow, setAllow] = useState<boolean>(forceOpen);

    return (
      <>
        <i className={style.contact_button} onClick={() => setAllow(!allow)}>
          {(!allow && 'Agregar información de contacto') || 'Ocultar'}
        </i>
        {allow && (
          <div className={style.contact}>
            <h5 className={style.sub_title}>
              Información de Contacto (opcional)
            </h5>
            <Row>
              <CommonField
                rowWidth={'48%'}
                labelSpan={10}
                inputSpan={12}
                label="Correo electrónico:"
                rules={[email]}
                placeholder="Correo electrónico"
                maxLength={254}
                type="email"
                name="addressEmail"
              />
              <PhoneField
                rowWidth={'45%'}
                labelSpan={5}
                phoneCodeSpan={9}
                phoneSpan={8}
                phoneCodeName="addressPhoneCode"
                phoneName="addressPhone"
              />
            </Row>
          </div>
        )}
      </>
    );
  },
);

type observationFieldType = {
  form: FormInstance;
  observation: string;
  observationPlaceholder: string;
  disabled: boolean;
};
const ObservationField = memo(
  ({
    form,
    observation,
    observationPlaceholder,
    disabled,
  }: observationFieldType) => {
    const [selected, setSelected] = useState(false);
    return (
      <Col span={13}>
        <Row>
          <Col span={6}>
            <p
              className={`${style.label} ${
                !form.getFieldValue('localityId') ? style.disabled : ''
              }`}
            >
              {observation}
            </p>
          </Col>
          <Col span={17}>
            <Form.Item name="observation">
              <Input
                onClick={() => setSelected(true)}
                onBlur={() => setSelected(false)}
                bordered={selected}
                showCount
                autoComplete="off"
                disabled={disabled}
                maxLength={50}
                placeholder={observationPlaceholder}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    );
  },
);

const Address = ({
  form,
  isEditing,
  allowContact = false,
  center,
  observation = 'Observación',
  observationPlaceholder = 'Entre calle...',
}: propsAddress) => {
  const { loading, data, getStates, getDepartments, getLocalities } = useFetch(
    ConfigurationApi,
    {
      clearBefore: false,
      showNotification: { success: { show: false }, error: { show: false } },
    },
  );
  const [zoom, setZoom] = useState<any>();
  const [innerCenter, setCenter] = useState<any>(center);
  const [click, setClick] = useState<any>();

  useEffect(() => {
    getStates && getStates();
  }, [getStates]);

  useEffect(() => {
    setClick(undefined);
    if (!isEditing) return;

    const { latitude, longitude } = form.getFieldsValue();

    if (latitude && longitude) {
      setCenter({ lat: latitude, lng: longitude });
      setClick({ lat: latitude, lng: longitude });
      setZoom(17);
    }
    const { stateId } = form.getFieldsValue();

    if (stateId) {
      getDepartmentsByState(stateId);
    }
    const { departmentId } = form.getFieldsValue();

    if (departmentId) {
      getLocalitiesByDepartment(departmentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, getLocalities, getDepartments, center]);

  const setDefaultCenter = ({ lat, lng }: { lat: any; lng: any }) => {
    if (lat && lng) {
      form.setFieldsValue({
        latitude: lat,
        longitude: lng,
      });
      setCenter({ lat, lng });
    }
  };

  const getDepartmentsByState = (id: number, ...data: any) => {
    if (data[0]) {
      setDefaultCenter({
        lat: data[0].centroide.lat,
        lng: data[0].centroide.lon,
      });
      setZoom(9);
    }
    (!isEditing || !id) &&
      form.setFieldsValue({ departmentId: undefined, localityId: undefined });
    getDepartments && getDepartments(id);
  };

  const getLocalitiesByDepartment = (id: number, ...data: any) => {
    if (data[0]) {
      setDefaultCenter({
        lat: data[0].centroide.lat,
        lng: data[0].centroide.lon,
      });
      setZoom(12);
    }
    (!isEditing || !id) && form.setFieldsValue({ localityId: undefined });
    getLocalities && getLocalities(id);
  };

  const onClickMap = (v: any) => {
    form.setFieldsValue({
      latitude: v.lat(),
      longitude: v.lng(),
    });
  };

  return (
    <div className={style.address}>
      <Row gutter={30}>
        <AddressSelectField
          label={'Provincia'}
          name="stateId"
          loading={loading['getStates']}
          onChange={getDepartmentsByState}
          data={data['getStates']?.items}
        />
        <AddressSelectField
          label={'Departamento'}
          name="departmentId"
          disabled={!form.getFieldValue('stateId')}
          loading={loading['getDepartments']}
          onChange={getLocalitiesByDepartment}
          data={data['getDepartments']?.items}
        />
      </Row>
      <Row gutter={30}>
        <AddressSelectField
          label={'Localidad'}
          name="localityId"
          disabled={!form.getFieldValue('departmentId')}
          loading={loading['getLocalities']}
          onChange={(_: any, ...data) => {
            if (data) {
              setDefaultCenter({
                lat: data[0]?.centroide.lat,
                lng: data[0]?.centroide.lon,
              });
              setZoom(15);
            }
          }}
          data={data['getLocalities']?.items}
        />
        <AddressField disabled={!form.getFieldValue('localityId')} />
      </Row>
      <Row gutter={30}>
        <PostalCode />
        <ObservationField
          disabled={!form.getFieldValue('localityId')}
          form={form}
          observation={observation}
          observationPlaceholder={observationPlaceholder}
        />
      </Row>
      {allowContact && (
        <ContactAddress
          forceOpen={
            !!form.getFieldValue('addressEmail') ||
            !!form.getFieldValue('addressPhone')
          }
        />
      )}
      <Row gutter={15}>
        <Col span={12}>
          <Form.Item hidden name="latitude">
            <Input hidden disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item hidden name="longitude">
            <Input hidden disabled />
          </Form.Item>
        </Col>
      </Row>
      {!!form.getFieldValue('stateId') && (
        <>
          <p className={style.sub_title}>
            Ubicación precisa: <EnvironmentOutlined />
          </p>
          <div className={style.map} style={{ width: '100%', height: '300px' }}>
            <GoogleMap
              click={click}
              onClick={onClickMap}
              newCenter={innerCenter}
              newZoom={zoom}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default memo(Address);
