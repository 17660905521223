import { Rule } from 'antd/lib/form';

const required = {
  required: true,
  message: 'Campo requerido',
};

const email: Rule = {
  type: 'email',
  message: 'Ingrese un email válido',
};

const DNI: Rule = {
  min: 7,
  max: 8,
  message: 'Ingrese un DNI válido',
};

const CUIL: Rule = {
  min: 10,
  max: 11,
  message: 'Ingrese un CUIL válido',
};
export { required, email, DNI, CUIL };
