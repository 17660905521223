import useDate from 'hooks/useDate';
import style from './index.module.scss';
import { PaperClipOutlined } from '@ant-design/icons';
import { Badge, Empty } from 'antd';
import { memo } from 'react';
import useIcd from 'hooks/useIcd';
import { usePerson } from 'hooks/usePerson';
import { LockIcon } from 'icons';

type props = {
  diagnostics: any;
  type: 'antecedents' | 'diagnostics';
};

const DiagnosticsItem = ({ diagnostics, type }: props) => {
  const innerDiagnostics = diagnostics[type]?.[0];
  const antecedentCounter = diagnostics[type]?.length || 0;
  const { setBrowserVisible, BrowserModal } = useIcd({});
  const { person } = usePerson();
  const { applyFormat } = useDate();

  const openStudy = (record?: string) => {
    const url: string = `/person/${person.doc.id}/record/${
      (record || innerDiagnostics).record.split('/')[1]
    }`;
    window.open(url, '_blank');
  };

  const iAMOwnerAndIsPrivate = (v: any) =>
    v?.imOwner && v?.visibility === 'private';

  const getCreatedBy = (v: any) => {
    return (
      <div className={style.created_by}>
        Creado por{' '}
        {v?.imOwner ? (
          <>Mi {iAMOwnerAndIsPrivate(v) && <LockIcon />}</>
        ) : (
          `${v?.professional?.name} ${v?.professional?.lastName}`
        )}
      </div>
    );
  };

  const getDescription = ({ isVisible, description, ...rest }: any) => {
    if (isVisible && description) return description;

    if (!isVisible)
      return (
        <div className={style.not_visible}>
          <LockIcon />
          <p>
            No tienes permisos para ver este comentario,
            <div onClick={() => openStudy(rest)}>ir al registro</div>
          </p>
        </div>
      );

    if (isVisible && !description)
      return (
        <Empty className={style.empty_comment} description="Sin comentario" />
      );
  };

  return (
    <Badge
      color={'var(--card-color)'}
      style={{ top: '7px', right: '7px' }}
      title={`Cantidad de profesionales que cargaron este antecedente ( ${antecedentCounter} )`}
      count={antecedentCounter > 1 ? antecedentCounter : 0}
      size="small"
    >
      <div className={style.diagnostics_item}>
        <h3
          title={innerDiagnostics.title}
          className={style.diagnostics_item_title}
          onClick={() => setBrowserVisible(innerDiagnostics.code)}
        >{`${innerDiagnostics.code} - ${innerDiagnostics.title}`}</h3>
        <div className={style.description}>
          {diagnostics[type].map((a: any) => (
            <div key={a.doc.id}>
              {a.isVisible && (
                <div
                  title="Abrir registro relacionado"
                  onClick={() => openStudy(a)}
                  className={style.clip_study}
                >
                  <PaperClipOutlined />
                </div>
              )}
              <p className={style.description_text}>{getDescription(a)}</p>
              {antecedentCounter > 1 && (
                <>
                  <div className={style.small_information}>
                    {getCreatedBy(a)}
                  </div>
                  <div
                    className={style.small_information}
                  >{`Registrado el ${applyFormat(
                    innerDiagnostics.registeredAt,
                    'DD/MM/YYYY HH:mm [hs]',
                  )}`}</div>
                </>
              )}
            </div>
          ))}
        </div>
        {antecedentCounter === 1 && (
          <div className={style.information}>
            {getCreatedBy(innerDiagnostics)}
            <div>{`Registrado el ${applyFormat(
              innerDiagnostics.registeredAt,
              'DD/MM/YYYY HH:mm [hs]',
            )}`}</div>
          </div>
        )}
      </div>
      {BrowserModal()}
    </Badge>
  );
};

export default memo(DiagnosticsItem);
