import { Col, Form, Input, Row } from 'antd';
import { memo, useState } from 'react';
import style from './index.module.scss';

type props = {
  disabled?: boolean;
};

const AddressField = ({ disabled }: props) => {
  const [selectedAddress, setSelectedAddress] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState(false);
  return (
    <Col span={13}>
      <Row gutter={15}>
        <Col span={6}>
          <p className={`${style.label} ${disabled ? style.disabled : ''}`}>
            Domicilio
          </p>
        </Col>
        <Col span={12}>
          <Form.Item name="street">
            <Input
              onClick={() => setSelectedAddress(true)}
              onBlur={() => setSelectedAddress(false)}
              bordered={selectedAddress}
              autoComplete="off"
              placeholder="Calle"
              disabled={disabled}
              maxLength={100}
              type={'text'}
            />
          </Form.Item>
        </Col>
        <Col span={1}>
          <p className={`${style.label} ${disabled ? style.disabled : ''}`}>
            N°
          </p>
        </Col>
        <Col span={4}>
          <Form.Item name="streetNumber">
            <Input
              onClick={() => setSelectedNumber(true)}
              onBlur={() => setSelectedNumber(false)}
              bordered={selectedNumber}
              autoComplete="off"
              placeholder="xxx"
              disabled={disabled}
              maxLength={20}
              type={'text'}
            />
          </Form.Item>
        </Col>
      </Row>
    </Col>
  );
};
export default memo(AddressField);
