import { Empty, Modal } from 'antd';
import { memo, useEffect, useRef, useState } from 'react';
import style from './index.module.scss';
import { PaperClipOutlined, LoadingOutlined } from '@ant-design/icons';
import useFetch from 'hooks/useFetch';
import { MedicalStudyApi } from 'api';
import { useFile } from 'hooks/useFiles';
import useDate from 'hooks/useDate';
import { usePerson } from 'hooks/usePerson';
import { LockIcon } from 'icons';
import classNames from 'classnames';

type props = {
  show: boolean;
  onClose: () => void;
};

const FilesModal = ({ show, onClose }: props) => {
  const { person } = usePerson();
  const {
    getFiles,
    downloadFile,
    loading: { getFiles: loading },
  } = useFetch(MedicalStudyApi);
  const [files, setFiles] = useState([]);
  const last = useRef();
  const { getBaseFromBlob, downloadFile: download, ImgFile } = useFile();
  const { applyFormat } = useDate();

  useEffect(() => {
    if (!show || !getFiles) return;
    getInnerFiles();
  }, [show, getFiles]);

  const innerDownload = async (file: any, studyId: string) => {
    download(
      (await getBaseFromBlob(await downloadFile(studyId, file.id))) as string,
      file.fileName,
    );
  };

  const getInnerFiles = async () => {
    const { items, lastElement } = await getFiles(person.doc.id);
    setFiles(items);
    last.current = lastElement;
  };

  const openStudy = (studyId: any) =>
    window.open(`/person/${person.doc.id}/record/${studyId}`, '_blank');

  const showList = !!files.length && !loading;

  const iAMOwnerAndIsPrivate = (a: any) =>
    a?.imOwner && a?.visibility === 'private';

  const getCreatedBy = (a: any) => {
    return (
      <div className={style.created_by}>
        Creado por{' '}
        {a?.imOwner ? (
          <> Mi {iAMOwnerAndIsPrivate(a) && <LockIcon />}</>
        ) : (
          `${a?.professional?.name} ${a?.professional?.lastName}`
        )}
      </div>
    );
  };

  return (
    <>
      {show && (
        <Modal
          footer={false}
          style={{
            margin: 'auto',
            top: '0px',
            height: '90vh',
          }}
          width={'75%'}
          title={<h2 style={{ margin: 0 }}>Archivos cargados</h2>}
          onCancel={onClose}
          className={style.modal_files}
          open={show}
        >
          {(showList &&
            files?.map((a: any) => (
              <div
                title={a.isVisible && 'Descargar archivo'}
                onClick={() => innerDownload(a, a.study.id)}
                key={`file-${a.id}`}
                className={classNames(style.file, {
                  [style.visible]: a.isVisible,
                })}
              >
                {a.study && a.isVisible && (
                  <div
                    title="Abrir registro relacionado"
                    onClick={(e) => {
                      e.stopPropagation();
                      openStudy(a.study.id);
                    }}
                    className={style.clip_study}
                  >
                    <PaperClipOutlined />
                  </div>
                )}
                {(a.isVisible && (
                  <div className={style.file_icon}>
                    {ImgFile[a.fileType] || ImgFile.default}
                  </div>
                )) || (
                  <div className={style.not_visible}>
                    <LockIcon />
                    <p>
                      No tienes permisos para ver este archivo,
                      <div onClick={() => openStudy(a.study.id)}>
                        ir al registro
                      </div>
                    </p>
                  </div>
                )}
                <div className={style.file_name}>
                  <p title={a.fileName}>{a.fileName}</p>
                </div>
                <div>
                  {getCreatedBy(a)}
                  <div
                    className={style.small_information}
                  >{`Registrado el ${applyFormat(
                    a.registeredAt,
                    'DD/MM/YYYY HH:mm [hs]',
                  )}`}</div>
                </div>
              </div>
            ))) ||
            (!loading && (
              <div className={style.modal_empty}>
                <Empty description="Sin archivos cargados" />
              </div>
            ))}
          {loading && (
            <div className={style.files_loading}>
              {loading && <LoadingOutlined />} Cargando...
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default memo(FilesModal);
