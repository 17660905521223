import { Button, Input, Tag } from 'antd';
import { memo, useEffect, useLayoutEffect, useState } from 'react';
import { CloseCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import style from './index.module.scss';
import useIcd from 'hooks/useIcd';

type props = {
  onSelected?: (icd: any) => void;
  isSelect?: boolean;
  selected?: any[];
};

const IcdComponent = ({ onSelected, isSelect = false, selected }: props) => {
  const [value, setValue] = useState('');
  const [innerSelected, setSelected] = useState<any[]>(
    (isSelect && selected) || [],
  );
  const { clearInput, loading, setBrowserVisible, BrowserModal } = useIcd({
    callBackSelect: (v) => {
      if (!isSelect) return;
      setSelected((state: any[]) => {
        if (state.find((s: any) => s.code === v.code)) {
          return state;
        }
        return [...state, v];
      });
    },
  });

  const removeSelected = (v: any) => {
    setSelected((state: any[]) => state?.filter((s: any) => s.code !== v.code));
    onSelected &&
      onSelected(innerSelected.filter((s: any) => s.code !== v.code));
  };

  // TRIGGER WHEN SELECTED IS CHANGED
  useEffect(() => {
    if (!isSelect) return;
    onSelected && onSelected(innerSelected);
  }, [innerSelected.length]);

  // TRIGGER WHEN INPUT IS CHANGED
  useEffect(() => {
    if (!isSelect) return;
    onSelected && onSelected(selected);
  }, [selected?.length]);

  const innserClear = () => {
    setValue('');
    clearInput();
  };

  useLayoutEffect(() => {
    setTimeout(() => {
      innserClear();
    }, 2000);
  }, []);

  return (
    <div className={style.icd}>
      <div className={style.search}>
        <label>
          <p className={style.label_search}>Buscar diagnosticos</p>{' '}
          {!!innerSelected.length && (
            <b>seleccionado{innerSelected.length > 1 ? 's: ' : ': '}</b>
          )}
          {innerSelected.map((v) => (
            <Tag onClick={() => setBrowserVisible(v.code)}>
              <b
                key={v.code}
                onClick={(e) => {
                  e.stopPropagation();
                  removeSelected(v);
                }}
                className={style.tag_content}
              >
                {`${v?.code}`}{' '}
                <CloseCircleOutlined style={{ fontSize: '10px' }} />
              </b>
            </Tag>
          ))}
        </label>
        <div className={style.input_button}>
          <Input
            placeholder="Buscar en el CIE..."
            value={value}
            type="text"
            onChange={(e) => setValue(e.target.value)}
            className="ctw-input"
            autoComplete="off"
            data-ctw-ino="1"
          />
          <Button title="Recargar / Activar" onClick={innserClear}>
            <ReloadOutlined spin={loading} />
          </Button>
        </div>
      </div>
      <div className={style.content}>
        <div className="ctw-window" data-ctw-ino="1"></div>
      </div>
      {isSelect && BrowserModal()}
    </div>
  );
};

export default memo(IcdComponent);
