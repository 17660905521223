/* eslint-disable react-hooks/exhaustive-deps */
import { Button, DatePicker, Divider } from 'antd';
import useDate from 'hooks/useDate';
import { memo, useCallback } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import style from './index.module.scss';
import { AddRecordsFilter } from 'components';
import { getParamsFromValues, getParamsToObject } from 'utilities';
import dayjs from 'dayjs';
import useTour from 'hooks/useTour';

const { RangePicker } = DatePicker;

const FiltersRecords = () => {
  const { ref } = useTour();
  const { state } = useLocation();
  const { getFormat } = useDate();
  const navigate = useNavigate();
  const { personId } = useParams();
  const [params, setSearchParams] = useSearchParams();

  const onRangeChange = useCallback(
    (d: any) => {
      if (d?.filter((f: any) => f)?.length === 2) {
        setSearchParams(
          getParamsFromValues({
            ...getParamsToObject(params),
            begin: dayjs(d[0]).format(getFormat('DD/MM/YYYY')),
            end: dayjs(d[1]).format(getFormat('DD/MM/YYYY')),
          }),
          { state },
        );
      } else {
        const { registeredAt, ...rest } = getParamsToObject(params);
        setSearchParams(getParamsFromValues(rest), { state });
      }
    },
    [getFormat, setSearchParams, params],
  );

  return (
    <>
      <div className={style.filters_records}>
        <div ref={(r) => ref('person_date_filter', r)}>
          <RangePicker
            value={[
              (getParamsToObject(params)?.registeredAt?.[0] &&
                dayjs(getParamsToObject(params).registeredAt[0])) ||
                null,
              (getParamsToObject(params)?.registeredAt?.[1] &&
                dayjs(getParamsToObject(params).registeredAt[1])) ||
                null,
            ]}
            allowClear
            onCalendarChange={onRangeChange}
            format={getFormat('DD/MM/YYYY')}
          />
        </div>
        <div className={style.actions}>
          <AddRecordsFilter />
          <Button
            ref={(r) => ref('person_add_record', r)}
            onClick={() => navigate(`/person/${personId}/record`, { state })}
            className={style.add}
          >
            + Añadir Informe
          </Button>
        </div>
      </div>
      <Divider
        style={{ margin: '0 auto', minWidth: 'calc(99% - var(--padding))' }}
      />
    </>
  );
};

export default memo(FiltersRecords);
