import { Col, Form, Row, Select } from 'antd';
import { memo, useState } from 'react';
import { disabledAutocomplate } from 'utilities';
import style from './index.module.scss';

type props = {
  label: string;
  name: string;
  onChange: (value: number, option: any) => void;
  disabled?: boolean;
  loading?: boolean;
  data?: any[];
};

const AddressSelectField = ({
  label,
  loading,
  name,
  disabled = false,
  onChange,
  data = [],
}: props) => {
  const [selected, setSelected] = useState(false);
  return (
    <Col span={10}>
      <Row>
        <Col span={8}>
          <p className={`${style.label} ${disabled ? style.disabled : ''}`}>
            {label}
          </p>
        </Col>
        <Col span={14}>
          <Form.Item name={name}>
            <Select
              bordered={selected}
              onFocus={() => {
                setSelected(true);
                disabledAutocomplate();
              }}
              onBlur={() => setSelected(false)}
              placeholder="Seleccione una opción"
              loading={loading}
              disabled={disabled}
              onChange={onChange}
              filterOption={(input: any, option: any) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              showSearch
              allowClear
            >
              {data.map(({ id, nombre, ...data }: any) => (
                <Select.Option key={id} value={id} {...data}>
                  {nombre}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Col>
  );
};

export default memo(AddressSelectField);
