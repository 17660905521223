import { Button, Modal } from 'antd';
import { useFile } from 'hooks/useFiles';
import { CloudUploadOutlined, CloseOutlined } from '@ant-design/icons';
import style from './index.module.scss';
import { memo, useEffect } from 'react';

type props = {
  visible: boolean;
  onCancel: () => void;
  callBackImgData: (imgData: any) => void;
};

const ChangePersonPhoto = ({ onCancel, visible, callBackImgData }: props) => {
  const { UploadComponent, getFilesFormatter, setFileList } = useFile({
    max: 1,
    maxMB: 1,
  });

  const onUpload = async () => {
    const profilePhoto = getFilesFormatter()[0];
    callBackImgData(profilePhoto);
  };

  useEffect(() => {
    if (visible) setFileList([]);
  }, [visible]);

  return (
    <Modal
      maskClosable
      title="Agregar foto"
      width={'fit-content'}
      style={{
        margin: 'auto',
        top: '0px',
      }}
      onCancel={onCancel}
      className={style.profile_photo}
      footer={[
        <Button key="back" onClick={onCancel}>
          <CloseOutlined />
          Cancelar
        </Button>,
        <Button key="submit" onClick={onUpload}>
          <CloudUploadOutlined /> Aplicar
        </Button>,
      ]}
      open={visible}
    >
      {UploadComponent()}
    </Modal>
  );
};

export default memo(ChangePersonPhoto);
