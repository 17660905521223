import { Dropdown, Menu } from 'antd';
import style from './index.module.scss';
import {
  MoreOutlined,
  FullscreenOutlined,
  LinkOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { useState } from 'react';
import { RecordUpsertProvider } from 'provider/recordUpsertProvider';
import { useParams } from 'react-router-dom';
import { useRecordUpsert } from 'hooks/useRecord';

type props = {
  record: any;
  text?: string;
};

const InnerMenu = ({ record }: any) => {
  const { personId } = useParams();
  const { askToSee } = useRecordUpsert();
  const [sentAsk, setSentAsk] = useState(false);
  const url = `${window.location.origin}/person/${personId}/record/${record?.doc?.id}`;

  const options: any = {
    ask: () => {
      if (sentAsk) return;
      askToSee(record);
      setSentAsk(true);
    },
    copy_link: () => {
      const el = document.createElement('textarea');
      el.value = url;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    },
    open_new_tab: () => {
      window.open(url, '_blank')!.focus();
    },
  };

  const ask = {
    key: 'ask',
    disabled: sentAsk,
    label: (
      <div style={{ opacity: sentAsk ? '0.5' : '1' }}>
        <QuestionCircleOutlined /> Solicitar permiso para ver
      </div>
    ),
  };

  const onClick = ({ key, domEvent }: any) => {
    domEvent.stopPropagation();
    options[key]();
  };

  return (
    <Menu
      onClick={onClick}
      items={[
        (!record.isVisible && ask) || null,
        {
          key: 'copy_link',
          label: (
            <>
              <LinkOutlined /> Copiar link
            </>
          ),
        },
        {
          key: 'open_new_tab',
          label: (
            <>
              <FullscreenOutlined /> Abrir en nueva pestaña
            </>
          ),
        },
      ]}
    />
  );
};

const MoreOptionRecord = ({ record, text }: props) => {
  const { askToSee } = useRecordUpsert();
  const [sentAsk, setSentAsk] = useState(false);
  return (
    <div
      className={text ? style.more_options_text : style.more_options}
      onClick={(e) => e.stopPropagation()}
    >
      {(text && (
        <>
          {text}
          <div
            onClick={() => {
              !sentAsk && askToSee(record);
              setSentAsk(true);
            }}
            className={`${sentAsk ? style.disabled : ''} ${style.action}`}
          >
            solicita aqui.
          </div>
        </>
      )) || (
        <Dropdown
          overlay={
            <RecordUpsertProvider>
              <InnerMenu record={record} />
            </RecordUpsertProvider>
          }
          placement="bottomRight"
          arrow
          trigger={['click']}
        >
          <MoreOutlined />
        </Dropdown>
      )}
    </div>
  );
};

export default MoreOptionRecord;
