import { memo, useState } from 'react';
import style from './index.module.scss';
import {
  AntecedentsModal,
  Counter,
  DiagnosisModal,
  FilesModal,
  Loading,
  PersonSummaryInformation,
} from 'components';
import { usePerson } from 'hooks/usePerson';
import { useNavigate, useParams } from 'react-router-dom';
import useTour from 'hooks/useTour';

const Antecedents = memo(() => {
  const { person } = usePerson();
  const [show, setShow] = useState(false);
  return (
    <>
      <Counter
        onClick={() => setShow(true)}
        label={'Antecedentes'}
        number={person.antecedentsCounter || 0}
      />
      <AntecedentsModal show={show} onClose={() => setShow(false)} />
    </>
  );
});

const Diagnostics = memo(() => {
  const { person } = usePerson();
  const [show, setShow] = useState(false);
  return (
    <>
      <Counter
        onClick={() => setShow(true)}
        label={'Diagnósticos'}
        number={person.diagnosticsCounter || 0}
      />
      <DiagnosisModal show={show} onClose={() => setShow(false)} />
    </>
  );
});

const Files = memo(() => {
  const { person } = usePerson();
  const [show, setShow] = useState(false);
  return (
    <>
      <Counter
        onClick={() => setShow(true)}
        label={'Adjuntos'}
        number={person.filesCounter || 0}
      />
      <FilesModal show={show} onClose={() => setShow(false)} />
    </>
  );
});

const HeaderPerson = () => {
  const { ref } = useTour();
  const { person, fetching } = usePerson();
  const { personId } = useParams();
  const navigate = useNavigate();

  return (
    <div className={style.header}>
      {(!fetching && person && (
        <>
          <div className={style.person}>
            <PersonSummaryInformation />
          </div>
          <div ref={(r) => ref('person_counter', r)} className={style.counters}>
            <Antecedents />
            <Diagnostics />
            <Files />
            <Counter
              onClick={() =>
                navigate(`/person/${personId}`, {
                  state: { personInformation: person },
                })
              }
              type="solid"
              label={'Informes'}
              number={person.recordsCounter || 0}
            />
          </div>
        </>
      )) || <Loading />}
    </div>
  );
};

export default memo(HeaderPerson);
