import * as types from "./types";

const result = (type, payload) => {
  return {
    type: type,
    payload: payload,
  };
};

export const getSpecialities = payload => result(types.GET_SPECIALITIES, payload);
export const getSpecialitiesSuccess = payload => result(types.GET_SPECIALITIES_SUCCESS, payload);
export const getSpecialitiesError = payload => result(types.GET_SPECIALITIES_ERROR, payload);

export const getTermsAndPrivacity = payload => result(types.GET_TERMS_AND_PRIVACITY, payload);
export const getTermsAndPrivacitySuccess = payload => result(types.GET_TERMS_AND_PRIVACITY_SUCCESS, payload);
export const getTermsAndPrivacityError = payload => result(types.GET_TERMS_AND_PRIVACITY_ERROR, payload);

export const getAllProfessionalsEmails = payload => result(types.GET_ALL_PROFESSIONALS_EMAILS, payload);
export const getAllProfessionalsEmailsSuccess = payload => result(types.GET_ALL_PROFESSIONALS_EMAILS_SUCCESS, payload);
export const getAllProfessionalsEmailsError = payload => result(types.GET_ALL_PROFESSIONALS_EMAILS_ERROR, payload);

export const getAllOSS = payload => result(types.GET_ALL_OSS, payload);
export const getAllOSSSuccess = payload => result(types.GET_ALL_OSS_SUCCESS, payload);
export const getAllOSSError = payload => result(types.GET_ALL_OSS_ERROR, payload);

export const getAllZip = payload => result(types.GET_ALL_ZIP, payload);
export const getAllZipSuccess = payload => result(types.GET_ALL_ZIP_SUCCESS, payload);
export const getAllZipError = payload => result(types.GET_ALL_ZIP_ERROR, payload);

export const getAllPhoneCode = payload => result(types.GET_ALL_PHONE_CODE, payload);
export const getAllPhoneCodeSuccess = payload => result(types.GET_ALL_PHONE_CODE_SUCCESS, payload);
export const getAllPhoneCodeError = payload => result(types.GET_ALL_PHONE_CODE_ERROR, payload);
