import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import * as actions from './actions';
import { ConfigurationApi, MedicalApi } from 'api';

function* watchgetSpecialities() {
  yield takeEvery(types.GET_SPECIALITIES, function* () {
    try {
      const { data } = yield call(ConfigurationApi.getAllSpecialities);
      yield put(actions.getSpecialitiesSuccess(data));
    } catch (error) {
      yield put(actions.getSpecialitiesError());
    }
  });
}

function* watchGetTermsAndPrivacity() {
  yield takeEvery(types.GET_TERMS_AND_PRIVACITY, function* () {
    try {
      const { data } = yield call(ConfigurationApi.getTermsAndPrivacity);
      yield put(actions.getTermsAndPrivacitySuccess(data));
    } catch (error) {
      yield put(actions.getTermsAndPrivacityError());
    }
  });
}

function* watchGetAllProfessionalsEmails() {
  yield takeEvery(types.GET_ALL_PROFESSIONALS_EMAILS, function* () {
    try {
      const { data } = yield call(MedicalApi.getAllMedicalsEmails);
      yield put(actions.getAllProfessionalsEmailsSuccess(data));
    } catch (error) {
      yield put(actions.getAllProfessionalsEmailsError());
    }
  });
}

function* watchGetOss() {
  yield takeEvery(types.GET_ALL_OSS, function* () {
    try {
      const { data } = yield call(ConfigurationApi.getOSS);
      yield put(actions.getAllOSSSuccess(data));
    } catch (error) {
      yield put(actions.getAllOSSError());
    }
  });
}

function* watchGetZip() {
  yield takeEvery(types.GET_ALL_ZIP, function* () {
    try {
      const { data } = yield call(ConfigurationApi.getZip);
      yield put(actions.getAllZipSuccess(data));
    } catch (error) {
      yield put(actions.getAllZipError());
    }
  });
}

function* watchGetAllPhoneCode() {
  yield takeEvery(types.GET_ALL_PHONE_CODE, function* () {
    try {
      const { data } = yield call(ConfigurationApi.getPhoneCode);
      yield put(actions.getAllPhoneCodeSuccess(data));
    } catch (error) {
      yield put(actions.getAllPhoneCodeError());
    }
  });
}
function* SystemConfigurationSagas() {
  yield all([
    fork(watchgetSpecialities),
    fork(watchGetTermsAndPrivacity),
    fork(watchGetAllProfessionalsEmails),
    fork(watchGetOss),
    fork(watchGetZip),
    fork(watchGetAllPhoneCode),
  ]);
}

export default SystemConfigurationSagas;
