import { useCallback, useEffect, useRef, useState, ReactElement } from 'react';
import { Upload } from 'antd';
import {
  FileImageOutlined,
  FileWordOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  FileZipOutlined,
  FileOutlined,
} from '@ant-design/icons';
import { useNotify } from './useNotify';

type props = {
  max?: number;
  label?: string | ReactElement;
  showRemaining?: boolean;
  maxMB?: number;
};
const maxSizeBackendAccepted = 10;
const Bytes = 1048576;

const MAX_SIZE_TOTAL = Bytes * maxSizeBackendAccepted;

let innerCallBack: Function | undefined;
let totalAccumulatedSize = 0;

const ImgFile: any = {
  '.png': <FileImageOutlined />,
  '.jpg': <FileImageOutlined />,
  '.jpeg': <FileImageOutlined />,
  '.doc': <FileWordOutlined />,
  '.docx': <FileWordOutlined />,
  '.pdf': <FilePdfOutlined />,
  '.xls': <FileExcelOutlined />,
  '.xlsx': <FileExcelOutlined />,
  '.zip': <FileZipOutlined />,
  '.rar': <FileZipOutlined />,
  '.7z': <FileZipOutlined />,
  '.tar': <FileZipOutlined />,
  default: <FileOutlined />,
};

export const useFile = (props?: props) => {
  const MB = props?.maxMB || 4;
  const maxSize = Bytes * MB;
  const MAX_FILES_TO_ADD = props?.max || 3;
  const [fileList, setFileInnerList] = useState([]);
  const formatters = useRef<any[]>([]);
  const [max_files, MAX_FILES] = useState(MAX_FILES_TO_ADD);
  const { openErrorNotify } = useNotify();

  const formatter = async () => {
    const files = [];
    for (let index = 0; index < fileList.length; index++) {
      const i: any = fileList[index];
      files.push({
        fileName: i.name,
        [i.url ? 'id' : 'fileBase64']: i.url
          ? i.uid
          : await getBase64(i?.originFileObj),
      });
    }
    formatters.current = files;
    innerCallBack && innerCallBack(files);
  };

  const getFilesFormatter = () => {
    return formatters.current;
  };

  const onPreview = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow: any = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const onChangeFile = ({ fileList: newFileList }: any) => {
    setFileList(newFileList);
  };

  const propsUploader = {
    accept: 'audio/*,video/*,image/*,.*',
    maxCount: max_files,
    beforeUpload: (file: any) => {
      if (file.size > maxSize) {
        openErrorNotify(`Limite maximo por archivo es de ${MB} MB`);
        return Upload.LIST_IGNORE;
      }
      totalAccumulatedSize += file.size;
      if (totalAccumulatedSize > MAX_SIZE_TOTAL) {
        totalAccumulatedSize -= file.size;
        openErrorNotify(
          `Tamaño limite total alcanzado, restante ${
            Math.round(
              ((MAX_SIZE_TOTAL - totalAccumulatedSize) / Bytes +
                Number.EPSILON) *
                100,
            ) / 100
          } MB`,
        );
        return Upload.LIST_IGNORE;
      }
      return false;
    },
    onRemove: (file: any) => {
      totalAccumulatedSize -= file.size;
    },
  };

  const getBase64 = async (img: any) => {
    return new Promise((resolve) => {
      if (img) {
        const reader = new FileReader();
        reader.addEventListener('load', () => resolve(reader.result));
        reader.readAsDataURL(img);
      } else {
        resolve(undefined);
      }
    });
  };

  /**
   *
   * @param {{uid: string, name: string, status: "done", url: string}} list
   */
  const setFileList = (list: any) => {
    setFileInnerList(list?.splice(0, max_files) || []);
  };

  useEffect(() => {
    if (!!!fileList.length) return;
    formatter();
  }, [fileList]);

  // tranform a blob to base64
  const getBaseFromBlob = async (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const downloadFile = async (href: string, name: string) => {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = href;
    a.download = name;
    a.click();
  };

  const UploadComponent = useCallback(
    (callBack?: Function) => {
      innerCallBack = callBack;
      return (
        <Upload
          multiple={true}
          listType="picture-card"
          {...propsUploader}
          fileList={fileList}
          onChange={onChangeFile}
          onPreview={onPreview}
        >
          {fileList?.length < max_files && (
            <>
              {props?.label || '+ Cargar'}
              {props?.showRemaining && ` (${max_files - fileList?.length})`}
            </>
          )}
        </Upload>
      );
    },
    [onChangeFile, onPreview, fileList, propsUploader, props?.label, max_files],
  );

  return {
    setMax: MAX_FILES,
    getFilesFormatter,
    UploadComponent,
    setFileList,
    canAdd: max_files <= MAX_FILES_TO_ADD,
    isSeted: !!fileList?.length,
    downloadFile,
    getBaseFromBlob,
    ImgFile,
  };
};
