import confirm from 'antd/lib/modal/confirm';
import moment from 'moment';
import {
  createContext,
  useCallback,
  useRef,
  useState,
  useEffect,
  MutableRefObject,
  useMemo,
} from 'react';
import {
  ExclamationCircleOutlined,
  CloudUploadOutlined,
  CloseOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { ChooseSpeciality } from 'components';
import useFetch from 'hooks/useFetch';
import { MedicalStudyApi } from 'api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecord } from './recordProvider';
import {
  CACHE_LAST_RECORD_KEY,
  CACHE_PERSON_KEY,
  CACHE_RECORD_KEY,
  fastModalClass,
  removeHtmlTags,
  setGetWitchCache,
} from 'utilities';

type onChangeType =
  | 'title'
  | 'files'
  | 'description'
  | 'visibility'
  | 'registeredAt'
  | 'icd'
  | 'canSee';

export type RecordUpsertProviderType = {
  data: MutableRefObject<any>;
  onChange: (field: onChangeType, d: any) => void;
  setTemplate: (v: any) => void;
  selectedTemplate: any;
  loadingCreate: boolean;
  loadingUpdate: boolean;
  loadingDelete: boolean;
  askToSee: (record: any) => void;
  onSave: () => void;
  onDelete: () => void;
  onUpdate: () => void;
  setEditing: () => void;
  editing: boolean;
  allowSave: boolean;
  isVisible: boolean;
};

export const RecordContext = createContext<RecordUpsertProviderType>(
  {} as RecordUpsertProviderType,
);

const RecordUpsertProvider = ({ children }: any) => {
  const backup = useRef();
  const { record } = useRecord();
  const [editing, innerSetEditing] = useState(false);
  const { medicalProfile } = useSelector((sate: any) => sate.medicalProfile);
  const { state }: any = useLocation();
  const navigate = useNavigate();
  const { personId } = useParams();
  const [allowSave, setAllowSave] = useState(false);
  const {
    loading: {
      createMedicalStudy: loadingCreate,
      updateStudy: loadingUpdate,
      deleteStudy: loadingDelete,
    },
    createMedicalStudy,
    updateStudy,
    deleteStudy,
    askToSee: innerAskToSee,
  } = useFetch(MedicalStudyApi, {
    showNotification: {
      error: { show: true },
      success: {
        show: {
          createMedicalStudy: true,
          askToSee: true,
          updateStudy: true,
          deleteStudy: true,
        },
        message: {
          createMedicalStudy: 'Registro creado con éxito!',
          askToSee: 'Solicitud enviada con éxito!',
          updateStudy: 'Registro actualizado con éxito!',
          deleteStudy: 'Registro eliminado con éxito!',
        },
      },
    },
  });

  const setEditing = useCallback(() => {
    if (editing) {
      setTemplate(null);
      data.current = {
        ...record,
        files: [...(record.files || [])],
        icd: [...(record.icd || [])],
      };
    }
    innerSetEditing(!editing);
    _changeLength();
  }, [editing, record]);

  const data = useRef<any>(record || {});
  const [selectedTemplate, setTemplate] = useState<any>();

  useEffect(() => {
    if (!!selectedTemplate?.template?.length) {
      data.current.description = selectedTemplate?.template;
      _changeLength();
    }
  }, [selectedTemplate?.template]);

  const onChange = useCallback((field: onChangeType, d: any) => {
    data.current = { ...data.current, [field]: d };
    if (field === 'title' || field === 'description') _changeLength();
  }, []);

  const _changeLength = () => {
    setAllowSave(
      data.current.title?.length > 0 &&
        removeHtmlTags(data.current.description)?.length > 0,
    );
  };

  /**
   *  Modifiy the study to send to backend
   *
   * @param study
   */
  const _dataToSave = useCallback(() => {
    backup.current = data.current;
    data.current.specialityId =
      data.current.specialityId ||
      data.current?.speciality?.id ||
      medicalProfile.specialities[0].id;
    data.current.person = personId;
    delete data.current.doc;
    delete data.current.updatedAt;
    delete data.current.createdAt;
    delete data.current.imOwner;
    delete data.current.isVisible;
    delete data.current.speciality;
    delete data.current.editorChanged;
    data.current.visibility = data.current.visibility || 'public';
    if (data.current.visibility === 'private') data.current.canSee = [];

    if (data.current.registeredAt instanceof moment)
      data.current.registered = data.current.registeredAt.toDate();
    else data.current.registeredAt = moment(data.current.registeredAt).toDate();

    data.current.icd =
      data.current.icd?.map(
        ({ code, title, description, antecedent, action, id }: any) => ({
          ...((action !== 'delete' && { code }) || {}),
          ...((action !== 'delete' && { title }) || {}),
          ...((action !== 'delete' && { description }) || {}),
          ...((action !== 'delete' && { antecedent }) || {}),
          ...((action && { action }) || (!id && { action: 'new' })),
          ...((id && { id }) || null),
        }),
      ) || [];
  }, [medicalProfile.specialities, personId]);

  const _navigateToPerson = useCallback(
    (withCache: boolean) => {
      setGetWitchCache(CACHE_RECORD_KEY);
      withCache && setGetWitchCache(CACHE_PERSON_KEY);
      navigate(`/person/${personId}`, {
        replace: true,
        ...((withCache && { state: { personInformation: null } }) || { state }),
      });
    },
    [navigate, personId, state],
  );

  const _save = useCallback(async () => {
    _dataToSave();
    try {
      await createMedicalStudy(data.current);
      setGetWitchCache(CACHE_LAST_RECORD_KEY);
      _navigateToPerson(true);
    } catch (err) {
      data.current = backup.current;
    }
  }, [_navigateToPerson, _dataToSave, createMedicalStudy]);

  const _update = useCallback(async () => {
    const id = data.current?.doc?.id;
    _dataToSave();
    try {
      await updateStudy(id, data.current);
      const haveUpdated =
        !!data.current.icd?.filter(({ action }: any) =>
          ['new', 'delete'].includes(action),
        )?.length ||
        !!data.current.files?.filter(({ action }: any) =>
          ['new', 'delete'].includes(action),
        )?.length ||
        record.visibility !== data.current.visibility ||
        !!data.current.icd?.find(({ action }: any) => !!action);
      _navigateToPerson(haveUpdated);
    } catch (error) {
      data.current = backup.current;
    }
  }, [_dataToSave, updateStudy, record?.visibility, _navigateToPerson]);

  const _askChooseSpeciality = useCallback(() => {
    confirm({
      className: fastModalClass,
      maskClosable: true,
      okButtonProps: { style: { boxShadow: 'none' }, type: 'default' },
      cancelButtonProps: { style: { boxShadow: 'none' } },
      title: 'Elegir especialidad',
      icon: <ExclamationCircleOutlined />,
      content: (
        <ChooseSpeciality
          defaultSpeciality={data.current?.speciality?.id}
          specialities={medicalProfile.specialities}
          onChange={(selectedId: string) =>
            (data.current.specialityId = selectedId)
          }
        />
      ),
      onOk: () => (data.current?.doc?.id ? _update() : _save()),
      okText: (
        <>
          <CloudUploadOutlined /> Guardar
        </>
      ),
      cancelText: (
        <>
          <CloseOutlined /> Cancelar
        </>
      ),
    });
  }, [_save, _update, medicalProfile.specialities]);

  const askToSee = useCallback(
    (rec: any) => {
      const person = JSON.parse(sessionStorage.getItem('person') || '{}');
      const studyId = rec.doc.id;
      innerAskToSee({
        studyId,
        study: {
          id: studyId,
          title: rec.title,
          typeName: rec.speciality.name,
        },
        professional: {
          name: medicalProfile.name,
          lastName: medicalProfile.lastName,
          email: medicalProfile.email,
        },
        person: {
          id: personId,
          name: person.name,
          lastName: person.lastName,
        },
      });
    },
    [
      innerAskToSee,
      medicalProfile.email,
      medicalProfile.lastName,
      medicalProfile.name,
      personId,
    ],
  );

  const onUpdate = useCallback(async () => {
    confirm({
      className: fastModalClass,
      maskClosable: true,
      okButtonProps: { style: { boxShadow: 'none' } },
      cancelButtonProps: { style: { boxShadow: 'none' } },
      title: '¿Estás seguro de que deseas guardar los cambios?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Si',
      okType: 'ghost',
      cancelText: 'No',
      onOk: async () => {
        if (data.current?.imOwner)
          medicalProfile.specialities?.length > 1
            ? _askChooseSpeciality()
            : _update();
      },
      onCancel() {},
    });
  }, [_askChooseSpeciality, _update, medicalProfile.specialities?.length]);

  const onSave = useCallback(async () => {
    medicalProfile.specialities?.length > 1 ? _askChooseSpeciality() : _save();
  }, [_askChooseSpeciality, _save, medicalProfile?.specialities]);

  const onDelete = useCallback(async () => {
    const hasICD = !!data.current.icd?.length;
    const innerData = { deleteIcd: true };
    confirm({
      maskClosable: true,
      className: fastModalClass,
      title: '¿Estás seguro de eliminar este registro?',
      icon: <WarningOutlined style={{ color: 'red' }} />,
      content: (
        <div>
          <p>No podrás revertir esta acción</p>
          {/* TODO future feature */}
          {/* {hasICD && (
            <Checkbox
              defaultChecked
              onChange={(v) => (innerData.deleteIcd = v.target.checked)}
            >
              Eliminar diagnosticos relacionados
            </Checkbox>
          )} */}
        </div>
      ),
      okText: 'Si, eliminar',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        if (!data.current.imOwner) return;
        await deleteStudy(data.current.doc.id, innerData);
        setGetWitchCache(CACHE_LAST_RECORD_KEY);
        _navigateToPerson(true);
      },
    });
  }, [_navigateToPerson, deleteStudy]);

  const isVisible = !!data.current?.isVisible;

  const value = useMemo(
    () => ({
      data,
      onChange,
      setTemplate,
      selectedTemplate,
      askToSee,
      onUpdate,
      onSave,
      onDelete,
      loadingCreate,
      loadingUpdate,
      loadingDelete,
      setEditing,
      editing,
      allowSave,
      isVisible,
    }),
    [
      allowSave,
      askToSee,
      editing,
      isVisible,
      loadingCreate,
      loadingDelete,
      loadingUpdate,
      onChange,
      onDelete,
      onSave,
      onUpdate,
      selectedTemplate,
      setEditing,
    ],
  );
  return (
    <RecordContext.Provider value={value}>{children}</RecordContext.Provider>
  );
};

export { RecordUpsertProvider };
