import { Avatar, Col, Row } from 'antd';
import { MedicalApi } from 'api';
import { MoreOptionRecord } from 'components';
import SpecialityTag from 'components/specialityTag';
import useCommon from 'hooks/useCommon';
import usePhoto from 'hooks/usePhoto';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import style from './index.module.scss';

type props = {
  id?: string;
  data: any;
};

const RecordItem = ({ id, data }: props) => {
  const { medicalProfile } = useSelector((state: any) => state.medicalProfile);
  const navigate = useNavigate();
  const { personId } = useParams();
  const { state }: any = useLocation();
  const professional =
    (typeof data.professional !== 'string' && data.professional) ||
    medicalProfile;
  const { img, fallback } = usePhoto({
    api: MedicalApi,
    person: professional,
  });

  const { professionalsEmails } = useSelector(
    (state: any) => state.systemConfiguration,
  );

  const { privacity, title, registeredAt, diagnostics, files } = useCommon({
    professionalsEmails,
    study: data,
  });

  const openRecord = () => {
    navigate(`/person/${personId}/record/${data.doc.id}`, {
      state: {
        ...state,
        recordInformation: data,
      },
    });
  };

  return (
    <Row onClick={openRecord} id={id} gutter={15} className={style.record_item}>
      <Col span={16} className={style.record_information}>
        <Row>
          <Col span={24} className={style.record_information_specialty}>
            <SpecialityTag
              onRecord
              speciality={data.speciality}
              withoutBorder
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className={style.record_title}>
              {title(data.title)}
              {privacity(data)}
              {diagnostics(data)}
              {files(data)}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24} className={style.record_date}>
            {registeredAt(data)}
          </Col>
        </Row>
      </Col>
      <Col span={8} className={style.record_chip_parent}>
        <div className={style.record_chip}>
          <Row>
            <Col span={5}>
              <Avatar src={img || fallback} size="large" />
            </Col>
            <Col span={19} className={style.record_professional_info}>
              <Row>
                <Col className={style.record_professional_name} span={24}>
                  <div>
                    {`${professional.name || ''} ${
                      professional.lastName || ''
                    }`}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className={style.record_professional_enrollment} span={24}>
                  MAT:{' '}
                  {
                    professional.extraSpecialitiesInfo?.[data.speciality.id]
                      ?.enrollment
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Col>
      <MoreOptionRecord record={data} />
    </Row>
  );
};

export default memo(RecordItem);
