import { Badge } from 'antd';
import { SharedProfessional } from 'components';
import { AttachIcon, HeartIcon, LockEyeIcon, LockIcon, WorldIcon } from 'icons';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import useDate from './useDate';

type props = {
  study: any;
  professionalsEmails: any[];
};

const useCommon = ({ study, professionalsEmails }: props) => {
  const { medicalProfile } = useSelector((state: any) => state.medicalProfile);
  const { applyFormat, getDifferenceBetween } = useDate();

  const registeredAt = useCallback(
    ({ createdAt, updatedAt, registeredAt }: any) => (
      <div>
        Realizado el {applyFormat(registeredAt, 'DD/MM/YYYY')}{' '}
        {getDifferenceBetween(updatedAt, createdAt, 'd') > 0 && (
          <> - Actualizado el {applyFormat(updatedAt, 'DD/MM/YYYY')}</>
        )}
      </div>
    ),
    [applyFormat, getDifferenceBetween],
  );

  const title = useCallback((title: string) => <div>{title}</div>, []);

  const files = useCallback(
    ({ files, isVisible }: any) =>
      !!files?.length &&
      isVisible && (
        <div className="mt-badgets">
          <Badge
            color="var(--color-badge)"
            size="small"
            count={files?.length}
            overflowCount={10}
          >
            <AttachIcon title="Adjuntos" />
          </Badge>
        </div>
      ),
    [],
  );
  const diagnostics = useCallback(
    ({ icd, isVisible }: any) =>
      !!icd?.length &&
      isVisible && (
        <div className="mt-badgets">
          <Badge
            color="var(--color-badge)"
            size="small"
            count={icd?.length}
            overflowCount={10}
          >
            <HeartIcon title="Diagnosticos" />
          </Badge>
        </div>
      ),
    [],
  );

  const privacity = useCallback(
    ({ visibility, canSee, isVisible }: any) => {
      const filteredCanSee = professionalsEmails
        ?.filter(
          ({ uid }: any) =>
            canSee.includes(uid) && uid !== medicalProfile?.doc?.id,
        )
        ?.map(({ email }: any) => email);
      return visibility === 'private' ? (
        <LockIcon title="Informe Privado" />
      ) : filteredCanSee?.length > 0 && isVisible ? (
        <SharedProfessional
          emails={filteredCanSee}
          title={
            <div
              style={{ width: '200px', margin: 'auto', textAlign: 'center' }}
            >
              Profesionales con acceso a <b>{study.title}</b>
            </div>
          }
        >
          {(setOpen: any) => (
            <div className="mt-badgets" onClick={(e) => e.stopPropagation()}>
              <Badge
                color="var(--color-badge)"
                size="small"
                count={filteredCanSee?.length}
                overflowCount={10}
              >
                <WorldIcon
                  title="Compartido"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setOpen(true);
                  }}
                />
              </Badge>
            </div>
          )}
        </SharedProfessional>
      ) : (
        !isVisible && <LockEyeIcon />
      );
    },
    [medicalProfile?.doc?.id, professionalsEmails, study.title],
  );

  return { registeredAt, privacity, title, diagnostics, files };
};

export default useCommon;
