import { MedicalStudyApi } from 'api';
import { IcdFabButton, List, PersonStudyItem } from 'components';
import useFetch from 'hooks/useFetch';
import { memo, useCallback, useEffect, useLayoutEffect } from 'react';
import style from './index.module.scss';
import { useDispatch } from 'react-redux';
import { getMe } from 'pages/medicalProfile/redux-sagas/actions';
import {
  getAllProfessionalsEmails,
  getSpecialities,
} from 'systemConfiguration/redux-sagas/actions';
import useTourConfig from 'hooks/useTourConfig';
import { useAuth } from 'hooks/useAuth';
import classNames from 'classnames';
import { CACHE_LAST_RECORD_KEY, getWithCache } from 'utilities';

const Home = () => {
  const { init, ref } = useTourConfig({ screen: 'home' });
  const { getToken } = useAuth();
  const {
    loading: { getLastStudies: loading },
    data: { getLastStudies: data },
    getLastStudies,
  } = useFetch(MedicalStudyApi, { clearBefore: false });

  const dispatch = useDispatch();

  const getData = useCallback(async () => {
    if (getToken) {
      dispatch(getMe({ token: await getToken() }));
      dispatch(getSpecialities());
      dispatch(getAllProfessionalsEmails());
    }
  }, [dispatch, getToken]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    getLastStudies && getLastStudies(getWithCache(CACHE_LAST_RECORD_KEY));
  }, [getLastStudies]);

  useLayoutEffect(() => {
    init();
  });

  return (
    <div className={style.home}>
      <List
        setRef={(r) => ref('main_list', r)}
        loading={loading}
        gap={'20px'}
        emptyLabel="Ningún informe creado"
        data={data?.items || []}
        onRender={(v: any) => ({
          study: v,
        })}
        Item={PersonStudyItem}
      />
      <IcdFabButton />
    </div>
  );
};

export default memo(Home);
