import { memo } from 'react';
import style from './index.module.scss';
import { LoadingOutlined } from '@ant-design/icons';

const Loading = () => {
  return (
    <div className={style.loading}>
      <LoadingOutlined spin className={style.icon} />
      Cargando...
    </div>
  );
};

export default memo(Loading);
