export const GET_ME = "GET_ME";
export const GET_ME_ERROR = "GET_ME_ERROR";
export const GET_ME_SUCCESS = "GET_ME_SUCCESS";

export const MEDICAL_CLEAR = "MEDICAL_CLEAR";

export const SET_LOGGED = "SET_LOGGED";

export const SET_TERMS_AS_READ = "SET_TERMS_AS_READ";
export const SET_TERMS_AS_READ_SUCCESS = "SET_TERMS_AS_READ_SUCCESS";
export const SET_TERMS_AS_READ_ERROR = "SET_TERMS_AS_READ_ERROR";

export const UPDATE_MEDICAL_CACHE = "UPDATE_MEDICAL_CACHE";

export const SIGN_OUT = "SIGN_OUT";
