import moment, { unitOfTime } from 'moment';
type FORMAT = 'DD/MM/YYYY';
type FORMAT_TIME = 'HH:mm [hs]';
type FORMAT_DATE_AND_TIME = `${FORMAT} ${FORMAT_TIME}`;
type FULL_FORMAT = 'dddd DD [de] MMMM [de] YYYY';

export const FORMAT: FORMAT = 'DD/MM/YYYY';
export const FORMAT_TIME: FORMAT_TIME = 'HH:mm [hs]';
export const FORMAT_DATE_AND_TIME: FORMAT_DATE_AND_TIME =
  'DD/MM/YYYY HH:mm [hs]';
export const FULL_FORMAT: FULL_FORMAT = 'dddd DD [de] MMMM [de] YYYY';

const useDate = () => {
  const applyFormat = (
    date: string,
    type: FORMAT | FORMAT_TIME | FORMAT_DATE_AND_TIME | FULL_FORMAT,
  ) => {
    return moment(date).format(type);
  };

  //deshabilita las fechas despues de la actual
  const disabledFutureDate = (current: any) => {
    return current && current.isAfter(moment(), 'dates');
  };

  const getDifferenceBetween = (
    first: string,
    second: string,
    type?: unitOfTime.Diff,
  ) => {
    return moment(first).diff(moment(second), type || 'days');
  };

  const getDifference = (date: string, type?: unitOfTime.Diff) => {
    return moment().diff(moment(date), type || 'days');
  };

  return {
    applyFormat,
    disabledFutureDate,
    getDifference,
    getDifferenceBetween,
    getFormat: (type: FORMAT | FORMAT_TIME | FORMAT_DATE_AND_TIME) => {
      return type;
    },
  };
};

export default useDate;
