import api from '../api';

const baseUrl =
  process.env.REACT_APP_API_URL ||
  process.env.REACT_APP_CONFIGURATION_SERVICE_URL;
// const baseContext = `${baseUrl}/api/v1/configuration`;
const baseContext = `${baseUrl}${
  process.env.REACT_APP_API_URL ? '/api/v1/configuration' : ''
}`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  //Get all specialities
  getAllSpecialities: function () {
    return api.get(`${baseContext}/specialities`);
  },

  getSpecialityIcon: function (fileId, params) {
    return api.get(`${baseContext}/specialities/${fileId}/icon`, {
      params,
      responseType: 'blob',
    });
  },

  getTermsAndPrivacity: function () {
    return api.get(`${baseContext}/terms-privacity`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_JWT_PRIVATE_KEY_GENERAL}`,
      },
    });
  },

  getStates: function () {
    return api.get(`${baseContext}/states`);
  },

  getDepartments: function (stateId) {
    return api.get(`${baseContext}/departments`, {
      params: { stateId },
    });
  },

  getLocalities: function (departmentId) {
    return api.get(`${baseContext}/localities`, {
      params: { departmentId },
    });
  },

  getOSS: function () {
    return api.get(`${baseContext}/oss`);
  },

  getZip: function () {
    return api.get(`${baseContext}/zip`);
  },

  getPhoneCode: function () {
    return api.get(`${baseContext}/phone-code`);
  },
};
