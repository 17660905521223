import { Address } from 'components';
import { useProfessional } from 'hooks/useProfessional';
import { memo } from 'react';

type props = {
  isEditing: boolean;
  center: any;
};

const AddNewProfessionalAddress = ({ isEditing, center }: props) => {
  const { form } = useProfessional();
  return (
    <Address
      allowContact
      form={form}
      center={center}
      isEditing={isEditing}
      observation={'Nombre'}
      observationPlaceholder="Centro Medico..."
    />
  );
};

export default memo(AddNewProfessionalAddress);
