import { Avatar } from 'antd';
import { memo } from 'react';
import style from './index.module.scss';
import { PersonSummaryInformationActions } from 'components';
import { FameleIcon, IdPersonIcon, MaleIcon, SocialWorkIcon } from 'icons';
import usePhoto from 'hooks/usePhoto';
import { PeopleApi } from 'api';
import { usePerson } from 'hooks/usePerson';
import useDate from 'hooks/useDate';
import { numberWithCommas } from 'utilities';
import useTour from 'hooks/useTour';

const PersonSummaryInformation = () => {
  const { ref } = useTour();
  const { person } = usePerson();
  const { getDifference } = useDate();
  const { img, fallback } = usePhoto({ api: PeopleApi, person });

  return (
    <div
      ref={(r) => ref('person_information', r)}
      className={style.person_summary_information}
    >
      <div className={style.photo}>
        <Avatar src={img || fallback} size={'large'} />
      </div>
      <div className={style.content}>
        <div className={style.text}>
          <h4>
            {person.name} {person.lastName}
          </h4>
          <div className={style.actions}>
            <PersonSummaryInformationActions />
          </div>
        </div>
        <div className={style.id_gender_society}>
          <div>
            {person.genderId !== 'F' ? <MaleIcon /> : <FameleIcon />}
            {getDifference(person.birthdate, 'years')}
          </div>
          <div>
            <IdPersonIcon /> {numberWithCommas(person.id)}
          </div>
          {person.socialType && person.socialId && (
            <div>
              <SocialWorkIcon />
              {`${person.socialType.sigla} ${person.socialId}`}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(PersonSummaryInformation);
