import { Button, Col, DatePicker, Input, Row } from 'antd';
import {
  IcdModal,
  RecordEditor,
  RecordFiles,
  SaveTemplate,
  TemplateList,
} from 'components';
import useDate from 'hooks/useDate';
import dayjs from 'dayjs';
import { useRecord } from 'provider/recordProvider';

import { memo, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import style from './index.module.scss';
import RelatedInputs from './relatedInputs';
import useNav from 'hooks/useNav';
import useTourConfig from 'hooks/useTourConfig';
import { useRecordUpsert } from 'hooks/useRecord';
import { removeFirstAndSecondLastEmptySpace } from 'utilities';

const Record = () => {
  const { ref, init } = useTourConfig({ screen: 'record' });

  useLayoutEffect(() => {
    init();
  });

  const { backNavigate } = useNav();
  const {
    onChange,
    onSave,
    onUpdate,
    onDelete,
    data,
    editing,
    setEditing,
    loadingCreate,
    loadingDelete,
    loadingUpdate,
    allowSave,
    isVisible,
  } = useRecordUpsert();
  const { isPreviewRecord, isNewRecord, imOwner, errorMessage } = useRecord();
  const { getFormat, disabledFutureDate } = useDate();
  const { personId } = useParams();

  /* // Conditions  // */
  const deleteCondition =
    imOwner && !editing && isPreviewRecord && !isNewRecord;

  const showCondtion = editing || isNewRecord;

  const relatedInputsConditions =
    (isPreviewRecord && editing) || (isNewRecord && !editing);

  const showRecordCondition = data.current?.doc || isNewRecord || loadingUpdate;

  const showRelatedInputsConditions = showCondtion && relatedInputsConditions;

  const showImOwnerOrNew = showCondtion || imOwner;

  const hasIcd = !!data.current.icd?.length && isVisible;

  const largOrShowrtTitleInput =
    (!hasIcd && !showImOwnerOrNew) || (!showCondtion && !hasIcd);

  /* // End conditions // */

  return (
    (showRecordCondition && (
      <div className={style.record}>
        <Row gutter={15}>
          <Col
            ref={(r) => ref('record_title', r)}
            span={
              showImOwnerOrNew
                ? largOrShowrtTitleInput
                  ? 18
                  : 12
                : !hasIcd
                ? 21
                : 15
            }
          >
            <Input
              {...(!showCondtion && { className: style.disabledInput })}
              disabled={!showCondtion}
              defaultValue={data.current.title}
              placeholder="Titulo del Informe"
              {...(showCondtion && { showCount: true })}
              maxLength={100}
              onChange={(v) =>
                onChange(
                  'title',
                  removeFirstAndSecondLastEmptySpace(v.target?.value),
                )
              }
            />
          </Col>
          <Col ref={(r) => ref('record_date', r)} span={3}>
            <DatePicker
              {...(!showCondtion && { className: style.disabledInput })}
              disabled={!showCondtion}
              defaultValue={dayjs(data.current.registeredAt || undefined)}
              disabledDate={disabledFutureDate}
              onChange={(v) => onChange('registeredAt', v?.toDate())}
              placeholder="Elegir Fecha"
              format={getFormat('DD/MM/YYYY')}
            />
          </Col>
          <>
            {(showRelatedInputsConditions && (
              <>
                <Col ref={(r) => ref('record_template_add', r)} span={2}>
                  <SaveTemplate />
                </Col>
                <Col ref={(r) => ref('record_template_get', r)} span={2}>
                  <TemplateList />
                </Col>
              </>
            )) ||
              (showImOwnerOrNew && (
                <RelatedInputs disabledVisibility showMode="partial" />
              ))}
            <Col span={relatedInputsConditions ? 5 : 6}>
              <IcdModal />
            </Col>
          </>
        </Row>
        {showCondtion && relatedInputsConditions && showImOwnerOrNew && (
          <RelatedInputs showMode="full" />
        )}
        <RecordEditor />
        <Row className={style.record_last_row}>
          <Col span={16} className={style.record_files_col}>
            <RecordFiles />
          </Col>
          <Col className={style.record_action_col} span={8}>
            <Row gutter={15}>
              {!editing && (
                <Col className={style.action}>
                  <Button
                    className="mt_secondary_button"
                    onClick={() => backNavigate(`/person/${personId}`)}
                  >
                    Volver
                  </Button>
                </Col>
              )}
              {editing && !isNewRecord && (
                <Col className={style.action}>
                  <Button className="mt_secondary_button" onClick={setEditing}>
                    Cancelar
                  </Button>
                </Col>
              )}
              {deleteCondition && (
                <Col className={style.action}>
                  <Button
                    className="mt_delete_button"
                    loading={loadingDelete}
                    onClick={onDelete}
                  >
                    Eliminar
                  </Button>
                </Col>
              )}
              {(isNewRecord || editing) && (
                <Col className={style.action}>
                  <Button
                    className="mt_primary_button"
                    disabled={!allowSave}
                    loading={loadingCreate || loadingUpdate}
                    onClick={() => (editing ? onUpdate() : onSave())}
                  >
                    Guardar
                  </Button>
                </Col>
              )}
              {imOwner && !editing && !isNewRecord && (
                <Col className={style.action}>
                  <Button className="mt_primary_button" onClick={setEditing}>
                    Editar
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    )) || (
      <div className={style.error_message}>
        {errorMessage}
        <Button
          onClick={() => backNavigate(`/person/${personId}`)}
          className="mt_primary_button"
        >
          Volver
        </Button>
      </div>
    )
  );
};

export default memo(Record);
