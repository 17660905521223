
import { IcdComponent } from "components";
import { memo } from "react";
import style from "./index.module.scss";

const SearchICD = () => {
  return (
    <div className={style.search_icd}>
      <IcdComponent />
    </div>
  );
};

export default memo(SearchICD);
