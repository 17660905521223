import { memo } from 'react';
import style from './index.module.scss';

type props = {
  number?: number;
  label: string;
  type?: 'outline' | 'solid';
  onClick?: () => void;
};

const Counter = ({ number, label, type = 'outline', onClick }: props) => {
  return (
    <div
      onClick={onClick}
      className={`mt_fifth_button ${type} ${style.counter}`}
    >
      <div className={style.number}>{number}</div>
      <div className={style.label}>{label}</div>
    </div>
  );
};

export default memo(Counter);
