import { Button, Empty, Modal } from 'antd';
import { Notification } from 'components';
import { memo } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import useNotification from 'hooks/useNotification';

type props = {
  show: boolean;
  onClose: Function;
};

const NotificationModal = ({ show, onClose }: props) => {
  const { notifications } = useNotification({ fromNoCache: true });
  const innerClose = () => {
    onClose(false);
  };

  return (
    <Modal
      style={{
        margin: 'auto',
        top: '0px',
        height: '60vh',
      }}
      bodyStyle={{ overflow: 'auto' }}
      onCancel={innerClose}
      footer={[
        <Button key={'on-close-button'} onClick={innerClose}>
          <CloseOutlined />
          Cerrar
        </Button>,
      ]}
      title="Notificaciones"
      open={show}
    >
      {(!!notifications?.length &&
        notifications?.map((e: any, i: number) => (
          <Notification key={'noti-' + i} notification={e} />
        ))) || (
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ margin: 'auto' }}>
            <Empty description="No hay notificaciones" />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default memo(NotificationModal);
